import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  templateUrl: './select.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormSelectComponent implements OnInit {
  @Input() model: any;
  @Input() key: string;
  @Input() name: string;
  @Input() label: string;
  @Input() errorLabel: string;
  @Input() text: string;
  @Input() tooltip?: string;
  @Input() options: any[];
  @Input() group = 'category';
  @Input() required = false;
  @Input() showIcons = false;
  @Input() triggerKeyboard = false;
  @Input() submited: boolean;
  @Input() redesign: boolean;
  @Output() optionValue = new EventEmitter();

  inputId: string;

  ngOnInit() {
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
    if (!this.errorLabel) {
      this.errorLabel = 'validation.required';
    }
    if (!this.key) {
      this.key = this.name;
    }
  }

  valueChange(value: any) {
    const optionValue = this.redesign ? value : value?.['value'];
    this.model[this.key] = optionValue;
    this.optionValue.emit(optionValue);
  }
}
