import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedNewspaperParentRoutingModule } from './parent-routing.module';
import { SharedNewspaperParentComponent } from './parent.component';

@NgModule({
  imports: [
    CommonModule,
    SharedNewspaperParentRoutingModule,
    TranslateModule,
    ModuleTitleModule,
    FormsModule,
    BacklinkModule,
    FilterPipeModule,
  ],
  exports: [SharedNewspaperParentComponent],
  declarations: [SharedNewspaperParentComponent],
})
export class SharedNewspaperParentModule {}
