import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { Address } from 'models/address';

@Component({
  selector: 'app-address-item',
  templateUrl: './address-item.component.html',
  styleUrls: ['./address-item.component.scss'],
})
export class AddressItemComponent implements OnChanges, OnInit {
  @Input() address: Address;
  @Input() index: number;
  @Input() changeable: boolean;

  @Output() editClick = new EventEmitter<number>();
  @Output() deleteClick = new EventEmitter<number>();

  @Output('onSelection') onSelection: EventEmitter<void> = new EventEmitter<void>();

  mainAddress: string;
  fullAddress: string;
  constructor(
    private _cdr: ChangeDetectorRef,
    private cicoService: PmsCiCoService,
  ) {}

  ngOnInit() {
    this.mainAddress = this.address.getMainAddress();
    this.fullAddress = this.address.getFullAddress();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this._cdr.detectChanges();
  }

  onSelectAddress() {
    this.cicoService.setFolioAddressStep('folio');
    this.cicoService.displayedFolioIndex = -1;
    this.cicoService.addressChange = false;
    this.onSelection.emit();
  }

  onEditClick(event: Event) {
    event.stopPropagation();
    this.editClick.emit(this.index);
  }

  onDeleteClick(event: Event) {
    event.stopPropagation();
    this.deleteClick.emit(this.index);
  }
}
