import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SearchModule } from 'common/search/search.module';
import { WidgetSearchRoutingModule } from './search-routing.module';
import { WidgetSearchComponent } from './search.component';

@NgModule({
  imports: [CommonModule, WidgetSearchRoutingModule, SearchModule, TranslateModule],
  exports: [WidgetSearchComponent],
  declarations: [WidgetSearchComponent],
})
export class WidgetSearchModule {}
