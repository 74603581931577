import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapProtection } from 'models/map_protection';
import { GuestService } from 'services/guest.service';

@Component({
  selector: 'app-map-protection',
  templateUrl: './map_protection.component.html',
  styleUrls: ['./map_protection.component.scss'],
})
export class MapProtectionComponent {
  @Input() public protection: MapProtection;
  @Input() public overview: boolean;
  @Output() unprotected = new EventEmitter<boolean>();

  constructor(private guestService: GuestService) {}

  unprotect() {
    this.unprotected.emit(true);
    if (this.protection.cookie) {
      this.guestService.setCookies({ analytics: true });
    }
  }
}
