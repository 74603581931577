import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderRoutingModule } from './loader-routing.module';
import { LoaderComponent } from './loader.component';

@NgModule({
  imports: [CommonModule, LoaderRoutingModule, TranslateModule],
  exports: [LoaderComponent, TranslateModule],
  declarations: [LoaderComponent],
})
export class LoaderModule {}
