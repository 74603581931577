import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-upsell-gallery',
  templateUrl: './upsell-gallery.component.html',
  styleUrls: ['./upsell-gallery.component.scss'],
})
export class UpsellGalleryComponent implements OnInit {
  @Input() currentPrice = 0;
  @Input() upsellItems: any[] = [];
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  public nightCount: number;

  constructor(public cicoService: PmsCiCoService) {}

  ngOnInit(): void {
    this.cicoService.data
      .pipe(map((data) => data.incident.reservation.nightsCount()))
      .subscribe((nightCount) => (this.nightCount = nightCount));
  }

  selectItem(item: any) {
    // when already selected, then deselect on click on the item
    if (this.cicoService.cusRoomupgradeToBook?.offer?.unit_code === item.unit_code) {
      this.itemSelected.emit(null);
      return;
    }
    this.itemSelected.emit(item);
  }
}
