<div class="button-grid sticky">
  <button class="button green" id="send_form" [disabled]="status || disabled">
    {{ 'misc.' + confirm | translate }}
  </button>
  <button translate class="button red" type="button" (click)="close()" [disabled]="status">
    misc.cancel
  </button>
</div>
<app-form-legal [text]="'privacy_policy'" *ngIf="legal && !legal.terms"></app-form-legal>
<app-form-legal [text]="'terms'" *ngIf="legal && legal.terms"></app-form-legal>
