<main>
  <div>
    <img src="/assets/images/logo_white.svg" width="202" alt="straiv.io" />

    <h1>
      You are offline
      <small>No network</small>
    </h1>
    <p>Please reconnect to the internet and try again</p>
  </div>
</main>
