import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanValueAccessor } from './boolean-value-accessor';
import { DatepickerValueAccessor } from './datepicker-value-accessor';
import { NumericValueAccessor } from './number-value-accessor';

@NgModule({
  imports: [CommonModule],
  declarations: [BooleanValueAccessor, DatepickerValueAccessor, NumericValueAccessor],
  exports: [BooleanValueAccessor, DatepickerValueAccessor, NumericValueAccessor],
})
export class ValueAccessorModule {}
