import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateValidator } from './date.validator';

@NgModule({
  imports: [CommonModule],
  exports: [DateValidator],
  declarations: [DateValidator],
})
export class DateValidatorModule {}
