import { LoginProvider } from '../interfaces/LoginProvider';
import { LoginProviderClass } from '../models/LoginProviderClass';
import { SocialUser } from '../models/SocialUser';

export abstract class BaseLoginProvider implements LoginProvider {
  protected constructor() {}

  abstract isInitialize: boolean;

  abstract initialize(): Promise<SocialUser>;

  abstract signIn(): Promise<SocialUser>;

  abstract signOut(): Promise<any>;

  abstract isLoggedIn(): Promise<boolean>;

  abstract shareMessage(url: string, icon?: string): Promise<any>;

  abstract getUser(): Promise<SocialUser>;

  loadScript(obj: LoginProviderClass, onload: any): void {
    if (document.getElementById(obj.name)) {
      return;
    }
    const signInJS = document.createElement('script');
    signInJS.async = true;
    signInJS.src = obj.url;
    signInJS.onload = onload;
    if (obj.name === 'LINKEDIN') {
      signInJS.async = false;
      signInJS.text = ('api_key: ' + obj.id).replace("'", '');
    }
    document.head.appendChild(signInJS);
  }
}
