import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { PmsCheckInConfirmModule } from '../shared/confirm/confirm.module';
import { PmsFoliosModule } from '../shared/folios/folios.module';
import { PmsFooterModule } from '../shared/footer/footer.module';
import { InfoScreenModule } from '../shared/info-screen/info-screen.module';
import { PmsLoginModule } from '../shared/login/login.module';
import { PmsOverlayModule } from 'modules/pms/shared/overlay/overlay.module';
import { StartModule } from '../shared/start/start.module';
import { PmsCheckOutRoutingModule } from './check_out-routing.module';
import { PmsCheckOutComponent } from './check_out.component';
import { PmsCoGeneralModule } from './general/general.module';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { StepsIndicatorModule } from '../shared/steps-indicator/steps-indicator.module';
import { MainTitleModule } from '../shared/main-title/main-title.module';

@NgModule({
  imports: [
    CommonModule,
    StartModule,
    PmsCheckOutRoutingModule,
    PmsCoGeneralModule,
    PmsLoginModule,
    PmsOverlayModule,
    PmsFooterModule,
    TranslateModule,
    LoaderModule,
    MatStepperModule,
    PmsCheckInConfirmModule,
    PmsFoliosModule,
    StepsIndicatorModule,
    MainTitleModule,
    InfoScreenModule,
  ],
  exports: [PmsCheckOutComponent],
  declarations: [PmsCheckOutComponent],
  providers: [PmsBaseDirective, PmsCiCoBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsCheckOutModule {}
