import { AfterViewInit, Component, Input } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { OverlayAction } from 'pms_enums';
import { GenericData } from 'models/pms/generic_data';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements AfterViewInit {
  @Input() data: GenericData;
  description: any;

  constructor(
    private cicoService: PmsCiCoService,
    private sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit(): void {
    timer(0, 0)
      .pipe(take(1))
      .subscribe(() => {
        if (this.data.module.description) {
          this.description = this.sanitizer.bypassSecurityTrustHtml(this.data.module.description);
        }
      });
  }

  closeOverlay() {
    this.cicoService.closeOverlay(OverlayAction.start);
  }
}
