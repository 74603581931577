<div *ngIf="business && !content?.component; else component">
  <div class="background overlay generic-overlay" [class.with_back]="content?.with_back">
    <div class="popup">
      <h2 class="title main-text" [innerHTML]="content?.title || content?.alternative?.title"></h2>
      <p class="text" [innerHTML]="content?.content?.text"></p>

      <p class="total-container" *ngIf="this.content?.inputParams?.amount">
        {{ this.content.inputParams.name | translate: { amount: this.content.inputParams.amount } }}
      </p>

      <div class="buttons-container">
        <button
          *ngIf="content?.button"
          class="btn-outline"
          (click)="onClick(content?.button?.action, content?.button?.guard)"
        >
          {{ content?.button?.title }}
        </button>
        <button
          class="btn-filled"
          [class.full-width]="!content?.button?.title"
          *ngIf="content?.alternative"
          (click)="onClick(content?.alternative?.action, content?.alternative?.guard)"
        >
          {{ content?.alternative?.title }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #component>
  <div [class.background]="content?.with_back">
    <div
      class="component-overlay"
      [id]="content?.id?.length ? content?.id : ''"
      [class.with_back]="content?.with_back"
    >
      <div class="clearfix" *ngIf="closeable">
        <div class="close-icon" (click)="onClick(overlayAction.close)">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <ng-template #dynamicContent></ng-template>
    </div>
  </div>
</ng-template>
