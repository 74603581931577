import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GenericOverlay } from 'models/pms/generic_overlay';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { DoorType, LostKeyStep, UserActionType, OverlayAction, OverlayType } from 'pms_enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pms-terminal-door',
  templateUrl: './door.component.html',
  styleUrls: ['../option/option.component.scss', './door.component.scss'],
})
export class PmsWizardTerminalDoorComponent implements OnInit, OnDestroy {
  readonly DoorType = DoorType;
  private subscriptions: Subscription = new Subscription();

  @ViewChild('serviceForm') serviceForm: NgForm;

  @Input() reservation: any;
  @Input() type: DoorType;

  encoderData: any;
  lostKeyStep = LostKeyStep;
  tmp_can_encode: boolean;

  showOverlay = false;
  olContent: GenericOverlay;
  mainActions = [
    { type: 'lost', text: 'pms_door.terminal.reason.lost', icon: 'fas fa-exclamation-triangle' },
    { type: 'duplicate', text: 'pms_door.terminal.reason.duplicate', icon: 'fas fa-key' },
  ];

  constructor(
    public cicoService: PmsCiCoService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.subOverlay();
    this.navSubscription();

    this.encoderData = {
      ...this.reservation,
      ...{
        isLost: null,
        furtherIsLost: null,
        internalEncoder: this.globals.place.internal_encoder,
      },
    };
    this.cicoService.disableNextButton(true);

    // Handle encoder setup and door type-specific logic
    if (!this.encoderData.already_encoded) {
      this.setEncoder();
    } else if (this.type === DoorType.cards) {
      this.cicoService.doorStep = LostKeyStep.lostOrDuplicate;
    } else if (this.type === DoorType.pin_codes) {
      this.setPinCodes();
    }
    this.cicoService.headerActionSubj.next(UserActionType.cancel);

    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe((close: any) => {
        if (close.action === OverlayAction.reallySure) {
          this.setEncoder();
        }
        this.globals.markAllControlsTouched(this.serviceForm);
      }),
    );
  }

  lostChanged(type) {
    this.cicoService.cardLost = type;
    this.encoderData.isLost = this.cicoService.cardLost;
    if (this.encoderData.isLost === 'lost') {
      this.tmp_can_encode = this.encoderData.can_encode;
      this.encoderData.can_encode = true;
      this.cicoService.openOverlay(OverlayType.reallySure);
      this.subscriptions.add(
        this.cicoService.overlayClose.subscribe(
          (close: any) => {
            if (close.action === OverlayAction.close) {
              this.encoderData.can_encode = this.tmp_can_encode;
            }
          },
          () => {},
        ),
      );
    } else {
      this.cicoService.headerActionSubj.next(UserActionType.back);
      this.setEncoder();
    }
  }

  private setPinCodes(): void {
    this.cicoService.doorStep = LostKeyStep.pinCodes;
  }

  private setEncoder(): void {
    this.cicoService.doorStep = LostKeyStep.encoder;
  }

  navSubscription() {
    this.subscriptions.add(
      this.cicoService.navigation.subscribe((type) => {
        if (type === UserActionType.back && this.cicoService.doorStep === LostKeyStep.encoder) {
          this.cicoService.doorStep = LostKeyStep.lostOrDuplicate;
          this.cicoService.headerActionSubj.next(UserActionType.cancel);
        }
      }),
    );
  }

  subOverlay() {
    this.subscriptions.add(
      this.cicoService.overlay.subscribe((content) => {
        this.olContent = content;
        this.showOverlay = true;
      }),
    );

    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe((close: any) => {
        switch (close.action) {
          case OverlayAction.cancel:
            document.getElementById('container').classList.add('overlay');
            break;
          case OverlayAction.reallySure:
          case OverlayAction.skip:
          case OverlayAction.close:
            document.getElementById('container').classList.add('overlay');
            this.showOverlay = false;
            break;
          default:
            break;
        }
      }),
    );
  }

  back() {
    this.cicoService.setProcess(null);
    this.cicoService.navigate(UserActionType.cancel);
    this.cicoService.headerActionSubj.next(UserActionType.cancel);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
