import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsService } from 'pms_service';

@Component({
  selector: 'app-form-image',
  templateUrl: './image.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ImageComponent implements OnInit {
  @Input() model: any;
  @Input() key: string;
  @Input() required = false;
  @Input() webcam = false;
  @Input() pdf = false;
  @Input() removable: boolean;

  @Output() success = new EventEmitter<any>();
  @Output() capture = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  @ViewChild('fileInput', { static: false }) fileInput: NgModel;

  MAXSIZE = 8000000;
  VALID_TYPES = ['jpeg', 'jpg', 'png'];

  id = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 6);
  image: any;
  loading: boolean;
  accept: string;

  constructor(
    private pmsService: PmsService,
    private cicoService: PmsCiCoService,
    private globals: Globals,
  ) {}

  ngOnInit() {
    if (this.pdf) {
      this.VALID_TYPES.push('pdf');
    }
    this.accept = this.VALID_TYPES.map((type) => '.' + type).join(',') + ',capture=camera';
  }

  onFileChange(event) {
    this.loading = true;
    const imageInput = event.target;
    const reader = new FileReader();
    if (imageInput.files?.length) {
      const file = imageInput.files[0];
      if (this.checkFile(file)) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = String(reader.result);
          if (base64.startsWith('data:image') || 'data:application/pdf') {
            this.success.emit(base64);
            if (this.key === 'passport') {
              this.checkPassport(base64);
            } else {
              this.loading = false;
              this.model[this.key] = base64;
            }
          }
        };
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  checkPassport(base64) {
    this.pmsService.documentIsValid(base64, this.model.group).subscribe(
      () => {
        this.globals.alert(
          'success',
          this.globals.translate('booking_data.passport_image.success'),
          this.globals.translate('service.process.success'),
        );
        this.cicoService.cacheImage('passport', this.model.uuid, base64);
        this.model.passport = base64;
      },
      () => {
        this.globals.alert(
          'error',
          this.globals.translate('validation.passport'),
          this.globals.translate('misc.error'),
        );
        this.resetImage(true);
        this.model.passport = null;
        this.loading = false;
      },
    );
  }

  checkFile(file: any): boolean {
    let error = '';
    if (file.size > this.MAXSIZE) {
      error = this.globals.translate('validation.file_size');
    }
    if (!this.VALID_TYPES.filter((type) => file.type.includes(type)).length) {
      error = this.globals.translate('validation.file_type');
    }
    const type = file.type.split('/')[0];
    const subtype = file.type.split('/')[1];
    const mime_type = type === 'image' || (this.pdf && subtype === 'pdf');
    if (!mime_type) {
      error = this.globals.translate('validation.mime_type');
    }
    if (error.length) {
      this.globals.alert('error', error);
      this.loading = false;
      return false;
    } else {
      return true;
    }
  }

  startCapture() {
    if (this.webcam) {
      this.capture.emit();
    } else {
      if (document.createEvent) {
        const evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, false);
        this.helper().dispatchEvent(evt);
      }
    }
  }

  resetImage(error = false) {
    this.loading = false;
    this.model[this.key] = null;
    if (!error) {
      this.globals.clearAlert();
    }

    const file = this.helper();
    if (file) {
      (<HTMLInputElement>file).value = '';
    }
  }

  abortCapture() {
    this.loading = false;
  }

  helper(): any {
    const id = this.id ? '_' + this.id : '';
    return document.getElementById('image_helper_' + this.key + id);
  }
}
