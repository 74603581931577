import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
})
export class FileComponent implements OnInit {
  file: File;
  url: string;
  blank: boolean;

  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = params['id'];

      this.route.queryParams.subscribe((query) => {
        this.blank = query['blank'] === 'true';
      });

      this.api.get('files/' + id).subscribe(
        (success) => {
          this.file = success['attachment'];
          if (this.blank) {
            location.href = this.file['file_url'];
          } else {
            this.globals.page = this.file['gid'];
            this.url = this.file['file_url'];
          }
        },
        () => {},
      );
    });
  }

  mouseEnter() {
    document.body.classList.add('scroll_lock');
  }

  mouseLeave() {
    document.body.classList.remove('scroll_lock');
  }
}
