import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'pipes/safe.pipe.module';
import { BacklinkModule } from '../layout/backlink/backlink.module';
import { FileRoutingModule } from './file-routing.module';
import { FileComponent } from './file.component';

@NgModule({
  imports: [CommonModule, FileRoutingModule, SafePipeModule, BacklinkModule, TranslateModule],
  exports: [FileComponent],
  declarations: [FileComponent],
})
export class FileModule {}
