import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QuantityRoutingModule } from './quantity-routing.module';
import { QuantityComponent } from './quantity.component';

@NgModule({
  imports: [CommonModule, QuantityRoutingModule, TranslateModule],
  exports: [QuantityComponent],
  declarations: [QuantityComponent],
})
export class QuantityModule {}
