import { Component, OnInit } from '@angular/core';
import { BusinessGroup } from 'models/business_group';
import { Socialmedia } from 'models/socialmedia';
import { BusinessGroupService } from 'services/business_group.service';
import { Globals } from 'base';

@Component({
  selector: 'app-social-portfolio',
  templateUrl: './group_social.component.html',
})
export class GroupSocialComponent implements OnInit {
  business_group: BusinessGroup;
  social: Socialmedia;
  social_keys: string[];

  constructor(
    public globals: Globals,
    private businessGroupService: BusinessGroupService,
  ) {}

  ngOnInit() {
    this.businessGroupService.getBusinessGroup().subscribe(
      (success) => {
        this.business_group = new BusinessGroup(success['business_group']);
        this.social = this.business_group.socialmedia;
        this.social_keys = Object.keys(this.social);
      },
      () => {},
    );
  }
}
