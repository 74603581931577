import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MediaFilterPipe } from './mediaFilter.pipe';

const routes: Routes = [{ path: '', component: MediaFilterPipe }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MediaFilterPipeRoutingModule {}
