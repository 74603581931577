import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { MenuModule } from 'common/menu/menu.module';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ShopListParentRoutingModule } from './parent-routing.module';
import { ShopListParentComponent } from './parent.component';
import { ServiceParentDirective } from 'shared/service/parent.directive';

@NgModule({
  imports: [
    CommonModule,
    ShopListParentRoutingModule,
    TranslateModule,
    AttachmentsModule,
    FilterPipeModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    NgPipesModule,
    BacklinkModule,
    MenuModule,
    FormsModule,
  ],
  exports: [ShopListParentComponent],
  declarations: [ShopListParentComponent],
  providers: [ServiceParentDirective],
})
export class ShopListParentModule {}
