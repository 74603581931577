<app-module-title [module]="module" *ngIf="replaced && module"></app-module-title>
<div class="popup-text">
  <div class="choose-mood text-center clearfix" [hidden]="response" *ngIf="moods">
    <div class="emoji" (click)="answer('good', $event)" *ngIf="moods.indexOf('good') > -1">😊</div>
    <div class="emoji" (click)="answer('neutral', $event)" *ngIf="moods.indexOf('neutral') > -1">
      😐
    </div>
    <div class="emoji" (click)="answer('bad', $event)" *ngIf="moods.indexOf('bad') > -1">😞</div>
  </div>

  <form class="comment" #serviceForm="ngForm">
    <ng-container *ngIf="response">
      <div class="text" *ngIf="text" [innerHtml]="response.content"></div>

      <fieldset>
        <ng-container *ngIf="comment">
          <ng-container *ngIf="response.show_additional_fields && !this.globals.validPmsGuest()">
            <div class="form-box">
              <label translate>misc.name</label>
              <input
                name="name"
                #name="ngModel"
                [(ngModel)]="service.name"
                placeholder="{{ 'misc.name' | translate }}"
                [required]="response.require_additional_fields"
              />
              <label translate class="error">validation.name</label>
            </div>

            <div class="form-box">
              <label translate>misc.mail</label>
              <input
                type="email"
                #email="ngModel"
                [(ngModel)]="service.email"
                name="email"
                placeholder="{{ 'misc.mail' | translate }}"
                emailvalidator
                [required]="response.require_additional_fields"
              />
              <label translate class="error">validation.email</label>
            </div>
          </ng-container>

          <app-form-place
            [service]="service"
            [hide_name]="true"
            [required]="response.require_place_field"
            *ngIf="response.place_field"
          ></app-form-place>

          <div class="form-box">
            <label translate>request.comment</label>
            <textarea
              name="body"
              #body="ngModel"
              [(ngModel)]="service.body"
              placeholder="{{ 'request.comment_text' | translate }}"
              required="required"
            ></textarea>
            <label translate class="error">request.comment_text</label>
          </div>
        </ng-container>

        <div class="button-grid">
          <div class="mood-comment">
            <span translate class="button green" (click)="action()">{{ confirm_name }}</span>
          </div>
        </div>
        <app-form-legal [text]="'privacy_policy'"></app-form-legal>
      </fieldset>
    </ng-container>
  </form>
</div>
