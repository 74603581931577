<section id="background" [class.landing]="!mainPage">
  <ng-container *ngIf="medias?.length">
    <div
      *ngFor="let media of medias | mediaFilter: 'image'"
      [class.active]="media.media.position === minPosition || medias.length === 1"
      data-type="image"
      [attr.data-duration]="imageTrigger * 1000"
    >
      <img
        [src]="encodeParentheses(media.media.urls.normal)"
        [srcset]="
          '' +
          encodeParentheses(media.media.urls.normal) +
          ' 2000w, ' +
          encodeParentheses(media.media.urls.original) +
          ' 3000w'
        "
        alt="background"
        *ngIf="media.media.urls"
      />
    </div>
    <div
      *ngFor="let media of medias | mediaFilter: 'video'"
      [class.active]="media.media.position === minPosition || medias.length === 1"
      data-type="video"
      [attr.data-duration]="media.media.duration * 1000"
    >
      <video loop muted playsinline>
        <source [src]="media.media.url.webm" type="video/webm" />
        <source [src]="media.media.url.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </ng-container>
</section>
<section id="c-background" *ngIf="mainPage"></section>
