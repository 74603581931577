import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetGroupRoutingModule } from './group-routing.module';
import { WidgetGroupComponent } from './group.component';

@NgModule({
  imports: [CommonModule, WidgetGroupRoutingModule],
  exports: [WidgetGroupComponent],
  declarations: [WidgetGroupComponent],
})
export class WidgetGroupModule {}
