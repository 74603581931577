import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { LabelModule } from 'shared/forms/label/label.module';
import { FormPhoneFieldModule } from '../../../shared/forms/phone/phone_field.module';
import { FormSelectModule } from '../../../shared/forms/select/select.module';
import { PmsGuestRoutingModule } from './guest-routing.module';
import { PmsGuestComponent } from './guest.component';
import { TypographyModule } from 'common/typography/typography.module';
import { TooltipModule } from 'common/tooltip/tooltip.module';
import { MainTitleModule } from 'modules/pms/shared/main-title/main-title.module';
import { GuestIndexModule } from 'modules/pms/shared/guest-index/guest-index.module';
import { FormFieldsModule } from 'modules/shared/forms/fields/fields.module';
import { PassportFormModule } from 'modules/shared/forms/passport-form/passport-form.module';
import { PassportPhotoModule } from 'modules/shared/forms/passport-photo/passport-photo.module';
import { LoaderModule } from 'common/loader/loader.module';
import { PmsAuthenticationModule } from 'modules/pms/check_in/authentication/authentication.module';
import { PmsInvoiceAddressFormModule } from '../invoice_address/form/form.module';
import { PmsAddressModule } from '../address/address.module';
import { ValueAccessorModule } from 'app/value-accessors/value-accessor-module';
import { DateValidatorModule } from 'validators/date.validator.module';
import { ValidationMessagePipeModule } from 'pipes/validation-message.pipe.module';

@NgModule({
  imports: [
    CommonModule,
    PmsGuestRoutingModule,
    LabelModule,
    FormPhoneFieldModule,
    FormsModule,
    DatePickerModule,
    TranslateModule,
    FormSelectModule,
    EmailValidatorModule,
    ValidationMessagePipeModule,
    TypographyModule,
    TooltipModule,
    MainTitleModule,
    GuestIndexModule,
    FormFieldsModule,
    PassportFormModule,
    PassportPhotoModule,
    LoaderModule,
    PmsAuthenticationModule,
    PmsInvoiceAddressFormModule,
    PmsAddressModule,
    ValueAccessorModule,
    DateValidatorModule,
  ],
  exports: [PmsGuestComponent],
  declarations: [PmsGuestComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsGuestModule {}
