<form #form="ngForm" class="redesign" *ngIf="data?.module?.type === ciCoType.co">
  <fieldset class="co-container">
    <ng-container *ngIf="data.incident?.reservation?.primary_guest">
      <straiv-reservation-card
        is-upgrade-card="false"
        min-height="185px"
        size="medium"
        selectable="false"
        [imageUrl]="reservationCardData.media"
        [extrasHeading]="reservationCardData.extras.heading"
        [extras]="reservationCardData.extras.data"
        [heading]="reservationCardData.heading"
        [description]="reservationCardData.description"
        [viewDetailsButtonText]="'booking_data.transportation.viewDetails' | translate"
        [arrivalDate]="reservationCardData.dates.arrival"
        [departureDate]="reservationCardData.dates.departure"
        [checkInTime]="reservationCardData.times.checkInTime"
        [checkOutTime]="reservationCardData.times.checkOutTime"
      ></straiv-reservation-card>

      <straiv-input
        ngDefaultControl
        [name]="'email'"
        #email="ngModel"
        placeholder="yourname@mail.de"
        type="email"
        mode="email"
        customEmailValidator
        [required]="field_for('email').required"
        [(ngModel)]="data.incident.reservation.primary_guest.email"
        [errorMessage]="email.errors | validationMessage | translate"
        [labelText]="field_for('email').name"
      ></straiv-input>

      <app-pms-invoice-address-form
        [data]="data"
        *ngIf="ownInvoiceAddress"
      ></app-pms-invoice-address-form>

      <app-form-fields
        [form]="data.incident"
        [submited]="submited"
        [module]="data.module"
        [redesign]="true"
        #fieldsComponent
        *ngIf="data.module.fields"
      ></app-form-fields>
    </ng-container>
  </fieldset>
</form>
