<ng-container *ngIf="data && !deviatingBillingAdr && selectedAddress">
  <div *ngIf="newDesign">
    <div class="folio-header" *ngIf="!isStaticAddress(); else addressReadOnly">
      <label
        *ngIf="usedAddress.valid()"
        [for]="'address_' + number + '_' + 0"
        class="address redesign"
        data-cy="address-block"
        [innerHtml]="htmlContent"
        translate
      ></label>
      <span *ngIf="!usedAddress.valid()" class="error" (click)="editInvoiceAdr()">
        {{ 'address.valid_adr' | translate }}
      </span>
      <straiv-button
        name="edit-address"
        variant="outline"
        data-cy="edit-address"
        [leftLabel]="'address.edit_address' | translate"
        size="xs"
        (buttonClick)="editInvoiceAdr()"
      ></straiv-button>
    </div>

    <ng-template #addressReadOnly>
      <div
        class="address"
        [innerHtml]="usedAddress.isEmpty() ? 'address.valid_adr' : usedAddress.htmlBlock(true)"
        translate
      ></div>
    </ng-template>
  </div>
</ng-container>

<!-- Display content directly on the page when dialog is closed -->
<ng-container
  *ngIf="!isDialogOpen && deviatingBillingAdr"
  [ngTemplateOutlet]="addressContent"
></ng-container>

<!-- Dialog with the same content conditionally displayed -->
<straiv-dialog
  id="dialog"
  size="md"
  [closeOnClickOutside]="false"
  [open]="isDialogOpen"
  (dialogClose)="closeDialog()"
>
  <straiv-dialog-header
    class="straiv-dialog"
    [dialogTitle]="'address.change_billing_address' | translate"
    title-alignment="center"
  ></straiv-dialog-header>
  <ng-container
    *ngIf="cicoService.addresses.length === 0 && cicoService.folioAddressStep === 'select'"
  >
    {{ 'address.valid_adr' | translate }}
  </ng-container>
  <ng-container [ngTemplateOutlet]="addressContent"></ng-container>
  <straiv-dialog-actions
    *ngIf="cicoService.folioAddressStep === 'create'"
    [primaryButtonLeftLabel]="'address.add_address' | translate"
    [secondaryButtonLeftLabel]="'misc.cancel' | translate"
    [shouldCloseOnSecondaryBtnClick]="false"
    (primaryButtonClick)="onClickAddAddress()"
    (secondaryButtonClick)="onClickCancel()"
  ></straiv-dialog-actions>
</straiv-dialog>

<!-- Define the reusable content as an ng-template -->
<ng-template #addressContent>
  <ng-container *ngIf="(data && newDesign) || deviatingBillingAdr">
    <div>
      <div
        class="invoices-container"
        *ngIf="cicoService.folioAddressStep === 'select' && !deviatingBillingAdr"
      >
        <div
          *ngFor="let address of cicoService.addresses | addressPipe; let i = index"
          class="list-container"
        >
          <div class="addresses-list" [ngClass]="{ highlighted: i === highlightedIndex }">
            <app-address-item
              [address]="address"
              [index]="i"
              [changeable]="!fieldsInactive && address.manual"
              (onSelection)="onSelectionOfAddress(i)"
              (editClick)="onClickEditManualAddress($event)"
              (deleteClick)="onClickDeleteManualAddress($event)"
            ></app-address-item>
          </div>
        </div>
        <div class="heading-container">
          <straiv-button
            name="new-address-button"
            variant="outline"
            data-cy="new-address-button"
            [disabled]="fieldsInactive || cicoService.addresses.length >= maxAdresses"
            [leftLabel]="'address.add_address' | translate"
            size="sm"
            (buttonClick)="onClickAddNewAddress()"
          ></straiv-button>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="
        ((usedAddress?.manual && cicoService.folioAddressStep === 'create') ||
          deviatingBillingAdr) &&
        fields &&
        !isStaticAddress()
      "
    >
      <div id="address-form">
        <ng-container *ngIf="isDialogOpen; else withOutForm">
          <form #addressForm="ngForm">
            <pms-address
              ngDefaultControl
              [guestAddress]="usedAddress"
              [fieldType]="'popup_address'"
              [field]="fields"
              [data]="data"
              [isBillingAddress]="deviatingBillingAdr"
              (onChangeCountry)="handleCountryChange($event)"
            ></pms-address>
          </form>
        </ng-container>
        <ng-template #withOutForm>
          <pms-address
            ngDefaultControl
            [guestAddress]="usedAddress"
            [fieldType]="'invoice_address'"
            [field]="fields"
            [data]="data"
            [isBillingAddress]="deviatingBillingAdr"
            (onChangeCountry)="handleCountryChange($event)"
          ></pms-address>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
