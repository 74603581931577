import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanValidatorDirective } from './boolean.validator';

@NgModule({
  imports: [CommonModule],
  exports: [BooleanValidatorDirective],
  declarations: [BooleanValidatorDirective],
})
export class BooleanValidatorModule {}
