import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { ConfirmPage } from 'models/confirm_page';
import { Module } from 'models/module';
import { BusinessService } from 'services/business.service';
import { ServiceService } from 'services/service.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  confirmPage: ConfirmPage;
  module: Module;
  showSocial: boolean;

  constructor(
    private serviceService: ServiceService,
    private businessService: BusinessService,
  ) {}

  ngOnInit(): void {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.showSocial = business.style.social_in_modal;
    });
    this.subscriptions.add(
      this.serviceService.confirmPage.subscribe((confirmPage) => {
        this.confirmPage = confirmPage;
        this.module = new Module({ name: confirmPage?.title });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
