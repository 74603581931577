import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsBaseDirective } from 'pms_base/base.directive';
import {
  ConfirmName,
  OverlayAction,
  OverlayType,
  PmsModType,
  PreventionStep,
  ProcessStep,
  Step,
} from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsService } from 'pms_service';
import { filter, take } from 'rxjs/operators';
import { Field } from 'models/field';
import { StorageService } from 'services/storage.service';
import { StepperService } from 'services/pms/stepper.service';
import { InfoComponent } from '../info/info.component';
import { PmsGuest } from 'models/pms/pms_guest';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { FieldValue } from 'models/field_value';
import { ReservationCard } from 'models/reservation_card';

@Component({
  selector: 'app-pms-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class PmsCheckInConfirmComponent
  extends PmsBaseDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() submitted?: boolean = false;
  processes = [];
  success = [];
  canceled = [];
  failed = [];

  sending: boolean;
  processSubmitted: boolean = false;
  done: boolean;
  legalInfo: any;
  legal: any;
  legalTypes = ['city_tax', 'visitor_tax', 'tourism_fee'];
  incident: any;
  reservationCardData: ReservationCard;

  confirmation: any;
  doneButton: string;
  variantDoneButton: string = 'outline';
  notCompleteButtonLabels: { [key: string]: string } = {
    inform_pms: 'service.check_in.general.finish',
    invoice_address: 'service.check_in.general.go_payments',
    check_room: 'service.check_in.general.try_again',
  };

  doorStep: Array<string> = [];
  doorStepWithCards: boolean = false;

  processText: string = '';
  showSignature: boolean;
  resReceivedNotSuccess: boolean = false;
  confirmType: string = 'success';
  checkInFinished: boolean = false;

  fields: { [key: string]: Field } = {};
  mappedFormFieldsValues: { [key: string]: FieldValue } = {};

  digitalSignatureField: any;

  constructor(
    public globals: Globals,
    private pmsService: PmsService,
    private api: ApiService,
    protected stepperService: StepperService,
    public cicoService: PmsCiCoService,
    private storageService: StorageService,
    protected readonly eventService?: EventAggregatorService,
  ) {
    super(cicoService, Step.confirm, undefined, stepperService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cicoService.setAutoSkipUntilStep(undefined);
  }

  ngAfterViewInit(): void {
    // To make sure the view is fully rendered, in order to resize the canvas internally in stencil.
    requestAnimationFrame(() => {
      this.signatureCheck();
    });
  }

  protected fetchData() {
    this.subscriptions.add(
      this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        this.data = data;
        this.legalInfo = this.legalInfos();
        this.legal = this.globals.business?.legal;
        if (this.data.module.type === PmsModType.co) {
          this.checkCoAnswers();
        } else {
          this.setFields();
        }

        this.data.incident.prevention = this.data.blank ? '' : this.prevention() || '';
        this.data.incident.prevented = this.data.blank || this.data.incident.prevention.length > 0;
        this.cicoService.setShowFooter(true);

        try {
          this.data.incident.checks.debug_paid = this.data.incident.reservation.foliosPaid(
            this.data,
          );
        } catch (e) {
          try {
            Bugsnag.notify(e.message);
          } catch (e) {
            console.log(e.message);
          }
        }

        this.setFooterActionBtn();
        this.reservationCardData = this.cicoService.setReservationCardInfo(this.data);
      }),
    );
    // when user click (send or check-in/check-out) button, label depends
    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.checkInOutAction).subscribe(() => {
        this.processSubmitted = true;
        this.createIncident();
      }),
    );
  }

  /**Set primary button's label in the footer, continue/Check in/Check out*/
  setFooterActionBtn(): void {
    let name;
    if (this.data.incident.prevented) {
      name = ConfirmName.next;
    } else {
      name = this.data.module.type === PmsModType.ci ? ConfirmName.ci : ConfirmName.co;
    }
    this.cicoService.confirmNameSubj.next(name);
  }

  createIncident() {
    this.clearPassportVisa();
    this.resetStates();
    this.sending = true;
    this.globals.clearAlert(true);
    this.cicoService.disableButtons(true);
    const time = this.cicoService.duration();

    this.data.incident.reservation.persons =
      this.data.incident.reservation.adults + this.data.incident.reservation.children;
    this.legalInfo = [];
    this.api
      .post('incident', {
        duration: time.seconds < 3600 ? time.nano : null, //less then 1 hour
        uuid: this.data.uuid,
        payment: this.data.payment,
        module: this.data.module.type,
        settings: this.data.module.settings,
        late_reg_form: this.cicoService.late_reg_form,
        should_payment: this.cicoService.should_payment,
        params: Object.assign(this.data.incident),
      })
      .subscribe(
        (success: any) => {
          this.pmsService.clear();
          this.startProcess(success);
          this.cicoService.addresses = [];
        },
        (e) => {
          this.sending = false;
          this.cicoService.openOverlay(OverlayType.reset);
          this.cicoService.disableButtons(false);
          try {
            Bugsnag.notify(e.message);
          } catch (e) {
            console.log(e.message);
          }
        },
      );
  }

  startProcess(response) {
    window.scrollTo(0, 0);
    this.cicoService.toggleInactivity(false);

    if (!response.processes.length && response.confirmation) {
      this.data.done = true;
      this.confirmation = response;
      return;
    }

    this.processes = response.processes;
    this.incident = response.incident;

    this.checkProcess(0);

    this.subscriptions.add(
      this.cicoService.suppressGuard.subscribe((suppressGuard) => {
        this.done = suppressGuard;
        this.cicoService.disableBack = true;
      }),
    );
  }

  checkProcess(index) {
    const task = this.processes[index];
    const params = { uuid: this.data.incident.reservation.uuid, task: task };
    this.processText = task;
    this.api.silentPut('incident/' + this.incident, params).subscribe(
      (response: any) => {
        if (response.success) {
          this.success.push(task);
        } else {
          this.failed.push(task);
          this.canceled = this.processes.filter(
            (elem) => !this.success.includes(elem) && !this.failed.includes(elem),
          );
        }
        if (response.confirmation) {
          this.data.done = true;
          this.confirmation = response.confirmation;
          this.reservationCardData.heading = this.confirmation.room || '';
          this.storageService.removeKey(
            this.data.incident.getStorageKey(),
            this.data.business.code,
          );
          this.cicoService.toggleInactivity(true);
          this.cicoService.infoScreen = true;
          if (response.success && this.failed.length === 0) {
            if (this.confirmation.encoderData?.autoEncode) {
              this.cicoService.setShowFooter(false);
            }
            this.doorStep = this.doorStep.concat(this.confirmation.door_step);
            this.doorStepWithCards = this.doorStep.includes('cards');
            this.cicoService.encoderData = this.confirmation.encoderData;
            this.cicoService.suppressGuardSubj.next(!this.doorStep.includes('cards'));
            // checkInFinished check if all processes are done including the task = 'check_in'
            this.checkInFinished = this.processText === ProcessStep.CHECK_IN;
          } else {
            this.resReceivedNotSuccess = true;
            this.cicoService.suppressGuardSubj.next(true);
          }
          this.confirmType = this.checkConfirmStatus();
          this.doneButton = this.handleDoneBtnLabel(this.data.module.type);
        } else {
          this.checkProcess(index + 1);
        }
      },
      () => {},
    );
  }

  resetStates() {
    this.processes = [];
    this.success = [];
    this.canceled = [];
    this.failed = [];
  }

  openOverlay(type) {
    this.api.silentGet('legal/' + type).subscribe((success: any) => {
      this.cicoService.openComponentOverlay(InfoComponent, { legal: success.legal, kind: type });
    });
  }

  legalInfos() {
    const legal = (this.data?.business || this.globals.business)?.legal || {};
    return this.legalTypes
      .map((type) => {
        if (legal[type]) {
          return { type: type, text: this.globals.translate('legal.form.' + type) };
        }
      })
      .filter((entry) => entry !== undefined);
  }

  checkCoAnswers() {
    const answers = this.data.incident.field_values.filter(
      (field) => field.check_out_if !== 'co_none' && field.kind === 'polar',
    );
    this.filterQuestions().forEach((question, _i) => {
      const field = answers.find((answer) => answer.id === question.id);
      const status = !!field.value;
      field.valid =
        (question.check_out_if === 'co_false' && !status) ||
        (question.check_out_if === 'co_true' && status);
    });
    this.data.incident.checks.questions =
      !this.filterQuestions().length || answers.every((answer) => answer.valid);
  }

  filterQuestions(): Field[] {
    return this.data.module.fields.filter(
      (field) => field.check_out_if !== 'co_none' && field.kind === 'polar',
    );
  }

  prevention(): string {
    switch (this.data.module.type) {
      case PmsModType.ci:
        if (
          this.data.module.settings.wrong_device &&
          this.data.module.settings?.should_check_in &&
          this.inTime()
        ) {
          return PreventionStep.WRONG_DEVICE;
        }
        if (this.cicoService.step.invoice) {
          if (this.data.folioInfo?.error || this.data.folioInfo?.noFolios) {
            if (!this.data.module.settings?.allow_folio_error) {
              return PreventionStep.NOT_LOADED;
            }
          } else if (
            'paid_folios' in this.data.incident.checks &&
            !this.data.incident.checks.paid_folios &&
            this.data.module.settings?.required_payments &&
            this.cicoService.should_payment
          ) {
            return PreventionStep.NOT_PAID;
          }
        }
        if (this.data?.module?.settings?.should_check_in && !this.inTime()) {
          return PreventionStep.INVALID_TIME;
        }
        if (!this.data.incident.reservation[`can_${this.data.module.pmsType()}`]) {
          return PreventionStep.CANT_PERFORM;
        }
        if (
          this.data.incident.checks.hasOwnProperty('auth_done') &&
          !this.data.incident.checks.auth_done
        ) {
          return PreventionStep.NOT_AUTHORIZED;
        }
        break;
      case PmsModType.co:
        if (this.data.module.settings.wrong_device && this.inTime()) {
          return PreventionStep.WRONG_DEVICE;
        }
        if (this.cicoService.step.invoice && this.data.folioInfo?.error) {
          return PreventionStep.NOT_LOADED;
        }
        if (!this.data.incident.checks.paid_folios) {
          return PreventionStep.NOT_PAID;
        }
        if (!this.inTime()) {
          return PreventionStep.INVALID_TIME;
        }
        if (!this.data.incident.reservation['can_' + this.data.module.pmsType()]) {
          return PreventionStep.CANT_PERFORM;
        }
        if (!this.data.incident.checks.questions) {
          return PreventionStep.INVALID_ANSWERS;
        }
        break;
    }
  }

  inTime() {
    return this.data.incident.reservation.in_time;
  }

  signatureCheck() {
    if (this.data.module.type === PmsModType.co || !('signature' in this.fields)) {
      return;
    }
    this.digitalSignatureField = this.data.module.fields.find(
      (field) => field.identifier === 'signature',
    );
    this.mappedFormFieldsValues.signature.value = '';
    this.showSignature =
      this.digitalSignatureField?.active &&
      this.data.incident.reservation.should_sign &&
      !(
        this.data.incident.reservation.signedBySca() || this.data.incident.reservation.authenticated
      );
  }

  endProcess() {
    this.cicoService.closeOverlay(OverlayAction.cancel, false);
  }

  /**
   * Get the event from the signature box to get the signature.
   * @param $event event emitted from stencil component (straiv-signature-box).
   */
  updateSignature($event) {
    this.mappedFormFieldsValues.signature.value = $event.detail;
  }

  /** Choose the correct action for the not complete button depending on the process where the check-in process has stopped. */
  handleNotCompletebtn() {
    switch (this.processText) {
      // Requierd folio to be paied.
      case ProcessStep.INVOICE_ADDRESS:
        {
          this.goToPayment();
        }
        break;
      case ProcessStep.CHECK_ROOM:
        {
          this.cicoService.closeOverlay(OverlayAction.cancel, false);
        }
        break;
    }
  }

  /** go back to payment step to make the user pays the open balance since the folio is required */
  goToPayment() {
    this.data.done = false;
    this.stepperService.previousStep();
  }

  private clearPassportVisa() {
    this.data.incident.reservation.allGuests().forEach((guest: PmsGuest) => {
      if (!guest.passport_data) {
        guest.passport_id = null;
        guest.passport_authority = null;
        guest.passport_date = null;
        guest.passport_expire = null;
      }
      if (!guest.visa_data) {
        guest.visa_number = null;
        guest.visa_date = null;
        guest.visa_expire = null;
      }
    });
  }

  /**
   * @param moduleType define which is the current module (check-in or check-out)
   * It specifies the lable of the done button. Additionally, change the style between filled or outlined button
   * depending on the case.
   */
  private handleDoneBtnLabel(moduleType: string): string {
    let labelKey: string = '';
    switch (moduleType) {
      case PmsModType.ci:
        labelKey = this.setDoneBtnLabelCheckIn();
        this.variantDoneButton = this.checkBtnDoneVariant();
        break;
      case PmsModType.co:
        labelKey = 'service.check_out.finish';
        break;
    }

    return this.globals.translate(labelKey);
  }

  /**
   * Specify which label for the done btn in case of check-in
   * @returns the required label to be displayed depending on the case (not check-in times or check-in complete and we have a room assigned)
   * Labels are Finish, Finish later, Finish check-in
   */
  private setDoneBtnLabelCheckIn(): string {
    return this.data.incident.prevention === PreventionStep.INVALID_TIME
      ? 'misc.finish'
      : this.confirmation.room
        ? 'service.check_in.general.finish'
        : 'service.check_in.general.finish_later';
  }

  /**
   * check the type of straiv-confirm either success or error depending on the module type (ci or co) and the current case.
   * @returns success or error
   */
  private checkConfirmStatus(): string {
    if (this.data.module.type === PmsModType.ci) {
      // if it is not the check-in time, data will be transfered and it is success screen for straiv-confirm.
      if (this.data.incident.prevention === PreventionStep.INVALID_TIME) {
        return 'success';
      }
      // if the check-in process has failed or not complete during a specific task according to the processes that should be done successfully
      if (this.resReceivedNotSuccess || !this.checkInFinished) {
        return 'error';
      }
    }
    return 'success';
  }

  /**
   * check if the done button is the primary button (flat) or secondary button (outline)
   * @returns flat or outline
   */
  private checkBtnDoneVariant(): string {
    // if it is not the check-in time => done btn is the only btn visible and it is the primary btn => variant = flat
    // if the check-in process has finished and no encoding btn => done btn is the only btn visible and it is the primary btn => variant = flat.
    let variant = 'outline';
    if (
      this.data.incident.prevention === PreventionStep.INVALID_TIME ||
      (!this.doorStepWithCards && this.checkInFinished)
    ) {
      variant = 'flat';
    }
    return variant;
  }

  /** Prepare system fields for the last form of check-in process */
  private setFields(): void {
    ['marketing', 'preferences', 'signature'].forEach((entry) => {
      const getField = this.field_for(entry);
      if (!getField) {
        return;
      }

      this.fields[entry] = getField;
      const field = this.fields[entry];
      if (!this.modelFor(field.id)) {
        this.data.incident.field_values.push(new FieldValue(field));
      }
      this.mappedFormFieldsValues[entry] = this.modelFor(field.id);
    });
  }

  modelFor(id) {
    return this.data.incident?.field_values?.find((field) => field.id === id);
  }

  ngOnDestroy(): void {
    this.cicoService.infoScreen = false;
    this.resReceivedNotSuccess = false;
    super.ngOnDestroy();
    this.cicoService.confirmNameSubj.next(ConfirmName.next);
  }
}
