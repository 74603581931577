import { Component, OnDestroy, OnInit } from '@angular/core';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-module-poi-map',
  templateUrl: './map.component.html',
})
export class PoiMapComponent implements OnInit {
  module: Module;
  business: Business;
  entries: any[] = [];

  constructor(
    public globals: Globals,
    private api: ApiService,
  ) {}

  ngOnInit() {
    this.globals.getModule('poi').then((mod) => {
      this.module = mod;
      this.api.get('poi/map').subscribe((success: any) => {
        this.entries = success.poi_entries;
      });
    });
  }
}
