import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { SelectionService } from 'common/selection/selection.service';
import { GenericService } from 'models/generic_service';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { OfflineService } from 'services/offline.service';
import { ServiceService } from 'services/service.service';

@Component({
  selector: 'app-housekeeping',
  templateUrl: './housekeeping.component.html',
})
export class HousekeepingComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  service: GenericService;
  module: Module;
  info_text: string;
  select: string[];
  dates: string[] = [];
  rightSide = false;
  disabled: boolean;
  offline: boolean;
  reservation: any;

  confirm_name = 'send';
  error_text: string;

  @ViewChild('serviceForm', { static: true }) serviceForm: NgForm;

  constructor(
    public globals: Globals,
    private selectionService: SelectionService,
    private api: ApiService,
    private offlineService: OfflineService,
    private serviceService: ServiceService,
  ) {
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );
  }

  onSubmit() {
    this.serviceService.send_service(this, 'housekeeping');
  }

  ngOnInit() {
    this.createForm();

    this.globals
      .getModule('housekeeping')
      .then((mod) => {
        this.module = mod;
        this.disabled = this.module.service_disabled() || this.shortReservation();
        if (this.disabled && !this.shortReservation()) {
          this.serviceService.openTimetable();
        }
        this.info_text = this.module.info;
        this.rightSide = this.module.medias && this.module.medias.length > 0;
        this.dates = this.module.settings.dates;
        this.service.date = this.module.settings.request_date;
      })
      .catch(() => {});

    this.selectionService
      .getSelection('housekeeping')
      .then((data) => {
        this.select = data;
      })
      .catch(() => {});
  }

  shortReservation(): boolean {
    return this.module.settings.reservation && !this.module.settings.dates?.length;
  }

  createForm() {
    this.service = this.serviceService.newService();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
