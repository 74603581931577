<div class="loader-container" [class.main]="!small" [class.included]="included">
  <div class="spinner-wrapper">
    <svg
      class="spinner"
      xmlns="http://www.w3.org/2000/svg"
      width="177"
      height="177"
      viewBox="0 0 177 177"
      fill="none"
    >
      <path
        d="M177 88.5C177 137.377 137.377 177 88.5 177C39.6228 177 0 137.377 0 88.5C0 39.6228 39.6228 0 88.5 0C137.377 0 177 39.6228 177 88.5ZM17.7 88.5C17.7 127.602 49.3982 159.3 88.5 159.3C127.602 159.3 159.3 127.602 159.3 88.5C159.3 49.3982 127.602 17.7 88.5 17.7C49.3982 17.7 17.7 49.3982 17.7 88.5Z"
        fill="url(#gradient_angular)"
      />
      <defs>
        <linearGradient id="gradient_angular" x1="0%" y1="50%" x2="100%" y2="50%">
          <stop class="spinner-main-color" offset="0%" />
          <stop offset="100%" stop-color="#BBCDD0" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
    <img
      *ngIf="!noLogo && globals.business"
      class="logo"
      [src]="globals.business?.icon?.size_72"
      alt="Logo"
    />
  </div>
  <h1 class="loading-re main-text" *ngIf="headline?.length">{{ globals.translate(headline) }}</h1>
  <div class="loading-re main-text" *ngIf="!headline?.length && text?.length">
    {{ globals.translate(text) }}
  </div>
  <div class="loading-description" *ngIf="headline?.length && text?.length">
    {{ globals.translate(text) }}
  </div>
</div>
