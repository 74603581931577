import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { LengthValidatorModule } from 'validators/length.validator.module';
import { DatePickerModule } from '../datepicker/datepicker.module';
import { FormPhoneFieldModule } from '../phone/phone_field.module';
import { FormSelectModule } from '../select/select.module';
import { FormFieldsRoutingModule } from './fields-routing.module';
import { FormFieldsComponent } from './fields.component';
import { RequiredCheckboxValidatorModule } from 'validators/required-checkbox.validator.module';
import { ValueAccessorModule } from 'app/value-accessors/value-accessor-module';
import { ValidationMessagePipeModule } from 'pipes/validation-message.pipe.module';
import { DateValidatorModule } from 'validators/date.validator.module';

@NgModule({
  imports: [
    CommonModule,
    FormFieldsRoutingModule,
    DatePickerModule,
    TranslateModule,
    FormsModule,
    FormSelectModule,
    FormPhoneFieldModule,
    EmailValidatorModule,
    ValidationMessagePipeModule,
    LengthValidatorModule,
    ValueAccessorModule,
    RequiredCheckboxValidatorModule,
    DateValidatorModule,
  ],
  exports: [FormFieldsComponent],
  declarations: [FormFieldsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormFieldsModule {}
