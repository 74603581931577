import { Subject, Subscription } from 'rxjs';
/**
 * Interface defining the structure of an aggregate event.
 */
export interface IAggregateEvent<T> {
  /**
   * Publishes the payload to all subscribers.
   * @param payload The data to be published.
   */
  publish(payload: T): void;

  /**
   * Subscribes a callback function to receive payloads.
   * Note: Subscribing components should handle the subscription disposal in ngOnDestroy method.
   * @param callback The function to be called when a payload is published.
   * @returns A subscription object that can be used to unsubscribe.
   */
  subscribe(callback: (payload: T) => void): Subscription;
}

/**
 * Class representing an aggregate event item.
 * Contains a key and an associated event.
 */
export class AggregateEventItem {
  key: string;
  event: IAggregateEvent<any>;
}

/**
 * Class implementing the IAggregateEvent interface.
 * Manages the publishing and subscription of events.
 */
export class AggregateEvent<T> implements IAggregateEvent<T> {
  // Subject to handle event broadcasting
  private subject = new Subject<any>();

  constructor() {}

  /**
   * Publishes the payload to all subscribers.
   * @param payload The data to be published.
   */
  public publish(payload: T): void {
    // Notify all subscribers with the new payload.
    this.subject.next(payload);
  }

  /**
   * Subscribes a callback function to receive payloads.
   * @param callback The function to be called when a payload is published.
   * @returns A subscription object that can be used to unsubscribe.
   */
  public subscribe(callback: (payload: T) => void): Subscription {
    // Create a subscription to the subject with the provided callback.
    const subscription = this.subject.asObservable().subscribe(callback);
    return subscription;
  }
}
