import { Injectable } from '@angular/core';
import { ApiService } from 'api_service';
import { Guest } from 'models/guest';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, filter, take } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  guest: Guest;
  value: number;

  public ready = new BehaviorSubject<boolean>(false);
  open_modal = this.ready.asObservable();

  constructor(
    private api: ApiService,
    private guestService: GuestService,
  ) {}

  getQuestions(): Observable<Object> {
    return this.api.get('feedback/entries');
  }

  // Smart Feedback
  initSubscriptions() {
    return new Promise((resolve, reject) => {
      this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
        if (guest.smart_feedback) {
          this.value = Number(guest.smart_feedback.trigger_value);
          if (guest.smart_feedback.trigger === 'time') {
            this.smart_feedback_timer();
          } else if (guest.smart_feedback.trigger === 'clicks') {
            this.smart_feedback_steps();
          }
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  smart_feedback_timer() {
    // wait for defined time, then show modalService
    of(true)
      .pipe(delay(this.value * 1000))
      .subscribe(() => {
        this.ready.next(true);
      });
  }

  smart_feedback_steps() {
    // wait until guest has reached enough visits, then show modalService
    this.guestService.steps
      .pipe(
        filter((steps: number) => steps === this.value),
        take(1),
      )
      .subscribe(() => {
        this.ready.next(true);
      });
  }
}
