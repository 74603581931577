import { ComponentFactoryResolver, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'base';
import { DomModal } from 'models/dom_modal';
import { File } from 'models/file';
import { interval, of, Subject, Subscription } from 'rxjs';
import { delay, skipWhile, take } from 'rxjs/operators';
import { A2hsComponent } from '../a2hs/a2hs.component';

@Injectable({ providedIn: 'root' })
export class ModalService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  modalStack: DomModal[] = [];
  notificationState: Subscription;
  body: DomModal;
  update: boolean;
  showHint: boolean;
  closeable: boolean;
  title: string;
  message: string;
  addition: string;
  params: any;
  component: any;
  files: File[] = [];
  title_newsletter: string;
  newsletter: boolean;
  description_newsletter: string;
  a2hs = false;
  small: boolean;

  isShowing = false;
  modal = new Subject<boolean>();

  constructor(
    private router: Router,
    private globals: Globals,
    private resolver: ComponentFactoryResolver,
  ) {
    this.subscriptions.add(
      this.modal.subscribe((_isOpen) => {
        this.modalManager();
      }),
    );
  }

  getModal() {
    return this.modal;
  }

  open(body: DomModal, openInstant: boolean = false, force = false): void {
    if (force) {
      this.openNow(openInstant, body);
    } else {
      this.subscriptions.add(
        interval(2500)
          .pipe(
            skipWhile((_value, _index) => !force && this.globals.isPmsModule()),
            take(1),
          )
          .subscribe(() => {
            this.openNow(openInstant, body);
          }),
      );
    }
  }

  openNow(openInstant: boolean, body: DomModal) {
    if (this.modalStack.length === 0 && !this.isShowing) {
      this._open(body);
    } else {
      if (openInstant) {
        this._open(body);
      } else if (this.body.title !== body.title) {
        this.modalStack.push(body);
      }
    }
  }

  _open(body: DomModal, small?: boolean): void {
    this.small = small;
    if (body) {
      this.a2hs = body.component === A2hsComponent;
      this.body = body;
      if (!this.body) {
        return;
      }

      this.notifications();

      this.closeable = body.closeable;
      this.title = body.title;
      this.message = body.description;
      this.addition = body.addition;
      this.files = body.attachments;
      this.update = body.update;
      this.small = body.small;
      this.params = body.params;

      this.setNewsletter();
      if (body.component) {
        this.component = this.resolver.resolveComponentFactory(body.component);
      }

      this.isShowing = true;
      this.modal.next(true);

      if (body.redirect) {
        this.router.navigate(['/g/', this.globals.getCode()], { replaceUrl: true });
      }
    }
  }

  setTitle(title) {
    this.title = title;
  }

  modalManager(): void {
    const self = this;
    if (!this.isShowing && this.modalStack.length > 0) {
      of(true)
        .pipe(delay(5000))
        .subscribe(() => {
          if (!self.isShowing) {
            self._open(this.modalStack.shift());
          }
        });
    }
  }

  notifications() {
    if (this.body?.notificationState) {
      this.notificationState = this.body.notificationState.subscribe((state) => {
        if (state) {
          this.showHint = !this.globals.validPushStates().includes(state);
        }
      });
    } else {
      if (this.notificationState) {
        this.notificationState.unsubscribe();
      }
      this.notificationState = null;
    }
  }

  setNewsletter() {
    if (!!this.body.newsletter && !!this.body.newsletter.show && !!this.body.newsletter.title) {
      this.title_newsletter = this.body.newsletter.title;
      this.description_newsletter = this.body.newsletter.description;
      this.newsletter = this.body.newsletter.show;
    } else {
      this.newsletter = false;
    }
  }

  close() {
    this.isShowing = false;
    this.modal.next(false);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.notificationState?.unsubscribe();
  }
}
