<ng-container *ngIf="widget?.length">
  <div class="page-headline" *ngIf="origin">
    <h2 translate>smart_weather.suggestion</h2>
  </div>
  <div translate class="title" *ngIf="!origin">smart_weather.suggestion</div>
  <div class="icon-menu">
    <ul class="icon-nav" *ngIf="widget.length > 1">
      <li
        class="smart-item"
        *ngFor="let group of widget"
        [class.active]="type == group.type"
        (click)="type = group.type"
      >
        <i class="fa {{ group.icon }}"></i>
      </li>
    </ul>

    <div
      class="menu condensed"
      *ngFor="let group of widget"
      [class.active]="type == group.type"
      [ngSwitch]="type"
    >
      <ng-template [ngSwitchCase]="group.type">
        <a
          *ngFor="let entry of group.entries"
          [routerLink]="['/g', globals.getCode(), entry.type, entry.id]"
        >
          <div>{{ entry.name }}</div>
          <div><i class="fas fa-angle-right icon-next"></i></div>
        </a>
        <a
          [routerLink]="['/g', globals.getCode(), group.type, 'smart']"
          class="more"
          *ngIf="group.more"
        >
          <div translate>misc.more</div>
          <div><i class="fas fa-angle-right icon-next"></i></div>
        </a>
      </ng-template>
    </div>
  </div>
</ng-container>
