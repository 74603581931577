import { AnimationBuilder } from '@angular/animations';
import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { Globals } from 'base';
import { CiCoFooterBaseDirective } from '../../base/cico-footer-base-directive';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-pms-simple-footer',
  templateUrl: './simple-footer.component.html',
  styleUrls: ['./simple-footer.component.scss'],
})
export class PmsSimpleFooterComponent extends CiCoFooterBaseDirective implements OnInit, OnDestroy {
  @Input() showPrivacyPolicy = false;
  @Input() showBack: boolean;
  @Input() showNext = false;
  @Input() disableNext: boolean;
  @Input() disableBack: boolean;
  @Input() backBtnText = 'misc.back';
  @Input() nextBtnId: string;
  @Input() nextBtnText = 'misc.next';

  @Output() nextClicked = new EventEmitter<void>();
  @Output() prevClicked = new EventEmitter<void>();

  constructor(
    protected globals: Globals,
    protected cicoService: PmsCiCoService,
    protected animationBuilder: AnimationBuilder,
  ) {
    super(globals, cicoService, animationBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onNextClick() {
    this.nextClicked.emit();
  }

  onPrevClick() {
    this.prevClicked.emit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
