import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PmsKioskTimerRoutingModule } from './timer-routing.module';
import { PmsKioskTimerComponent } from './timer.component';

@NgModule({
  imports: [CommonModule, PmsKioskTimerRoutingModule, TranslateModule],
  exports: [PmsKioskTimerComponent],
  declarations: [PmsKioskTimerComponent],
})
export class PmsKioskTimerModule {}
