import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LegacyDateValidator } from './legacy-date.validator';

@NgModule({
  imports: [CommonModule],
  exports: [LegacyDateValidator],
  declarations: [LegacyDateValidator],
})
export class LegacyDateValidatorModule {}
