import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GenericData } from 'models/pms/generic_data';
import { PmsCiCoService } from 'cico_service';
import { Globals } from 'base';
import { PmsService } from 'pms_service';
import { VerificationMethod } from 'common/icon-box-group/select-item.interface';
import { fadeInAnimation } from 'app/route-animations';
import { Field } from 'models/field';
import { PmsGuest } from 'models/pms/pms_guest';

@Component({
  selector: 'app-pms-ci-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  animations: [fadeInAnimation],
})
export class PmsAuthenticationComponent implements OnInit, OnDestroy {
  authId: string;
  authUrl: string;
  authFailed: boolean;
  authRequired: string;
  pending: boolean;
  timer: any;
  settings: any;
  verificationOptions: any;
  nationalityField: Field;

  ui_messages: any;

  socketInfos = new Subscription();
  subscriptions: Subscription = new Subscription();

  @Input() data: GenericData;
  @Input() guest: PmsGuest;
  @Output() dismiss = new EventEmitter<void>();
  @Output() done = new EventEmitter<boolean>();

  VERIFICATIONOPTIONS: { text: string; icon: string; type: string; method: VerificationMethod }[] =
    [
      {
        text: 'service.check_in.authentication.idnow',
        icon: 'passport',
        type: 'svg',
        method: VerificationMethod.Passport,
      },
      {
        text: 'service.check_in.authentication.manual',
        icon: 'keyboard',
        type: 'text',
        method: VerificationMethod.Manual,
      },
    ];

  constructor(
    private cicoService: PmsCiCoService,
    private pmsService: PmsService,
    private globals: Globals,
  ) {}

  ngOnInit(): void {
    this.cicoService.setShowFooter(false);
    this.nationalityField = this.cicoService.field_for('primary_guest')?.subField('nationality');
    this.settings = this.cicoService
      .field_for('primary_guest')
      ?.subField('passport')
      ?.subField('passport_image')?.settings;

    this.ui_messages = this.cicoService.ui_messages();

    if (!this.guest.nationality) {
      this.verificationOptions = [];
    } else {
      this.checkAuthMethods();
    }
  }

  selectNationality(event: any) {
    if (event?.detail?.selectedValue) {
      this.guest.nationality = event.detail.selectedValue.value;
      this.checkAuthMethods();
      this.cicoService.setPassportVisa();
    }
  }

  checkAuthMethods() {
    const setting = this.domestic_guest()
      ? this.setting_for('validation_domestic')
      : this.setting_for('validation_foreigners');
    this.verificationOptions = this.VERIFICATIONOPTIONS.filter((option) => {
      return (
        (setting !== 'none' || option.method !== VerificationMethod.Passport) &&
        (setting !== 'required' || option.method !== VerificationMethod.Manual)
      );
    });
  }

  domestic_guest(): boolean {
    return this.data?.incident?.reservation?.primary_guest?.domestic(this.data?.business);
  }

  setting_for(name) {
    return this.settings?.find((f) => f.identifier === name)?.value;
  }

  manual() {
    this.guest.address.manual = true;
    this.dismiss.emit();
    this.cicoService.toggleInactivity(true);
  }

  startIdnow() {
    this.pmsService.getAuthUrl(this.data.incident.reservation.uuid).subscribe((success: any) => {
      this.authId = success.id;
      this.authUrl = success.url;
      this.cicoService.disableNextButton(true);
      this.cicoService.toggleInactivity(false);
      this.startTimer();

      this.socketInfos = this.subscriptions.add(
        this.pmsService.passportAuth
          .pipe(
            filter(Boolean),
            filter((result: any) => result.id === this.authId),
          )
          .subscribe((result: any) => {
            this.authUrl = undefined;
            switch (<string>result.severity) {
              case 'success':
                this.fillData(result);
                this.pending = false;
                this.authFailed = false;
                this.data.incident.reservation.authenticated = true;
                this.globals.alert(
                  'success',
                  this.cicoService.ui_messages()?.auth_success?.content,
                );
                this.cicoService.disableNextButton(false);
                this.done.next(true);
                break;
              case 'info':
                this.authFailed = false;
                this.pending = true;
                this.resetTimer();
                break;
              default:
                this.error();
            }
            if (!this.pending) {
              this.stopTimer();
              this.cicoService.toggleInactivity(true);
            }
          }),
      );
    });
  }

  error() {
    this.authFailed = true;
    this.pending = false;
    this.authUrl = undefined;
    this.socketInfos?.unsubscribe();
    this.cicoService.toggleInactivity(true);
  }

  fillData(result) {
    [
      'first_name',
      'last_name',
      'nationality',
      'date_of_birth',
      'passport_id',
      'passport_date',
      'passport_expire',
      'passport_authority',
      'doc_type',
    ].forEach((colum) => {
      if (result[colum]?.length) {
        this.data.incident.reservation.primary_guest[colum] = result[colum];
      }
    });
    ['address', 'zipcode', 'city', 'country'].forEach((colum) => {
      if (result.address[colum]?.length) {
        this.data.incident.reservation.primary_guest.address[colum] = result.address[colum];
      }
    });
  }

  retryAuth() {
    this.socketInfos?.unsubscribe();
    this.authFailed = false;
    this.stopTimer();
    this.startIdnow();
  }

  // Timer - Wait 5 minutes to finish process

  startTimer() {
    if (this.globals.kiosk()) {
      this.timer = interval(1000).subscribe((val) => {
        if (val === 300) {
          this.error();
          this.stopTimer();
        }
      });
      this.subscriptions.add(this.timer);
    }
  }

  stopTimer() {
    this.timer?.unsubscribe();
  }

  resetTimer() {
    this.stopTimer();
    this.startTimer();
  }

  idfMethod(method: VerificationMethod) {
    this.data.authChosen = true;
    if (method === VerificationMethod.Passport) {
      this.startIdnow();
    } else {
      this.manual();
    }
  }

  ngOnDestroy() {
    this.cicoService.setShowFooter(true);
    this.subscriptions.unsubscribe();
    this.socketInfos?.unsubscribe();
  }
}
