import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OfflineService {
  offlineSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!navigator.onLine);
  offline: Observable<boolean> = this.offlineSubj.asObservable();

  constructor() {
    window.addEventListener('online', () => {
      this.offlineSubj.next(false);
    });
    window.addEventListener('offline', () => {
      this.offlineSubj.next(true);
    });
  }
}
