import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-widget-call',
  templateUrl: './call.component.html',
})
export class WidgetCallComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;

  constructor(private businessService: BusinessService) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
