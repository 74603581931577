export enum VerificationMethod {
  All,
  Passport,
  Ausweisapp,
  Manual,
}

export interface SelectItem {
  text: string;
  countryShortCode?: string;
  countryCode?: string;
  verificationMethods?: Array<VerificationMethod>;
}
