import { Component, OnDestroy, OnInit } from '@angular/core';
import { Guest } from 'models/guest';
import { Subscription } from 'rxjs';
import { GuestService } from 'services/guest.service';
import { Globals } from 'base';

@Component({
  selector: 'app-widget-qr-code',
  templateUrl: './qr_code.component.html',
})
export class WidgetQrCodeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  link: string;
  shortLink: string;

  constructor(
    private guestService: GuestService,
    private globals: Globals,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.guestService.currentGuest.subscribe((guest: Guest) => {
        if (guest) {
          this.link = guest.place.remote_url;
          this.shortLink = guest.place.remote_short_url;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
