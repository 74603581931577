import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CameraModule } from 'modules/pms/shared/camera/camera.module';
import { PassportPhotoComponent } from './passport-photo.component';

@NgModule({
  declarations: [PassportPhotoComponent],
  imports: [CommonModule, TranslateModule, CameraModule],
  exports: [PassportPhotoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PassportPhotoModule {}
