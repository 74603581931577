import { Component, Input } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { OverlayAction } from 'pms_enums';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input() legal: any;
  @Input() kind: string;
  link: any;

  constructor(
    public globals: Globals,
    private cicoService: PmsCiCoService,
  ) {}

  openPdf(data) {
    this.link = data.file_url;
  }

  closePdf() {
    document.getElementById('container')?.classList?.remove('frame');
    window.scrollTo(0, 0);
  }

  closeOverlay() {
    this.cicoService.closeOverlay(OverlayAction.close);
  }

  mouseEnter() {
    document.body.classList.add('scroll_lock');
  }

  mouseLeave() {
    document.body.classList.remove('scroll_lock');
  }
}
