import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { fadeInAnimation } from 'app/route-animations';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsService } from 'pms_service';
import { interval, of, Subscription } from 'rxjs';
import { delay, take, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  animations: [fadeInAnimation],
})
export class ScannerComponent implements AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  cameraChanging: boolean;

  @ViewChild('barcode') barcode: ElementRef;

  constructor(
    private pmsService: PmsService,
    private cicoService: PmsCiCoService,
    private globals: Globals,
  ) {
    this.facingMode = this.globals.place?.facing_mode || 'environment';

    this.browser = <any>navigator;
    this.browser.getUserMedia =
      this.browser.getUserMedia ||
      this.browser.webkitGetUserMedia ||
      this.browser.mozGetUserMedia ||
      this.browser.msGetUserMedia;

    /** Monkey Patch to avoid ExpressionChangedAfterItHasBeenCheckedError */
    ZXingScannerComponent.prototype.reset = function () {
      this._reset();
      setTimeout(() => {
        this.deviceChange.emit(null);
      });
    };
  }

  @Input() imageUpload = false;
  @Input() kioskScanner = false;
  @Input() data: string;
  @Input() mod: string;
  @Input() lostKey: boolean;
  @Output() success = new EventEmitter<any>();
  @Output() abort = new EventEmitter();

  hideVideo: boolean;
  hideCapture: boolean;
  cameraSet: boolean;
  private deviceIdx: number;
  private savedDeviceIdx: number;

  browser: any;
  width: null;
  height: null;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  facingMode: string;

  video: HTMLVideoElement;

  private static canvas() {
    return <any>document.querySelector('app-scanner canvas');
  }

  private static video() {
    return <any>document.querySelector('app-scanner video');
  }

  async initCapture(change = false) {
    this.onStarted();
    this.hideVideo = false;
    this.hideCapture = true;
    this.video = ScannerComponent.video();

    const constraints = {
      video: {
        height: { exact: this.height || this.video.offsetHeight },
        width: { exact: this.width || this.video.offsetWidth },
        facingMode: { ideal: this.facingMode },
      },
      audio: false,
    };

    this.browser.mediaDevices.enumerateDevices().then((result) => {
      this.availableDevices = result.filter((x) => x.kind === 'videoinput');
    });

    await this.browser.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.video.srcObject = stream;
        this.video.play();
      })
      .catch(() => {
        if (!change) {
          this.noCamera();
        } else {
          this.changeError();
        }
      });
  }

  onScanSuccess(data) {
    const payload = { data: data, mod: this.mod };
    this.pmsService.getReservationByQr(payload).subscribe(
      (resData: any) => {
        this.success.emit({
          errorText: null,
          reservationData: resData,
        });
      },
      (error) => {
        this.success.emit({ errorText: error });
      },
    );
  }

  barcodeScan(event) {
    if (event.key === 'Enter') {
      this.onScanSuccess(this.barcode.nativeElement.value);
      this.barcode.nativeElement.value = '';
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;

    const lookup =
      this.facingMode === 'user' ? ['front', 'vorder', 'user'] : ['back', 'rück', 'environment'];
    this.deviceIdx = this.availableDevices.indexOf(
      this.availableDevices.find((d) =>
        lookup.find((look) => d.label.toLowerCase().includes(look)),
      ),
    );
    if (this.deviceIdx === -1 && this.availableDevices.length > 0) {
      this.deviceIdx = 0;
    }
    this.savedDeviceIdx = this.deviceIdx;
    this.currentDevice = devices[this.deviceIdx];
    this.onStarted();
  }

  onStarted() {
    this.cicoService.toggleInactivity(true);
    this.globals.clearAlert();
    const self = this;

    this.subscriptions.add(
      interval(50)
        .pipe(
          takeWhile(() => ScannerComponent.video()),
          take(1),
        )
        .subscribe(() => {
          const video = ScannerComponent.video();
          video.addEventListener(
            'playing',
            () => {
              self.resize(video);
            },
            false,
          );
        }),
    );
  }

  switchCamera() {
    this.cameraChanging = true;
    if (this.imageUpload) {
      const current = this.facingMode;
      this.facingMode = current === 'environment' ? 'user' : 'environment';
      this.video.pause();
      this.initCapture(true).finally(() => {
        this.cameraChanging = false;
      });
    } else {
      this.deviceIdx = ++this.deviceIdx < this.availableDevices.length ? this.deviceIdx : 0;
      this.currentDevice = this.availableDevices[this.deviceIdx];
      of(true)
        .pipe(delay(1200))
        .subscribe(() => {
          this.cameraChanging = false;
        });
    }
  }

  useDefinedCamera() {
    this.subscriptions.add(
      of(true)
        .pipe(delay(400))
        .subscribe(() => {
          if (this.availableDevices) {
            const correct = this.availableDevices[this.savedDeviceIdx];
            if (correct && this.currentDevice !== correct) {
              this.currentDevice = this.availableDevices[this.savedDeviceIdx];
            }
            this.cameraSet = true;
          }
        }),
    );
  }

  scanAbort() {
    this.abort.emit();
  }

  noCamera() {
    this.globals.alert(
      'error',
      this.globals.translate('service.check_in.terminal.webcam.problem'),
      this.globals.translate('misc.error'),
    );
    this.subscriptions.add(
      of(true)
        .pipe(delay(5000))
        .subscribe(() => {
          this.globals.clearAlert();
          this.scanAbort();
        }),
    );
  }

  changeError() {
    this.globals.alert(
      'error',
      this.globals.translate('service.check_in.terminal.webcam.changeProblem'),
      this.globals.translate('misc.error'),
    );
    this.switchCamera();
    this.subscriptions.add(
      of(true)
        .pipe(delay(5000))
        .subscribe(() => {
          this.globals.clearAlert();
        }),
    );
  }

  ngAfterViewInit(): void {
    if (this.imageUpload) {
      setTimeout(() => {
        this.initCapture();
      });
    }
    if (this.kioskScanner) {
      this.barcode.nativeElement.focus();
    }
  }

  resize(video = ScannerComponent.video()) {
    this.subscriptions.add(
      interval(25)
        .pipe(
          takeWhile(() => document.getElementById('marker') !== null),
          take(1),
        )
        .subscribe(() => {
          const videoEl = <HTMLVideoElement>video;
          const marker = document.getElementById('marker');

          const width = (videoEl.clientWidth || videoEl.offsetWidth) - 32;
          marker.style.marginLeft = width / -2 + 'px';
          marker.style.width = width + 'px';
          marker.style.height = (videoEl.clientHeight || videoEl.offsetHeight) - 32 + 'px';
        }),
    );
  }

  ngOnDestroy(): void {
    if (ScannerComponent.video()?.srcObject?.getTracks()) {
      ScannerComponent.video().srcObject.getTracks()[0].stop();
    }
    this.subscriptions.unsubscribe();
  }
}
