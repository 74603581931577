import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';
import { PortfolioModuleService } from './portfolio.service';

@Component({
  selector: 'app-portfolio-module',
  templateUrl: './portfolio.component.html',
})
export class PortfolioModuleComponent implements OnInit {
  module: Module;
  cart: string[];

  constructor(
    private menuService: PortfolioModuleService,
    private globals: Globals,
  ) {}

  ngOnInit() {
    this.globals
      .getModule('portfolio', false)
      .then((mod) => {
        this.module = mod;
      })
      .catch(() => {});
  }
}
