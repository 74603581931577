import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { GenericService } from 'models/generic_service';
import { Module } from 'models/module';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { OfflineService } from 'services/offline.service';
import { ServiceService } from 'services/service.service';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
})
export class AlarmComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  service: GenericService;
  module: Module;
  offline: boolean;
  maxDate: string;

  rightSide = false;
  disabled = false;

  confirm_name = 'send';
  error_text: string;

  @ViewChild('serviceForm', { static: true }) serviceForm: NgForm;

  constructor(
    public globals: Globals,
    private api: ApiService,
    private offlineService: OfflineService,
    private serviceService: ServiceService,
  ) {
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );
  }

  onSubmit() {
    this.serviceService.send_service(this, 'alarm');
  }

  ngOnInit() {
    this.globals
      .getModule('alarm')
      .then((mod) => {
        this.module = mod;
        this.maxDate = this.module.settings.max_date;
        this.rightSide = this.module.medias && this.module.medias.length > 0;
        this.disabled = this.module.service_disabled();
        if (this.disabled) {
          this.serviceService.openTimetable();
        }
      })
      .catch(() => {});
    this.createForm();
  }

  createForm() {
    this.service = this.serviceService.newService();
    this.service.date = moment().add(1, 'days').format('YYYY-MM-DD');
    this.service.time = moment().set({ hour: 8, minutes: 0 }).format('HH:mm');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
