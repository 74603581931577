import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationCardComponent } from './reservation-card.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [ReservationCardComponent],
  exports: [ReservationCardComponent],
  providers: [],
})
export class ReservationCardModule {}
