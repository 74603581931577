import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-info',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  tooltip: string;
  left: number;
  top: number;

  constructor() {}

  ngOnInit() {}
}
