import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { PmsBaseDirective } from '../../base/base.directive';
import { PmsInvoiceAddressFormModule } from '../invoice_address/form/form.module';
import { PmsPaymentModule } from '../payment/payment/payment.module';
import { PmsFolioModule } from './folio/folio.module';
import { PmsFoliosRoutingModule } from './folios-routing.module';
import { PmsFoliosComponent } from './folios.component';

@NgModule({
  imports: [
    CommonModule,
    PmsFoliosRoutingModule,
    FormsModule,
    TranslateModule,
    PmsFolioModule,
    PmsInvoiceAddressFormModule,
    PmsPaymentModule,
    LoaderModule,
  ],
  exports: [PmsFoliosComponent],
  declarations: [PmsFoliosComponent],
  providers: [PmsBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsFoliosModule {}
