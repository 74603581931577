<div class="form-box">
  <label translate>entries.quantity</label>
  <div class="quantity-selector clearfix">
    <div class="main-bg" (click)="qty('reduce')"><i class="fas fa-minus"></i></div>
    <input
      name="quantity"
      value="{{ value }}"
      readonly="readonly"
      type="text"
      [class.removable]="removable"
    />
    <div class="main-bg" (click)="qty('add')"><i class="fas fa-plus"></i></div>
    <div class="main-bg" (click)="remove()" *ngIf="removable"><i class="fas fa-trash"></i></div>
  </div>
</div>
