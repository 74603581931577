import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { A2hsRoutingModule } from './a2hs-routing.module';
import { A2hsComponent } from './a2hs.component';

@NgModule({
  imports: [CommonModule, A2hsRoutingModule, TranslateModule],
  exports: [A2hsComponent],
  declarations: [A2hsComponent],
  providers: [CookieService, TranslateService],
})
export class A2hsModule {}
