import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelRoutingModule } from './label-routing.module';
import { LabelComponent } from './label.component';

@NgModule({
  imports: [CommonModule, LabelRoutingModule],
  exports: [LabelComponent],
  declarations: [LabelComponent],
})
export class LabelModule {}
