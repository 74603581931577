import { Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { OfflineService } from 'services/offline.service';
import { TeaserService } from 'services/websocket/teaser.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  message: string;
  network: string;
  offline: boolean;
  news: boolean;
  welcomeMessage: string;

  constructor(
    private businessService: BusinessService,
    public guestService: GuestService,
    public globals: Globals,
    private offlineService: OfflineService,
    private teaserService: TeaserService,
  ) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.news = this.business.style.news;
      this.globals.setModule('home');
      this.globals._module = new Module({ type: 'home' });
      this.globals.taskSubj.next(false);

      this.subscriptions.add(
        this.teaserService.teaser.pipe(filter(Boolean)).subscribe((teaser: any) => {
          this.welcomeMessage = teaser.message;
        }),
      );
    });
    this.subscriptions.add(
      this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
        this.network = guest.place.network;
      }),
    );
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );
    document.getElementById('container').classList.remove('cico', 'wizard', 'task', 'overlay');
    document.getElementById('container').classList.add('overviews');
  }

  ngOnDestroy() {
    document.getElementById('container')?.classList?.remove('overviews');
    this.subscriptions.unsubscribe();
  }
}
