import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class ShopModuleService {
  constructor(private api: ApiService) {}

  getCategories(id: string): Observable<Object> {
    return this.api.get('shop/categories/' + id);
  }

  getAll(): Observable<Object> {
    return this.api.get('shop/all');
  }
}
