<ng-container *ngIf="link">
  <div class="title" translate>misc.qr_cta</div>
  <qrcode
    [qrdata]="link"
    [width]="130"
    [errorCorrectionLevel]="'H'"
    [colorDark]="'#555'"
    [margin]="0.5"
  ></qrcode>
  <a href="{{ shortLink }}" *ngIf="shortLink?.length">{{ shortLink }}</a>
</ng-container>
