import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { EventListCalenderItemRoutingModule } from './calender_item-routing.module';
import { EventListCalenderItemComponent } from './calendar_item.component';

@NgModule({
  imports: [CommonModule, EventListCalenderItemRoutingModule, SharedSwiperModule, TranslateModule],
  exports: [EventListCalenderItemComponent],
  declarations: [EventListCalenderItemComponent],
})
export class EventListCalenderItemModule {}
