<div class="header-container main-shadow">
  <div
    class="action main-text"
    (click)="navigate(userActionType.cancel)"
    *ngIf="action == userActionType.cancel"
  >
    <i class="fas fa-xmark fa-xl" *ngIf="!firstPage"></i>
    <span *ngIf="!firstPage" class="action-text" translate>misc.cancel</span>
    <img *ngIf="firstPage" [src]="business.logo" [alt]="business.name" class="hotel-logo" />
  </div>

  <div
    class="action main-text"
    (click)="navigate(userActionType.back)"
    *ngIf="action == userActionType.back"
  >
    <i class="fas fa-chevron-left fa-xl" *ngIf="!firstPage"></i>
    <span *ngIf="!firstPage" class="action-text" translate>misc.back</span>
  </div>

  <div
    class="language-select"
    (clickOutside)="openLanguages = false"
    (click)="openLanguages = !openLanguages"
  >
    <label class="dropdown-toggle-label" *ngIf="languageChooser">
      <i class="fa-solid fa-globe fa-xl main-text" *ngIf="selectedLanguage == null"></i>
      <img class="flag" *ngIf="selectedLanguage" [src]="selectedLanguage?.media?.flag" alt="Logo" />
      <span>{{ selectedLanguage?.language }}</span>
    </label>
    <div class="dropdown">
      <ul class="dropdown-menu language-list-zindex thin-scroll main-shadow" *ngIf="openLanguages">
        <li class="dropdown-item" *ngFor="let lang of languages" (click)="changeLang(lang)">
          <a [ngClass]="{ selected: selectedLanguage && lang?.name == selectedLanguage?.name }">
            <img class="language-item-flag" [src]="lang.media.flag" [alt]="lang.name" />
            {{ lang.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
