import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { SelectionService } from 'common/selection/selection.service';
import { Business } from 'models/business';
import { GenericService } from 'models/generic_service';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { OfflineService } from 'services/offline.service';
import { ServiceService } from 'services/service.service';
import { WishModuleService } from '../wish.service';

@Component({
  selector: 'app-wish-direct',
  templateUrl: './direct.component.html',
})
export class WishDirectComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  service: GenericService;
  module: Module;
  business: Business;
  rightSide = false;
  disabled = false;
  required: boolean = true;
  offline: boolean;
  orderable: boolean;
  quantitable = false;
  entry: any;
  price = 0;
  description: any;
  total_price = 0;
  categories: any;
  select: any;
  has_price = false;

  medias: any;
  entryMedias: any;

  confirm_name = 'send';
  error_text: string;

  @ViewChild('serviceForm', { static: true }) serviceForm: NgForm;

  constructor(
    public globals: Globals,
    private selectionService: SelectionService,
    private api: ApiService,
    private businessService: BusinessService,
    private wishService: WishModuleService,
    private offlineService: OfflineService,
    private serviceService: ServiceService,
  ) {
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );
  }

  onSubmit() {
    this.serviceService.send_service(this, 'wish');
  }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
    this.globals
      .getModule('wish')
      .then((mod) => {
        this.module = mod;
        this.globals.init_send_cancel_btn();
        this.orderable = this.module.orderable;
        this.medias = this.module.medias;
        this.rightSide = this.medias.length > 0 || (this.orderable && this.has_price);
        this.disabled = this.module.service_disabled();
        if (this.disabled) {
          this.serviceService.openTimetable();
        }
      })
      .catch(() => {});

    this.selectionService
      .getSelection('wish')
      .then((data) => {
        this.select = data;
      })
      .catch(() => {});

    this.createForm();
  }

  createForm() {
    this.service = this.serviceService.newService();
  }

  optionChanged(id) {
    const value = Number(id);
    this.selectionService.optionChanged(value, this.select, this);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  set_quantity(qty) {
    this.service.quantity = qty;
    if (this.has_price) {
      this.set_total_price();
    }
  }

  set_total_price() {
    this.total_price = this.price * this.service.quantity;
    this.service.price = this.price;
  }
}
