<app-module-title [module]="module"></app-module-title>

<ng-container *ngIf="business">
  <div class="container">
    <div class="container-right">
      <app-swiper [items]="medias" *ngIf="medias?.length"></app-swiper>

      <div class="article-address">
        <h4 translate>misc.address</h4>
        <p [innerHTML]="business.getAddressBlock()"></p>
      </div>

      <div class="article-address">
        <h4 translate>poi.contact_details</h4>
        <ul class="icon-list">
          <li>
            <i class="far fa-envelope"></i>
            <a href="mailto:{{ business.contact.email }}">
              {{
                business.contact.email.length <= 20
                  ? business.contact.email
                  : ('misc.send_email' | translate)
              }}
            </a>
          </li>
          <li>
            <i class="fas fa-phone"></i>
            <a target="_blank" href="tel:{{ business.contact.phone }}">
              {{ business.contact.phone }}
            </a>
          </li>
          <li>
            <i class="fas fa-home"></i>
            <a
              [class.ext-link]="business.contact.url_target === '_blank'"
              translate
              (click)="globals.openUrl(business.contact.url, business.contact.url_target)"
            >
              misc.open_website
            </a>
          </li>
        </ul>
      </div>

      <div class="partner">
        <app-widget-advert></app-widget-advert>
      </div>
    </div>

    <div class="container-left">
      <div class="tiles clearfix">
        <a translate target="_blank" class="tile" href="tel:{{ business.contact.phone }}">
          <i class="fas fa-phone"></i>
          misc.call
        </a>
        <a translate class="tile" [href]="business.vcf">
          <i class="fas fa-save"></i>
          misc.save
        </a>
        <a translate class="tile ext-link" (click)="globals.openUrl(business.contact.route)">
          <i class="fas fa-route"></i>
          misc.route
        </a>
      </div>

      <ng-container *ngIf="show_cico">
        <h4 translate>misc.reception</h4>
        <ng-container
          [ngTemplateOutlet]="cico_table"
          [ngTemplateOutletContext]="{ entries: check_in, type: 'check_in' }"
          *ngIf="check_in"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="cico_table"
          [ngTemplateOutletContext]="{ entries: check_out, type: 'check_out' }"
          *ngIf="check_out"
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="showSocial()">
        <h4 translate>misc.social</h4>
        <div id="social" class="popup-social-container">
          <app-widget-social></app-widget-social>
        </div>
      </ng-container>

      <ng-container>
        <h4 translate>poi.map</h4>
        <app-map [latitude]="lat" [longitude]="lng"></app-map>
      </ng-container>
    </div>
  </div>

  <ng-template #cico_table let-entries="entries" let-type="type">
    <div class="opening_hours">
      <h5>{{ 'misc.' + type | translate }}</h5>
      <table class="timetable">
        <tbody>
          <tr *ngFor="let day of days" [class.main-bg]="entries[day].today">
            <td class="tableday">{{ entries[day].name }}</td>
            <td *ngIf="entries[day].disabled" translate>service_time.no_service</td>
            <td *ngIf="!entries[day].disabled">
              <span>{{ entries[day].from }}</span>
              -
              <span>{{ entries[day].till }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</ng-container>
