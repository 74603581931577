import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { WidgetQrCodeRoutingModule } from './qr_code-routing.module';
import { WidgetQrCodeComponent } from './qr_code.component';

@NgModule({
  imports: [CommonModule, WidgetQrCodeRoutingModule, QRCodeModule, TranslateModule],
  exports: [WidgetQrCodeComponent],
  declarations: [WidgetQrCodeComponent],
})
export class WidgetQrCodeModule {}
