const validationMsgGeneral = {
  required: 'validation.required',
};

const validationMsgEmail = {
  wrongFormat: 'validation.email_wrong_format',
  confirmEmail: 'validation.email_not_match',
};

const validationMsgDate = {
  adult: 'validation.adult_date_of_birth',
  kids: 'validation.kids_date_of_birth',
  future: 'validation.date_future',
  past: 'validation.date_past',
  dateInvalid: 'misc.invalid_date',
};

export const errorMessages: { [key: string]: string } = {
  ...validationMsgGeneral,
  ...validationMsgEmail,
  ...validationMsgDate,
};
