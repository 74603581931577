import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { errorMessages } from 'validators/errorMessages';

@Pipe({
  name: 'validationMessage',
  pure: true,
})
export class ValidationMessagePipe implements PipeTransform {
  transform(error: ValidationErrors): string {
    if (!error) {
      return '';
    }
    return errorMessages[Object.keys(error)?.[0]] || '';
  }
}
