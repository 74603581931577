<a
  class="side-button"
  [class.ext-link]="module.external_link"
  [routerLink]="['/g', globals.getCode(), module.type]"
  *ngIf="module"
  [ngClass]="{ disabled_state: offline }"
>
  <i class="fa {{ module.icon }}"></i>
  {{ module.title }}
</a>
