<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="module.medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>

  <div class="container-left">
    <form (ngSubmit)="onSubmit()" #serviceForm="ngForm">
      <!-- test code  -->
      {{ this.deviceService.getDeviceInfo().os }}
      {{ this.deviceService.getDeviceInfo().device }}
      {{ this.deviceService.getDeviceInfo().deviceType }}
      <!-- test code  -->

      <fieldset [disabled]="disabled">
        <div class="alert" [innerHtml]="error_text" *ngIf="error_text"></div>
        <div class="form-box">
          <label translate>misc.date</label>
          <app-datepicker
            [key]="'date'"
            [model]="service"
            [value]="globals.today()"
            [minDate]="globals.today()"
            [maxDate]="maxDate"
            [startView]="'month'"
            [required]="true"
          ></app-datepicker>
        </div>
        <div class="form-box">
          <label translate>misc.time</label>
          <input type="time" name="time" #time="ngModel" [(ngModel)]="service.time" required />
          <label translate class="error">validation.time</label>
        </div>

        <div class="form-box">
          <label translate>taxi.destination</label>
          <input
            type="text"
            name="destination"
            maxlength="254"
            #destination="ngModel"
            [(ngModel)]="service.destination"
            placeholder="{{ 'taxi.destination' | translate }}"
            id="place_search"
            min="0"
            max="254"
            [lengthValidator]="{ min: 0, max: 254 }"
          />
          <label class="error">{{ 'validation.maxLength' | translate: { length: 254 } }}</label>
        </div>

        <div class="form-box">
          <label translate>taxi.qty_persons</label>
          <input
            type="number"
            name="persons"
            #persons="ngModel"
            [(ngModel)]="service.persons"
            placeholder="{{ 'taxi.qty_persons' | translate }}"
          />
        </div>

        <div class="form-box" *ngIf="payments?.length && pay_method_field">
          <app-form-select
            [model]="service"
            [name]="'pay_method'"
            [label]="pay_method_field.name"
            [text]="pay_method_field.description"
            [options]="payments"
            [required]="pay_method_field.required"
          ></app-form-select>
        </div>

        <app-form-fields
          [form]="service"
          [module]="module"
          *ngIf="module?.fields?.length"
        ></app-form-fields>

        <ng-container *ngIf="module">
          <app-form-place
            [service]="service"
            [room_validation]="module.room_validation"
            *ngIf="globals.needPlace()"
          ></app-form-place>
        </ng-container>

        <div class="form-box">
          <label translate>misc.confirm_mail</label>
          <input
            type="email"
            name="email"
            #email="ngModel"
            [(ngModel)]="service.email"
            placeholder="{{ 'misc.mail' | translate }}"
            emailvalidator
            required
          />
          <label translate class="error">validation.email</label>
        </div>

        <div class="form-box" *ngIf="module?.field('phone')">
          <label [innerText]="module.field('phone').name"></label>
          <small
            [innerText]="module.field('phone').description"
            *ngIf="module.field('phone').description"
          ></small>
          <app-form-phone-field
            [model]="service"
            [key]="'phone'"
            [field]="module.field('phone')"
          ></app-form-phone-field>
        </div>

        <app-form-send-cancel
          [confirm]="confirmName"
          [status]="globals.send_cancel_disabled"
        ></app-form-send-cancel>
      </fieldset>
    </form>
  </div>
</div>
