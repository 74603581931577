import { Directive, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { File } from 'models/file';
import { Module } from 'models/module';
import { Target } from 'models/target';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { SuggestionsService } from 'widgets/suggestions/suggestions.service';

@Directive()
export class ServiceParentDirective implements OnInit, OnDestroy {
  mod: string;

  subscriptions: Subscription = new Subscription();
  module: Module;
  business: Business;

  title: string;
  description: any;
  medias: [];
  files: File[] = [];

  target: Target;

  category: any;
  categories: string[];
  entries: string[];

  searchText: string;
  rightSide: boolean;

  suggestions: boolean = false;

  constructor(
    public globals: Globals,
    protected route: ActivatedRoute,
    protected businessService: BusinessService,
    protected sanitizer: DomSanitizer,
    protected suggestionsService?: SuggestionsService,
  ) {}

  ngOnInit(service: any = null): any {
    return new Promise((resolve, reject) => {
      this.businessService.current_business
        .pipe(filter(Boolean))
        .subscribe((business: Business) => {
          this.business = business;
          this.globals
            .getModule(this.mod)
            .then((mod) => {
              this.module = mod;

              this.route.params.subscribe((params) => {
                const id = params['id'] === undefined ? '' : params['id'];

                this.subscriptions.add(
                  service.getCategories(id).subscribe(
                    (success: any) => {
                      this.defaultValues(success);
                      this.setSuggestions(id);
                      resolve(success);
                    },
                    () => {
                      reject();
                    },
                  ),
                );
              });
            })
            .catch(() => {
              reject();
            });
        });
    });
  }

  defaultValues(success: any) {
    this.category = success.category;
    this.categories = success.categories;
    this.entries = success.entries;
    this.target = success.target ? new Target(success.target) : null;

    this.title = this.category?.name || this.module.name;
    this.description = this.category?.description || this.module.description;
    this.description = this.description
      ? this.sanitizer.bypassSecurityTrustHtml(this.description)
      : null;
    this.medias = this.category?.medias || this.module.medias;
    this.files = this.category?.attachments || this.module.attachments;
    this.rightSide = this.medias && this.medias.length > 0;

    if (this.category?.gid) {
      this.globals.page = this.category.gid;
    }

    this.globals.target_navigate(this.categories, this.entries, this.mod, this.target);
  }

  setSuggestions(id) {
    if (id === '' && this.suggestionsService) {
      this.subscriptions.add(
        this.suggestionsService.getEntries(this.mod).subscribe((success: any) => {
          this.suggestions = success.entries.length > 0;
        }),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
