import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MapProtectionRoutingModule } from './map_protection-routing.module';
import { MapProtectionComponent } from './map_protection..component';

@NgModule({
  imports: [CommonModule, MapProtectionRoutingModule, TranslateModule],
  exports: [MapProtectionComponent],
  declarations: [MapProtectionComponent],
})
export class MapProtectionModule {}
