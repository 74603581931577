import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventRoutingModule } from './event-routing.module';
import { EventComponent } from './event.component';
import { EventListCalendarModule } from './list/calendar/calender.module';
import { EventListCompactModule } from './list/compact/compact.module';
import { EventListParentModule } from './list/parent/parent.module';

@NgModule({
  imports: [
    CommonModule,
    EventRoutingModule,
    EventListParentModule,
    EventListCompactModule,
    EventListCalendarModule,
  ],
  exports: [EventComponent],
  declarations: [EventComponent],
})
export class EventModule {}
