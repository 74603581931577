import { Contact } from './contact';
import { File } from './file';

export class Article {
  gid: string;
  title: string;
  content: string;
  advert: boolean;
  medias: [];
  contact: Contact;
  files: File[] = [];

  constructor(raw_json: any) {
    this.gid = raw_json?.gid;
    this.content = raw_json?.content;
    this.advert = raw_json?.advert;
    this.title = raw_json?.title;
    this.contact = raw_json?.contact;
    this.medias = raw_json?.medias;
    this.files = raw_json?.attachments;
  }
}
