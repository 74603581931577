import { Component, Input } from '@angular/core';
import { File } from 'models/file';
import { Globals } from 'base';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
})
export class AttachmentsComponent {
  @Input() files: any[] = [];
  @Input() blank = false;

  constructor(public globals: Globals) {}
}
