import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Link } from 'models/link';
import { of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { TeaserService } from 'services/websocket/teaser.service';
import { ExternalLinkService } from './external.service';

@Component({
  selector: 'app-external-link',
  templateUrl: './external.component.html',
})
export class ExternalLinkComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  link: Link;
  click_here: any;

  medias = [];

  constructor(
    public globals: Globals,
    private businessService: BusinessService,
    private sanitizer: DomSanitizer,
    private externalLinkService: ExternalLinkService,
    private teaserService: TeaserService,
  ) {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.teaserService.teaser.subscribe((teaser) => {
        if (teaser) {
          this.medias = teaser.medias;
        }
      }),
    );

    this.subscriptions.add(
      this.externalLinkService.linkChange.subscribe((link) => {
        this.link = link;
        if (this.link) {
          const content = this.globals.translate('external_link.open', this.link);
          this.click_here = this.sanitizer.bypassSecurityTrustHtml(content);
          this.subscriptions.add(
            of(true)
              .pipe(delay(2500))
              .subscribe(() => {
                window.open(this.link.url, '_blank');
              }),
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
