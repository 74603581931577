import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { WeatherService } from 'modules/weather/weather.service';

interface WeatherReport {
  date: string;
  date_short: string;
  date_name: string;
  temp: number;
  temp_fahrenheit: number;
  speed: number;
  humidity: number;
  description: string;
  media: { image: string };
}
@Component({
  selector: 'app-widget-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WidgetWeatherComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  weather: WeatherReport[];
  showFahrenheit: boolean;

  constructor(
    private weatherService: WeatherService,
    private business: BusinessService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.weatherService.showFahrenheit.subscribe((showFahrenheit) => {
        this.showFahrenheit = showFahrenheit;
      }),
    );
    this.subscriptions.add(
      this.business.currentLocale.subscribe(() => {
        this.weatherService.getWeather().subscribe(
          (success: any) => {
            this.weather = success.weather;
          },
          () => {},
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
