import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetA2hsRoutingModule } from './a2hs_widget-routing.module';
import { WidgetA2hsComponent } from './a2hs_widget.component';

@NgModule({
  imports: [CommonModule, WidgetA2hsRoutingModule, TranslateModule],
  exports: [WidgetA2hsComponent],
  declarations: [WidgetA2hsComponent],
})
export class WidgetA2hsModule {}
