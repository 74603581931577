import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DomModal } from 'models/dom_modal';
import { ApiService } from 'api_service';
import { Subscription } from 'rxjs';
import { BusinessService } from 'services/business.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { filter } from 'rxjs/operators';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-form-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit, OnDestroy {
  @Input() text: string;
  subscriptions: Subscription = new Subscription();
  clickable: any;

  constructor(
    private api: ApiService,
    private globals: Globals,
    private modalService: ModalService,
    private businessService: BusinessService,
    private sanitizer: DomSanitizer,
    private cicoService: PmsCiCoService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.businessService.currentLocale.subscribe(() => {
        const content = this.globals.translate('legal.form.' + this.text);
        this.clickable = this.sanitizer.bypassSecurityTrustHtml(content);
      }),
    );

    this.subscriptions.add(
      this.cicoService.inactivitySubj.pipe(filter(Boolean)).subscribe((inactive) => {
        this.modalService.close();
      }),
    );
  }

  open(data) {
    if (data.target.tagName !== 'A') {
      return;
    }
    const text = data.target.name || this.text;
    if (text !== 'privacy_terms') {
      this.api.silentGet('legal/' + text).subscribe((success: any) => {
        const body = new DomModal();
        body.title = 'legal.' + text;
        body.description = success.legal.text;
        body.addition = success.legal.additional_text;
        body.attachments = success.legal.attachments;
        body.redirect = false;
        this.modalService.open(body, true, true);
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
