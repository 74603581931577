import { Component, Input } from '@angular/core';
import { Globals } from 'base';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() small = false;
  @Input() included = false;
  @Input() text = 'misc.loading';
  @Input() headline = '';
  @Input() noLogo = false;

  constructor(public globals: Globals) {}
}
