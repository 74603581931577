import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiService {
  API_ROOT = '/api/v2/';
  BUSINESS_ROOT = this.API_ROOT;

  public codeSubj: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public code: Observable<string> = this.codeSubj.asObservable();

  constructor(private http: HttpClient) {
    this.code.pipe(filter(Boolean)).subscribe((code) => {
      this.BUSINESS_ROOT = `/api/v2/${code}/`;
    });
  }

  get(path: string, options?: any) {
    return this.http.get(environment.api_url + this.BUSINESS_ROOT + path, options);
  }

  silentGet(path: string, cache = false) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('ignoreLoadingBar', '');
    headers = headers.append('X-Cache', cache ? 'true' : 'false');
    return this.http.get(environment.api_url + this.BUSINESS_ROOT + path, {
      headers: headers,
    });
  }

  rootPost(path: string, data: {}) {
    return this.http
      .post(environment.api_url + this.API_ROOT + path, data)
      .pipe(map((response: Response) => response));
  }

  post(path: string, data: {}) {
    return this.http
      .post(environment.api_url + this.BUSINESS_ROOT + path, data)
      .pipe(map((response: Response) => response));
  }

  silentPost(path: string, data: {}) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('ignoreLoadingBar', '');
    return this.http
      .post(environment.api_url + this.BUSINESS_ROOT + path, data, { headers: headers })
      .pipe(map((response: Response) => response));
  }

  put(path: string, data = {}) {
    return this.http
      .put(environment.api_url + this.BUSINESS_ROOT + path, data)
      .pipe(map((response: Response) => response));
  }

  silentPut(path: string, data: {}) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('ignoreLoadingBar', '');
    return this.http
      .put(environment.api_url + this.BUSINESS_ROOT + path, data, { headers: headers })
      .pipe(map((response: Response) => response));
  }

  delete(path: string, data: {}) {
    return this.http
      .delete(environment.api_url + this.BUSINESS_ROOT + path, data)
      .pipe(map((response: Response) => response));
  }

  head(path: string) {
    return this.http.head(path);
  }

  batchGet(paths: Array<string>) {
    if (paths.length > 0) {
      const firstPath = paths.shift();
      this.silentGet(firstPath, true).subscribe(
        () => this.batchGet(paths),
        () => {},
      );
    }
  }
}
