import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
})
export class QuantityComponent {
  @Input() value: number;
  @Input() removable: boolean;
  @Input() disabled: boolean;
  @Output() quantity = new EventEmitter<number>();
  @Output() remove_item = new EventEmitter();

  constructor() {}

  qty(type) {
    if (!this.disabled) {
      if (type === 'reduce' && this.value > 1) {
        this.value -= 1;
      } else if (type === 'add' && this.value < 10) {
        this.value += 1;
      }
      this.quantity.emit(this.value);
    }
  }

  remove() {
    this.remove_item.emit(true);
  }
}
