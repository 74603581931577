import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '../typography/typography.module';
import { CountryBoxGroupComponent } from './country-box-group/country-box-group.component';
import { IconboxModule } from 'common/icon-box/icon-box.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CountryBoxGroupComponent],
  imports: [CommonModule, TypographyModule, IconboxModule, TranslateModule],
  exports: [CountryBoxGroupComponent],
})
export class IconBoxGroupModule {}
