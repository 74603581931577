import { Component, OnDestroy, OnInit } from '@angular/core';
import { Link } from 'models/link';
import { Module } from 'models/module';
import { Globals } from 'base';
import { NewspapersService } from './newspapers.service';

@Component({
  selector: 'app-newspapers',
  templateUrl: './newspapers.component.html',
  styleUrls: ['./newspapers.component.scss'],
})
export class NewspapersComponent implements OnInit, OnDestroy {
  items: any;
  link: Link;
  module: Module;
  rightSide = false;
  searchText: string;

  constructor(
    public globals: Globals,
    private newspapersService: NewspapersService,
  ) {}

  ngOnInit() {
    this.globals
      .getModule('newspaper')
      .then((mod: Module) => {
        this.module = mod;
        this.rightSide = this.module.medias && this.module.medias.length > 0;
        this.globals.overrideBacklink = ['/g', this.globals.getCode(), 'newspaper'];
      })
      .catch(() => {});

    this.newspapersService.getNewspapers().subscribe(
      (success: any) => {
        this.items = success.newspapers;
      },
      () => {},
    );
  }

  ngOnDestroy() {
    this.globals.overrideBacklink = null;
  }
}
