import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class NewspapersService {
  constructor(private api: ApiService) {}

  getNewspapers(): Observable<Object> {
    return this.api.get('newspapers');
  }
}
