import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceAllDirective } from '../../../shared/service/all.directive';
import { ComplaintModuleService } from '../../complaint.service';

@Component({
  selector: 'app-complaint-list-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss'],
})
export class ComplaintListAllComponent extends ServiceAllDirective implements OnInit, OnDestroy {
  mod = 'complaint';

  constructor(
    public globals: Globals,
    protected businessService: BusinessService,
    private complaintService: ComplaintModuleService,
  ) {
    super(globals, businessService);
  }

  ngOnInit() {
    super.ngOnInit(this.complaintService);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
