import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { PmsTerminalRoutingModule } from './terminal-routing.module';
import { PmsTerminalComponent } from './terminal.component';

@NgModule({
  imports: [CommonModule, PmsTerminalRoutingModule, LoaderModule, TranslateModule],
  exports: [PmsTerminalComponent],
  declarations: [PmsTerminalComponent],
})
export class PmsTerminalModule {}
