import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';
import { EventModuleService } from '../../event.service';

@Component({
  selector: 'app-event-list-compact',
  templateUrl: './compact.component.html',
  styleUrls: ['./compact.component.scss'],
})
export class EventListCompactComponent implements OnInit {
  module: Module;
  entries: any;

  searchText: string;
  rightSide: boolean;

  constructor(
    public globals: Globals,
    private eventService: EventModuleService,
  ) {}

  ngOnInit() {
    this.globals
      .getModule('event')
      .then((mod) => {
        this.module = mod;

        this.rightSide = this.module.medias && this.module.medias.length > 0;
      })
      .catch(() => {});

    this.eventService.getCompact().subscribe(
      (success) => {
        this.entries = success['compact'];

        this.globals.backlink = ['parent'];
      },
      () => {},
    );
  }
}
