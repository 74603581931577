<div class="content">
  <form
    #serviceForm="ngForm"
    class="redesign"
    *ngIf="!['new_card', 'cards', 'pin_codes'].includes(cicoService.getProcess())"
  >
    <div>
      <h1 class="heading" translate>{{ heading }}</h1>
      <p class="helper-text" translate *ngIf="description">{{ description }}</p>
    </div>
    <div class="actions-container">
      <straiv-actioncard
        *ngFor="let option of options"
        [text]="option.name"
        [icon]="option.icon"
        (click)="select(option.type)"
      ></straiv-actioncard>
    </div>
  </form>
</div>

<app-pms-cico-overlay
  [business]="business"
  [content]="olContent"
  *ngIf="
    business &&
    showOverlay &&
    (!cicoService.getProcess() ||
      ['new_card', 'cards', 'pin_codes'].includes(cicoService.getProcess()))
  "
></app-pms-cico-overlay>
