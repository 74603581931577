import { Injectable, OnDestroy } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { DomModal } from 'models/dom_modal';
import { A2hsComponent } from 'common/a2hs/a2hs.component';
import { ModalService } from 'common/modal/modal.service';
import { delay, filter, take } from 'rxjs/operators';
import { Business } from 'models/business';
import { Globals } from 'base';
import { ApiService } from './api.service';
import { RouterService } from './router.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class A2hsService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  a2hs_device = false;
  a2hsEvent: any;
  browser = '';
  classList = [];
  a2hsBrowser: boolean;
  content: SafeHtml;
  btnContent: SafeHtml;
  android = false;
  a2hsEl: HTMLElement;
  visible = false;
  text = '';

  whitelist = ['chrome', 'edge', 'firefox', 'safari', 'samsung-internet', 'opera'];

  installableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  installable: Observable<boolean> = this.installableSubject.asObservable();
  private beforeInstallSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isBeforeInstall: Observable<boolean> = this.beforeInstallSubj.asObservable();
  private beforeInstallNonWhitelistSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  isBeforeInstallNonWhitelist: Observable<boolean> =
    this.beforeInstallNonWhitelistSubj.asObservable();

  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private globals: Globals,
    private cookieService: CookieService,
    private modalService: ModalService,
    private routerService: RouterService,
  ) {}

  setBeforeInstall(isBeforeInstall: boolean) {
    this.beforeInstallSubj.next(isBeforeInstall);
  }

  setBeforeInstallNW(isBeforeInstallNonWhitelist: boolean) {
    this.beforeInstallNonWhitelistSubj.next(isBeforeInstallNonWhitelist);
  }

  subscribeModal(business) {
    this.business = business;
    if (!this.cookieService.check('a2hsPrompt') && this.business.a2hs.can_a2hs) {
      this.subscriptions.add(
        this.installable.pipe(filter(Boolean), take(1)).subscribe(() => {
          const timer = this.business.a2hs.trigger_value * 1000;
          this.subscriptions.add(
            this.globals.taskObservable
              .pipe(
                filter((str) => !str),
                take(1),
                delay(timer),
              )
              .subscribe(() => {
                this.openModal();
              }),
          );
        }),
      );
    }
  }

  install() {
    (<any>this.a2hsEvent).userChoice
      .then((choice) => {
        if (choice.outcome !== 'dismissed') {
          this.routerService.getCurrentCode().subscribe((code) => {
            this.setBeforeInstall(false);
            this.installableSubject.next(false);
            this.api.post('pwa/homescreen', {}).subscribe();
          });
        }
      })
      .catch(() => {});
    (<any>this.a2hsEvent).prompt();
  }

  closeModal() {
    this.modalService.close();
  }

  openModal() {
    const body = new DomModal();
    body.component = A2hsComponent;
    body.redirect = false;
    body.small = true;
    this.modalService.open(body);
  }

  remindLater() {
    if (!this.cookieService.check('a2hsPrompt')) {
      this.cookieService.set('a2hsPrompt', 'dismissed', 1, '/g/' + this.business.code);
    }
    this.closeModal();
  }

  browserClass(guest) {
    if (guest.browser?.length) {
      this.browser = guest.browser.replace(' ', '-');
      this.a2hsBrowser = ['chrome', 'edge'].includes(this.browser);
      this.text = this.a2hsBrowser ? 'chrome_edge' : 'other';
      this.classList.push(this.browser);
    }

    if (this.globals.isAndroid()) {
      this.classList.push('android');
    }
  }

  manage(guest, business) {
    this.business = business;
    this.browserClass(guest);

    const safari_ios = guest.browser === 'safari';
    const valid_platform_browser =
      guest.browser === 'chrome' ||
      (!this.globals.isMacOrIos() && ['edge', 'firefox', 'opera'].includes(guest.browser));
    this.a2hs_device =
      (this.globals.isAndroid() && !this.globals.isIos()) || safari_ios || valid_platform_browser;

    const installable =
      this.a2hs_device &&
      !this.globals.testView() &&
      !this.globals.kiosk() &&
      !this.globals.isInStandaloneMode() &&
      guest.popups();
    if (!installable) {
      this.installableSubject.next(false);
    } else if (this.isInWhitelist(true)) {
      this.withEvent();
    } else if (this.isInWhitelist() && !this.globals.isMac()) {
      this.installableSubject.next(true);
    } else {
      this.installableSubject.next(false);
    }
  }

  isInWhitelist(withEvent = false): boolean {
    return (
      this.browser &&
      this.whitelist.slice(0, withEvent ? 2 : this.whitelist.length).indexOf(this.browser) > -1
    );
  }

  withEvent() {
    this.subscriptions.add(
      this.isBeforeInstall.subscribe((isBeforeInstall) => {
        if (this.whitelist.includes(this.browser) && !this.globals.isIos()) {
          this.installableSubject.next(isBeforeInstall);
        } else {
          this.installableSubject.next(false);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
