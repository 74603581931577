import { Injectable } from '@angular/core';
import { StorageService } from 'services/storage.service';
import { Article } from './article';
import { Icon } from './icon';
import { Style } from './style';

@Injectable({ providedIn: 'root' })
export class LandingPage {
  view: string;
  article: Article;
  wifi_url: string;
  code: string;
  icon: Icon;
  logo: any;
  style: Style;
  locale: string;
  button: string;

  constructor(private storageService: StorageService) {}

  setLanding(landingPage: any) {
    this.view = landingPage.view;
    this.article = new Article(landingPage.article);
    this.wifi_url = landingPage.wifi_url;
    this.code = landingPage.code;
    this.icon = new Icon(landingPage.icon);
    this.logo = landingPage.logo;
    this.style = new Style(landingPage.style);
    this.locale = landingPage.locale;
    this.button = landingPage.button;

    return this;
  }

  save() {
    this.storageService.setItem('current_landing', this.toLocalStorage());
  }

  private toLocalStorage() {
    const ls_landing = {};
    Object.keys(this)
      .filter((k) => k !== 'storageService' && k !== 'cookieService')
      .forEach((k) => (ls_landing[k] = this[k]));
    return ls_landing;
  }
}
