import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';

@Component({
  selector: 'app-poi-module',
  templateUrl: './poi.component.html',
})
export class PoiComponent implements OnInit {
  module: Module;

  constructor(private globals: Globals) {}

  ngOnInit() {
    this.globals
      .getModule('poi', false)
      .then((mod) => {
        this.module = mod;
      })
      .catch(() => {});
  }
}
