import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PmsOverlayModule } from 'modules/pms/shared/overlay/overlay.module';
import { LegalModule } from 'shared/forms/legal/legal.module';
import { ScannerModule } from '../scanner/scanner.module';
import { PmsSimpleFooterModule } from '../simple-footer/simple-footer.module';
import { SuccessScreenModule } from '../success-screen/success-screen.module';
import { PmsLoginRoutingModule } from './login-routing.module';
import { PmsLoginComponent } from './login.component';
import { PmsCiCoBaseDirective } from 'modules/pms/base/cico_base.directive';
import { FormFieldsModule } from 'modules/shared/forms/fields/fields.module';
import { TypographyModule } from 'common/typography/typography.module';
import { MainTitleModule } from 'modules/pms/shared/main-title/main-title.module';
import { PassportPhotoModule } from 'modules/shared/forms/passport-photo/passport-photo.module';
import { ReservationCardModule } from './reservation-card/reservation-card.module';

@NgModule({
  imports: [
    CommonModule,
    PmsLoginRoutingModule,
    FormsModule,
    TranslateModule,
    FormFieldsModule,
    LegalModule,
    PmsOverlayModule,
    ScannerModule,
    TypographyModule,
    MainTitleModule,
    PassportPhotoModule,
    ReservationCardModule,
    PmsSimpleFooterModule,
    SuccessScreenModule,
  ],
  exports: [PmsLoginComponent],
  declarations: [PmsLoginComponent],
  providers: [PmsCiCoBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsLoginModule {}
