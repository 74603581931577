import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitComponent } from './common/init/init.component';
import { LandingpageComponent } from './common/landingpage/landingpage.component';
import { CiCoGuard } from './guards/cico.guard';
import { HomeComponent } from './modules/home/home.component';
import { OfflineComponent } from './modules/offline/offline.component';
import { PmsKioskComponent } from './modules/pms/kiosk/kiosk.component';
import { PushFormComponent } from './modules/push/form/form.component';
import { AllocatorComponent } from './modules/shared/allocator/allocator.component';
import { WishListParentComponent } from './modules/wish/list/parent/parent.component';

const routes: Routes = [
  {
    path: 'g',
    component: InitComponent,
    children: [
      {
        path: ':code',
        children: [
          { path: '', component: HomeComponent },
          {
            path: 'alarm',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'alarm' },
            pathMatch: 'full',
          },
          {
            path: 'arrangement',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'arrangement' },
            pathMatch: 'full',
          },
          {
            path: 'basket',
            loadChildren: () =>
              import('./modules/basket/basket.module').then((m) => m.BasketModule),
          },
          {
            path: 'booking',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'booking' },
            pathMatch: 'full',
          },
          { path: 'captive/:id', redirectTo: '/:id' }, // This route is just a fallback for old captive routes, theoretically not necessary
          {
            path: 'complaint',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'complaint' },
            pathMatch: 'full',
          },
          {
            path: 'complaint_category/:id',
            loadChildren: () =>
              import('./modules/complaint/list/parent/parent.module').then(
                (m) => m.ComplaintListParentModule,
              ),
          },
          {
            path: 'complaint/:id',
            loadChildren: () =>
              import('./modules/complaint/entry/entry.module').then((m) => m.ComplaintEntryModule),
          },
          {
            path: 'coupon',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'coupon' },
            pathMatch: 'full',
          },
          {
            path: 'coupon_category/:id',
            loadChildren: () =>
              import('./modules/coupon/list/parent/parent.module').then(
                (m) => m.CouponListParentModule,
              ),
          },
          {
            path: 'coupon/:id',
            loadChildren: () =>
              import('./modules/coupon/entry/entry.module').then((m) => m.CouponEntryModule),
          },
          {
            path: 'custom1',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom1' },
            pathMatch: 'full',
          },
          {
            path: 'custom2',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom2' },
            pathMatch: 'full',
          },
          {
            path: 'custom3',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom3' },
            pathMatch: 'full',
          },
          {
            path: 'custom4',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom4' },
            pathMatch: 'full',
          },
          {
            path: 'custom5',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom5' },
            pathMatch: 'full',
          },
          {
            path: 'custom6',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom6' },
            pathMatch: 'full',
          },
          {
            path: 'custom7',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom7' },
            pathMatch: 'full',
          },
          {
            path: 'custom8',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom8' },
            pathMatch: 'full',
          },
          {
            path: 'custom9',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom9' },
            pathMatch: 'full',
          },
          {
            path: 'custom10',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'custom10' },
            pathMatch: 'full',
          },
          {
            path: 'confirm',
            loadChildren: () =>
              import('./modules/confirm/confirm.module').then((m) => m.ConfirmModule),
          },
          {
            path: 'event',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'event' },
            pathMatch: 'full',
          },
          {
            path: 'event_category/:id',
            loadChildren: () =>
              import('./modules/event/list/parent/parent.module').then(
                (m) => m.EventListParentModule,
              ),
          },
          {
            path: 'event/:id',
            loadChildren: () =>
              import('./modules/event/entry/entry.module').then((m) => m.EventEntryModule),
          },
          {
            path: 'feedback',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'feedback' },
            pathMatch: 'full',
          },
          {
            path: 'file/:id',
            loadChildren: () =>
              import('./modules/shared/file/file.module').then((m) => m.FileModule),
          },
          {
            path: 'group_portfolio',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'group_portfolio' },
            pathMatch: 'full',
          },
          {
            path: 'group_portfolio/map',
            loadChildren: () =>
              import('./modules/group_portfolio/map/map.module').then((m) => m.GroupMapModule),
          },
          {
            path: 'group_portfolio/social',
            loadChildren: () =>
              import('./modules/group_portfolio/social/group_social.module').then(
                (m) => m.GroupSocialModule,
              ),
          },
          {
            path: 'group_portfolio/:id',
            loadChildren: () =>
              import('./modules/group_portfolio/entry/group_portfolio.module').then(
                (m) => m.GroupPortfolioEntryModule,
              ),
          },
          {
            path: 'housekeeping',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'housekeeping' },
            pathMatch: 'full',
          },
          {
            path: 'language',
            loadChildren: () =>
              import('./common/language/language.module').then((m) => m.LanguageModule),
          },
          {
            path: 'legal/:id',
            loadChildren: () =>
              import('./modules/legal/entry/entry.module').then((m) => m.LegalEntryModule),
          },
          {
            path: 'link',
            loadChildren: () =>
              import('./modules/shared/link/link.module').then((m) => m.LinkModule),
          },
          {
            path: 'link/external',
            loadChildren: () =>
              import('./modules/shared/link/external/external.module').then(
                (m) => m.ExternalLinkModule,
              ),
          },
          {
            path: 'list/:id',
            loadChildren: () =>
              import('./modules/shared/list/entry/entry.module').then((m) => m.ListEntryModule),
          },
          {
            path: 'list_category/:id',
            loadChildren: () =>
              import('./modules/shared/list/list.module').then((m) => m.ListModule),
          },
          {
            path: 'location',
            loadChildren: () =>
              import('./common/location/location.module').then((m) => m.LocationModule),
          },
          {
            path: 'menu',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'menu' },
            pathMatch: 'full',
          },
          {
            path: 'menu_category/:id',
            loadChildren: () =>
              import('./modules/menu/list/parent/parent.module').then(
                (m) => m.MenuListParentModule,
              ),
          },
          {
            path: 'menu/:id',
            loadChildren: () =>
              import('./modules/menu/entry/entry.module').then((m) => m.MenuEntryModule),
          },
          {
            path: 'menu/:id/:cart',
            loadChildren: () =>
              import('./modules/menu/entry/entry.module').then((m) => m.MenuEntryModule),
          },
          {
            path: 'news',
            loadChildren: () => import('./modules/news/news.module').then((m) => m.NewsModule),
          },
          {
            path: 'news/:id',
            loadChildren: () =>
              import('./modules/news/entry/entry.module').then((m) => m.NewsEntryModule),
          },
          {
            path: 'newspaper',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'newspaper' },
            pathMatch: 'full',
          },
          {
            path: 'newspaper_category/:id',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'newspaper' },
            pathMatch: 'full',
          },
          {
            path: 'newsletter',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'newsletter' },
            pathMatch: 'full',
          },
          {
            path: 'pms_check_in',
            component: AllocatorComponent,
            data: { mod: 'pms_check_in' },
            canDeactivate: [CiCoGuard],
          },
          {
            path: 'pms_check_out',
            component: AllocatorComponent,
            data: { mod: 'pms_check_out' },
            canDeactivate: [CiCoGuard],
          },
          { path: 'pms_journey', redirectTo: 'push' },
          {
            path: 'pms_service',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'pms_service' },
            pathMatch: 'full',
          },
          {
            path: 'pms_kiosk/:cryptcode',
            component: PmsKioskComponent,
            canDeactivate: [CiCoGuard],
          },
          {
            path: 'pms_kiosk/mobile/:cryptcode',
            component: PmsKioskComponent,
            canDeactivate: [CiCoGuard],
          }, // as fallback for created wizards
          {
            path: 'pms_kiosk/terminal/:cryptcode',
            component: PmsKioskComponent,
            canDeactivate: [CiCoGuard],
          }, // as fallback for created wizards
          {
            path: 'pms_wizard',
            loadChildren: () =>
              import('./modules/pms/kiosk/kiosk.module').then((m) => m.PmsKioskModule),
          }, // as fallback for created wizards
          {
            path: 'pms_reservation',
            loadChildren: () =>
              import('./modules/pms/reservation/reservation.module').then(
                (m) => m.PmsReservationModule,
              ),
            data: { mod: 'pms_reservation' },
            pathMatch: 'full',
          },
          {
            path: 'pms_door',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'pms_door' },
            pathMatch: 'full',
          },
          {
            path: 'poi',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'poi' },
            pathMatch: 'full',
          },
          {
            path: 'poi_category/:id',
            loadChildren: () =>
              import('./modules/poi/list/parent/parent.module').then((m) => m.PoiListParentModule),
          },
          {
            path: 'poi/map',
            loadChildren: () => import('./modules/poi/map/map.module').then((m) => m.PoiMapModule),
          },
          {
            path: 'poi/:id',
            loadChildren: () =>
              import('./modules/poi/entry/entry.module').then((m) => m.PoiEntryModule),
          },
          {
            path: 'portfolio',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'portfolio' },
            pathMatch: 'full',
          },
          {
            path: 'portfolio_category/:id',
            loadChildren: () =>
              import('./modules/portfolio/list/parent/parent.module').then(
                (m) => m.PortfolioListParentModule,
              ),
          },
          {
            path: 'portfolio/:id',
            loadChildren: () =>
              import('./modules/portfolio/entry/entry.module').then((m) => m.PortfolioEntryModule),
          },
          { path: 'push', component: PushFormComponent },
          {
            path: 'shop',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'shop' },
            pathMatch: 'full',
          },
          {
            path: 'shop_category/:id',
            loadChildren: () =>
              import('./modules/shop/list/parent/parent.module').then(
                (m) => m.ShopListParentModule,
              ),
          },
          {
            path: 'shop/:id',
            loadChildren: () =>
              import('./modules/shop/entry/entry.module').then((m) => m.ShopEntryModule),
          },
          {
            path: 'smart_feedback',
            loadChildren: () =>
              import('./modules/feedback/smart/smart_feedback.module').then(
                (m) => m.SmartFeedbackModule,
              ),
            data: { mod: 'feedback' },
            pathMatch: 'full',
          },
          {
            path: 'spa',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'spa' },
            pathMatch: 'full',
          },
          {
            path: 'spa_category/:id',
            loadChildren: () =>
              import('./modules/spa/list/parent/parent.module').then((m) => m.SpaListParentModule),
          },
          {
            path: 'spa/:id',
            loadChildren: () =>
              import('./modules/spa/entry/entry.module').then((m) => m.SpaEntryModule),
          },
          {
            path: 'reservation',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'reservation' },
            pathMatch: 'full',
          },
          {
            path: 'reservation/:id',
            loadChildren: () =>
              import('./modules/reservation/entry/entry.module').then(
                (m) => m.ReservationEntryModule,
              ),
          },
          {
            path: 'taxi',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'taxi' },
            pathMatch: 'full',
          },
          {
            path: 'tourism',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'tourism' },
            pathMatch: 'full',
          },
          {
            path: 'tv_stations',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'tv_stations' },
            pathMatch: 'full',
          },
          {
            path: 'weather',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'weather' },
            pathMatch: 'full',
          },
          {
            path: 'vcard',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'vcard' },
            pathMatch: 'full',
          },
          {
            path: 'wifi',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'wifi' },
            pathMatch: 'full',
          },
          {
            path: 'wifi/:id',
            loadChildren: () =>
              import('./modules/wifi/entry/entry.module').then((m) => m.WifiEntryModule),
          },
          {
            path: 'wish',
            loadChildren: () =>
              import('./modules/shared/allocator/allocator.module').then((m) => m.AllocatorModule),
            data: { mod: 'wish' },
            pathMatch: 'full',
          },
          { path: 'wish_category/:id', component: WishListParentComponent },
          {
            path: 'wish/:id',
            loadChildren: () =>
              import('./modules/wish/entry/entry.module').then((m) => m.WishEntryModule),
          },
        ],
      },
    ],
  },
  {
    path: 'w',
    children: [
      {
        path: ':code',
        children: [{ path: 'start', component: LandingpageComponent }],
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'error/:status',
        loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
      },
      { path: 'offline', component: OfflineComponent },
      {
        path: ':id/:module',
        loadChildren: () => import('./straiv/straiv.module').then((m) => m.StraivModule),
      },
      {
        path: ':id/:module/:sub',
        loadChildren: () => import('./straiv/straiv.module').then((m) => m.StraivModule),
      },
      {
        path: ':id',
        loadChildren: () => import('./straiv/straiv.module').then((m) => m.StraivModule),
      },
      {
        path: '',
        loadChildren: () => import('./straiv/straiv.module').then((m) => m.StraivModule),
        pathMatch: 'full',
      },
    ],
  },
  { path: '**', redirectTo: location.pathname.substr(0, 7) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
