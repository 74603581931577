<ng-container *ngIf="business">
  <div class="page-headline">
    <h1 translate>gps.location</h1>
  </div>

  <div class="container">
    <div class="container-right">
      <app-swiper [items]="business.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">
      <div *ngIf="showHint" class="info_box info">
        <i class="fas fa-info-circle"></i>
        <p translate>gps.hint</p>
      </div>
      <p>{{ description | translate }}</p>
      <p translate>gps.howto</p>
    </div>
  </div>
</ng-container>
