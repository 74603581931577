import { Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-widget-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class WidgetClockComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  time = '';
  date = '';

  constructor(private businessService: BusinessService) {
    this.subscriptions.add(
      this.businessService.currentLocale.subscribe(() => {
        this.setDateTime();
      }),
    );
    this.subscriptions.add(interval(5000).subscribe(() => this.setDateTime()));
  }

  setDateTime() {
    this.time = moment().format('LT');
    this.date = moment().format('LL');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
