import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { ShopModuleService } from '../../shop.service';

@Component({
  selector: 'app-shop-list-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss'],
})
export class ShopListParentComponent extends ServiceParentDirective implements OnInit, OnDestroy {
  mod = 'shop';

  constructor(
    public globals: Globals,
    protected route: ActivatedRoute,
    protected businessService: BusinessService,
    protected sanitizer: DomSanitizer,
    private shopService: ShopModuleService,
  ) {
    super(globals, route, businessService, sanitizer);
  }

  ngOnInit() {
    super.ngOnInit(this.shopService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
