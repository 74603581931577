import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoScreenComponent } from './info-screen.component';

@NgModule({
  declarations: [InfoScreenComponent],
  imports: [CommonModule],
  exports: [InfoScreenComponent],
})
export class InfoScreenModule {}
