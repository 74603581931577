import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { SuggestionsService } from 'widgets/suggestions/suggestions.service';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { PoiModuleService } from '../../poi.service';

@Component({
  selector: 'app-poi-list-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss'],
})
export class PoiListParentComponent extends ServiceParentDirective implements OnInit, OnDestroy {
  mod = 'poi';

  showMap = false;
  distances = false;

  constructor(
    public globals: Globals,
    protected route: ActivatedRoute,
    protected businessService: BusinessService,
    protected sanitizer: DomSanitizer,
    protected suggestionsService: SuggestionsService,
    private poiService: PoiModuleService,
  ) {
    super(globals, route, businessService, sanitizer, suggestionsService);
  }

  ngOnInit() {
    super.ngOnInit(this.poiService).then(() => {
      this.showMap = this.module.settings.show_map;
      this.distances = this.module.settings.distances;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
