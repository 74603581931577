<section id="search">
  <div class="searchbox">
    <input
      type="search"
      placeholder="{{ 'misc.searchenter' | translate }}"
      #input
      (keyup)="searchFor(input.value)"
    />
    <div class="search-icon"><i class="fas fa-search"></i></div>
  </div>
  <div id="search-results">
    <ul>
      <li translate *ngIf="no_results">search.no_results</li>
      <li translate *ngIf="!length">search.min_length</li>
      <li class="loading" *ngIf="loading"></li>
      <li *ngIf="deactivated" translate>search.maintenance</li>
      <ng-container *ngFor="let module of results">
        <li class="heading">
          <i class="fa {{ module[1].icon }}"></i>
          {{ module[1].name }}
        </li>
        <li *ngFor="let result of module[1].values">
          <a [routerLink]="url(result.url)" (click)="close()">
            {{ result.title }}
            <small [innerHtml]="result.description?.length ? result.description : '&nbsp;'"></small>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</section>
<div id="curtain" (click)="close()"></div>
