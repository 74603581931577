import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SuccessScreenComponent } from './success-screen.component';

@NgModule({
  declarations: [SuccessScreenComponent],
  imports: [CommonModule, TranslateModule],
  exports: [SuccessScreenComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SuccessScreenModule {}
