import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { FormPhoneFieldModule } from 'shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { AlarmRoutingModule } from './alarm-routing.module';
import { AlarmComponent } from './alarm.component';

@NgModule({
  imports: [
    CommonModule,
    AlarmRoutingModule,
    EmailValidatorModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    FormFieldsModule,
    SendCancelModule,
    FormPlaceModule,
    TranslateModule,
    ModuleTitleModule,
    DatePickerModule,
    FormPhoneFieldModule,
    FormsModule,
  ],
  declarations: [AlarmComponent],
})
export class AlarmModule {}
