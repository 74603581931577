import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PmsAddressRoutingModule } from './address-routing.module';
import { PmsAddressComponent } from './address.component';

@NgModule({
  imports: [CommonModule, PmsAddressRoutingModule, FormsModule, TranslateModule],
  exports: [PmsAddressComponent],
  declarations: [PmsAddressComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsAddressModule {}
