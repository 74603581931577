<form (ngSubmit)="onSubmit()" #serviceForm="ngForm" class="{{ class }}">
  <div class="alert" [innerHtml]="error_text" *ngIf="error_text"></div>

  <ng-container *ngIf="showNameFields">
    <div class="form-box">
      <label translate>misc.first_name</label>
      <input
        id="first_name"
        placeholder="{{ 'misc.first_name' | translate }}"
        #first_name="ngModel"
        [(ngModel)]="newsletter.first_name"
        name="first_name"
        [required]="requireNameFields"
      />
      <label translate for="first_name" class="error">validation.first_name</label>
    </div>

    <div class="form-box">
      <label translate>misc.last_name</label>
      <input
        id="last_name"
        placeholder="{{ 'misc.last_name' | translate }}"
        #last_name="ngModel"
        [(ngModel)]="newsletter.last_name"
        name="last_name"
        [required]="requireNameFields"
      />
      <label translate for="last_name" class="error">validation.last_name</label>
    </div>
  </ng-container>

  <div class="form-box">
    <label translate>misc.mail</label>
    <input
      type="email"
      id="email"
      name="email"
      placeholder="{{ 'misc.mail' | translate }}"
      #email="ngModel"
      [(ngModel)]="newsletter.email"
      emailvalidator
      required
    />
    <label translate for="email" class="error">validation.email</label>
  </div>

  <div class="form-box">
    <div class="checkbox">
      <input
        type="checkbox"
        name="privacy"
        id="privacy"
        [(ngModel)]="newsletter.privacy"
        class="option-input checkbox"
        required
      />
      <label translate for="privacy">newsletter.privacy</label>
      <label translate class="error">validation.required</label>
    </div>
  </div>

  <app-form-send-cancel
    [confirm]="confirm_name"
    [status]="globals.send_cancel_disabled"
  ></app-form-send-cancel>
</form>
