<ng-container *ngIf="business">
  <div class="overview-text-container">
    <em *ngIf="welcomeMessage">{{ welcomeMessage }}</em>

    <a
      class="overview-button"
      [routerLink]="['/g', globals.getCode(), 'language']"
      *ngIf="!business.language_sidebar && business.multilingual"
      [ngClass]="{ disabled_state: offline }"
    >
      Select Language
    </a>
    <app-internet-access *ngIf="network"></app-internet-access>
  </div>
  <app-news *ngIf="news"></app-news>
</ng-container>
