import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PmsSignatureComponent } from './signature.component';

const routes: Routes = [{ path: '', component: PmsSignatureComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PmsSignatureRoutingModule {}
