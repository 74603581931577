import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class RouterService implements OnDestroy {
  subscription: Subscription;

  private subject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private router: Router) {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        const path = (<any>event).url.split('/');
        if (path.length >= 3) {
          const code = path[this.pathLocation(path) + 1];
          this.subject.next(code.includes('?') ? code.substring(0, code.indexOf('?')) : code);
          this.subscription.unsubscribe();
        }
      });
  }

  getCurrentCode(): Observable<string> {
    return this.subject.asObservable();
  }

  pathLocation(path) {
    return path.indexOf('g') > -1 ? path.indexOf('g') : path.indexOf('w');
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
