import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PmsWizardTerminalDoorRoutingModule } from './door-routing.module';
import { PmsWizardTerminalDoorComponent } from './door.component';
import { PmsPinCodesModule } from '../../check_in/pin_codes/pin_codes.module';
import { PmsEncoderModule } from '../../shared/encoder/encoder.module';
import { PmsOverlayModule } from '../../shared/overlay/overlay.module';
import { PmsSimpleFooterModule } from '../../shared/simple-footer/simple-footer.module';

@NgModule({
  imports: [
    CommonModule,
    PmsWizardTerminalDoorRoutingModule,
    TranslateModule,
    PmsPinCodesModule,
    FormsModule,
    PmsEncoderModule,
    PmsSimpleFooterModule,
    PmsOverlayModule,
  ],
  exports: [PmsWizardTerminalDoorComponent],
  declarations: [PmsWizardTerminalDoorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsWizardTerminalDoorModule {}
