<div class="menu files">
  <a
    *ngFor="let file of files"
    [routerLink]="['/g', globals.getCode(), 'file', file.attachment.id]"
    [queryParams]="blank ? { blank: true } : null"
    [target]="blank ? '_blank' : '_self'"
  >
    <div><i class="far fa-file-pdf"></i></div>
    <div>{{ file.attachment.name }}</div>
    <div>
      <i class="fas fa-angle-right icon-next"></i>
    </div>
  </a>
</div>
