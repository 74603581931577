import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { ModalService } from 'common/modal/modal.service';
import { DomModal } from 'models/dom_modal';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable, Subscription } from 'rxjs';
import { GroupBusinessService } from './group_business.service';
import { WidgetGroupBusinessItemComponent } from './item/group_business_item.component';

@Component({
  selector: 'app-widget-group-business',
  templateUrl: './group_business.component.html',
})
export class WidgetGroupBusinessComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  widget: any;
  slideIndex = 0;

  public slideConfig: SwiperConfigInterface = {
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    watchOverflow: true,
    navigation: true,
  };

  constructor(
    private globals: Globals,
    private api: ApiService,
    private modalService: ModalService,
    private groupBusinessService: GroupBusinessService,
  ) {}

  ngOnInit() {
    this.getWidget().subscribe((success) => {
      this.widget = success['widget'];
      if (this.widget && this.widget.length === 1) {
        this.slideConfig.loop = false;
        this.slideConfig.navigation = false;
      }
    });
  }

  action(entry, event) {
    if (
      event?.target?.className !== 'swiper-button-next' &&
      event?.target?.className !== 'swiper-button-prev'
    ) {
      if (entry.link.modal) {
        this.subscriptions.add(
          this.groupBusinessService.getGroupBusiness(entry.slug).subscribe((business) => {
            this.groupBusinessService.groupBusinessSubj.next(business['business']);
            const body = new DomModal();
            body.title = business['business'].name;
            body.redirect = false;
            body.component = WidgetGroupBusinessItemComponent;
            this.modalService.open(body);
          }),
        );
      } else {
        this.globals.openUrl(entry.link.url, entry.link.url_target);
      }
    }
  }

  imageUrl(entry) {
    const images = entry.medias.filter((media) => media.media.type === 'image');
    if (images.length > 0) {
      return images[0].media.url;
    }
    return '';
  }

  getWidget(): Observable<Object> {
    return this.api.get('widgets/group_business');
  }

  setIndex(index: number) {
    this.slideIndex = index;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
