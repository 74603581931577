<div id="container" class="overviews" *ngIf="landingPage?.article">
  <app-background [medias]="medias"></app-background>
  <div class="fog"></div>
  <section id="content">
    <div class="logo">
      <img [src]="landingPage.logo" alt="logo" />
    </div>
    <div class="article">
      <h2>{{ landingPage.article.title }}</h2>
      <p [innerHTML]="content"></p>
    </div>
    <div class="overview-text-container">
      <a
        class="overview-button"
        [href]="landingPage.wifi_url"
        target="_blank"
        [innerHTML]="landingPage.button"
      ></a>
    </div>
  </section>
</div>
