import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

interface DateValidatorOptions {
  locale?: string;
  required?: boolean;
  min?: Date | string;
  max?: Date | string;
  mask?: string;
  validation?: string;
  includeToday?: boolean;
}
@Directive({
  selector: '[datevalidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidator,
      multi: true,
    },
  ],
})
export class DateValidator implements Validator, OnChanges {
  validator: ValidatorFn;
  value: any;
  elem: any;

  private control: FormControl;
  @Input('datevalidator') options: DateValidatorOptions;

  constructor(el: ElementRef) {
    this.elem = el.nativeElement;
    this.validator = this.dateValidator();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.control?.updateValueAndValidity();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  dateValidator(): ValidatorFn {
    return (c: FormControl) => {
      this.control = c;
      this.value = this.control.value;
      const errors = this.check();
      const errorObj = {};
      errors.forEach((err) => (errorObj[err] = true));
      return errors.length ? errorObj : null;
    };
  }

  check(): string[] {
    const mask = this.options.mask || this.getLocaleMask(this.options.locale).momentMask;
    return this.validateDateInput(this.value, mask);
  }

  /**
   * Validates the input date based on various criteria (required, future, past, etc.).
   * Updates the `isInvalid` state based on validation results and returns an array of validation messages.
   * @param value - The current value of the input to validate.
   * @returns An array of validation messages indicating which checks failed.
   */
  private validateDateInput = (value: string, mask): string[] => {
    const inputDate = moment(value, mask, true);
    const formattedDate = inputDate.isValid() ? inputDate.format('YYYY-MM-DD') : '';
    const today = moment().format('YYYY-MM-DD');
    const required = this.options.required && !value;

    const validationRules = {
      required: required,
      dateInvalid: !inputDate.isValid() && !required,
      future:
        this.options.validation === 'future' && inputDate.isValid()
          ? this.options.includeToday
            ? !moment(formattedDate).isSameOrAfter(today)
            : !moment(formattedDate).isAfter(today)
          : false,
      past:
        this.options.validation === 'past' && inputDate.isValid()
          ? this.options.includeToday
            ? !moment(formattedDate).isSameOrBefore(today)
            : !moment(formattedDate).isBefore(today)
          : false,
      adult:
        this.options.validation === 'adult' && inputDate.isValid()
          ? !inputDate.add(18, 'years').isSameOrBefore(today, 'day')
          : false,
      kids:
        this.options.validation === 'kids' && inputDate.isValid()
          ? !inputDate.add(18, 'years').isAfter(today, 'day')
          : false,
      min:
        this.options.min && inputDate.isValid()
          ? this.options.includeToday
            ? !moment(formattedDate).isSameOrAfter(this.options.min)
            : !moment(formattedDate).isAfter(this.options.min)
          : false,
      max:
        this.options.max && inputDate.isValid()
          ? this.options.includeToday
            ? !moment(formattedDate).isSameOrBefore(this.options.max)
            : !moment(formattedDate).isBefore(this.options.max)
          : false,
    };

    // Filter out failed validations and map to their corresponding messages
    const validationMessages = Object.entries(validationRules)
      .filter(([, failed]) => failed)
      .map(([message]) => message);
    return validationMessages;
  };

  private getLocaleMask(locale: string) {
    return (
      this.LOCALE_MASKS.find((mask) => mask.locale === locale) ||
      this.LOCALE_MASKS.find((mask) => mask.locale === 'default')
    );
  }

  private LOCALE_MASKS = [
    { locale: 'de', momentMask: 'DD.MM.YYYY' },
    { locale: 'ar', momentMask: 'YYYY/MM/DD' },
    { locale: 'default', momentMask: 'DD/MM/YYYY' },
  ];
}
