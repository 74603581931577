import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { List } from 'models/list';
import { ListCategory } from 'models/list_category';
import { Module } from 'models/module';
import { Target } from 'models/target';
import { Globals } from 'base';
import { ListService } from './list.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {
  list: List;
  category: ListCategory;
  categories: any;
  entries: string[];
  target: Target;

  title: string;
  description: string;
  medias: [];

  rightSide = false;
  searchText: string;

  @Input() module: Module;

  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private listService: ListService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      let id: string;
      if (this.module) {
        this.listService.getMainCategory(this.module.list.id).subscribe(
          (success) => {
            this.setContent(success);
          },
          () => {},
        );
      } else {
        this.listService.getCategories(params['id']).subscribe(
          (success) => {
            this.setContent(success);
          },
          () => {},
        );
      }
    });
  }

  setContent(success) {
    if (success['category']) {
      this.category = new ListCategory(success['category']);
      this.globals.page = this.category['gid'];
    }
    this.categories = success['categories'];
    this.entries = success['entries'];
    this.target = success['target'] ? new Target(success['target']) : null;

    this.globals.target_navigate(this.categories, this.entries, 'list', this.target);

    this.title = this.category ? this.category.name : this.module.name;
    this.description = this.category ? this.category.description : this.module.description;
    this.medias = this.category ? this.category.medias : this.module.medias;

    this.rightSide = this.medias && this.medias.length > 0;
  }
}
