<section class="modal-box">
  <div class="desc">
    <h1 translate>terminalConflict.title</h1>
    <em translate>terminalConflict.description</em>
  </div>
  <div class="btn-grid">
    <button class="btn-filled small-width main-bg" (click)="wsService.confirm()" translate>
      misc.confirm
    </button>
    <button class="btn-outline small-width main-text" (click)="abort()" translate>
      misc.cancel
    </button>
  </div>
</section>
