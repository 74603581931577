import { Link } from './link';

export class Api {
  kind: string;
  script: string;
  credential: string;
  link: Link;

  constructor(data: any) {
    this.kind = data.kind;
    this.script = data.script;
    this.credential = data.credential;
    this.link = data.link ? new Link(data.link) : null;
  }
}
