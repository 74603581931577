import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class MenuModuleService {
  constructor(private api: ApiService) {}

  getEntry(id: string): Observable<Object> {
    return this.api.get('menu/entries/' + id);
  }

  getCategories(id: string): Observable<Object> {
    return this.api.get('menu/categories/' + id);
  }

  getAll(): Observable<Object> {
    return this.api.get('menu/all');
  }

  getCartItem(id: string): Observable<Object> {
    return this.api.get('basket/' + id);
  }

  getBasket(): Observable<Object> {
    return this.api.get('basket');
  }

  delete(item): Observable<Object> {
    return this.api.delete('basket/' + item, {});
  }
}
