import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetLogoComponent } from './logo.component';

const routes: Routes = [{ path: '', component: WidgetLogoComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WidgetLogoRoutingModule {}
