import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'base';
import { RestrictionComponent } from 'common/restriction/restriction.component';
import { Business } from 'models/business';
import { Link } from 'models/link';
import { Module } from 'models/module';

import { AlarmComponent } from 'modules/alarm/alarm.component';
import { GoldkeyComponent } from 'modules/api/goldkey/goldkey.component';
import { TacComponent } from 'modules/api/tac/tac.component';
import { BasketComponent } from 'modules/basket/basket.component';
import { ComplaintComponent } from 'modules/complaint/complaint.component';
import { CouponComponent } from 'modules/coupon/coupon.component';
import { EventComponent } from 'modules/event/event.component';
import { FeedbackComponent } from 'modules/feedback/feedback.component';
import { GroupPortfolioComponent } from 'modules/group_portfolio/overview/group_portfolio.component';
import { HousekeepingComponent } from 'modules/housekeeping/housekeeping.component';
import { MenuModuleComponent } from 'modules/menu/menu.component';
import { NewsletterComponent } from 'modules/newsletter/newsletter.component';
import { NewspapersComponent } from 'modules/newspapers/newspapers.component';
import { PoiComponent } from 'modules/poi/poi.component';
import { PortfolioModuleComponent } from 'modules/portfolio/portfolio.component';
import { PushFormComponent } from 'modules/push/form/form.component';
import { ReservationComponent } from 'modules/reservation/overview/reservation.component';
import { ShopComponent } from 'modules/shop/shop.component';
import { SpaComponent } from 'modules/spa/spa.component';
import { TaxiComponent } from 'modules/taxi/taxi.component';
import { TvStationsComponent } from 'modules/tv_stations/tv_stations.component';
import { VcardComponent } from 'modules/vcard/vcard.component';
import { WeatherComponent } from 'modules/weather/weather.component';
import { WifiComponent } from 'modules/wifi/wifi.component';
import { WishComponent } from 'modules/wish/wish.component';
import { PmsCheckInComponent } from 'pms_modules/check_in/check_in.component';
import { PmsCheckOutComponent } from 'pms_modules/check_out/check_out.component';
import { PmsDoorComponent } from 'pms_modules/door/door.component';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { ArticleComponent } from '../article/article.component';
import { FilesComponent } from '../files/files.component';
import { LinkComponent } from '../link/link.component';
import { ListComponent } from '../list/list.component';
import { ScriptComponent } from '../script/script.component';

@Component({
  selector: 'app-module',
  templateUrl: './allocator.component.html',
})
export class AllocatorComponent implements OnInit, OnDestroy {
  public module: Module;
  private subscriptions: Subscription = new Subscription();
  private currentModule: Module;

  classList = document.querySelector('html')['className'];
  supportGeolocation = this.classList.indexOf('can_geolocation') !== -1;

  link: Link;
  mode: string;
  view: any;
  business: Business;
  checkingGps = true;

  mapping = {
    alarm: AlarmComponent,
    basket: BasketComponent,
    complaint: ComplaintComponent,
    coupon: CouponComponent,
    event: EventComponent,
    feedback: FeedbackComponent,
    group_portfolio: GroupPortfolioComponent,
    housekeeping: HousekeepingComponent,
    menu: MenuModuleComponent,
    newspaper: NewspapersComponent,
    newsletter: NewsletterComponent,
    pms_check_in: PmsCheckInComponent,
    pms_check_out: PmsCheckOutComponent,
    pms_door: PmsDoorComponent,
    poi: PoiComponent,
    portfolio: PortfolioModuleComponent,
    push: PushFormComponent,
    shop: ShopComponent,
    spa: SpaComponent,
    reservation: ReservationComponent,
    taxi: TaxiComponent,
    tv_stations: TvStationsComponent,
    weather: WeatherComponent,
    vcard: VcardComponent,
    wifi: WifiComponent,
    wish: WishComponent,
  };

  apiMapping = {
    // newspaper
    sharemagazines: LinkComponent,
    goldkey: GoldkeyComponent,
    // booking
    caesar_data: LinkComponent,
    dirs21: LinkComponent,
    hotelnetsolutions: LinkComponent,
    ibelsa_ibe: LinkComponent,
    hotel_spider: LinkComponent,
    onepagebooking: LinkComponent,
    profitroom: LinkComponent,
    resavio: LinkComponent,
    synxis: LinkComponent,
    travelclick: LinkComponent,
    uphotel: ScriptComponent,
    upperbooking: LinkComponent,
    vioma: ScriptComponent,
    // spa
    tac: TacComponent,
    giggle: ScriptComponent,
    // reservation
    bookatable: LinkComponent,
    gastronovi: ScriptComponent,
    // door opening
    four_suites: PmsDoorComponent,
    // tourism
    mylike: ScriptComponent,
  };

  @ViewChild('service', { read: ViewContainerRef, static: true }) service: ViewContainerRef;

  constructor(
    private globals: Globals,
    private route: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private businessService: BusinessService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.businessService.current_business
        .pipe(filter(Boolean), first())
        .subscribe((business: Business) => {
          this.business = business;
          this.route.data.subscribe((data) => {
            if (this.business.ip_modules && this.business.ip_modules.indexOf(data.mod) !== -1) {
              this.globals.getNetworkInfo(data.mod).subscribe(
                () => {
                  this.getModule(data);
                },
                (err) => {
                  if (err.status === 405) {
                    const factory = this.resolver.resolveComponentFactory(RestrictionComponent);
                    this.service.createComponent(factory);
                    this.globals.setModule(data.mod);
                  }
                },
              );
            } else {
              this.getModule(data);
            }
          });
        }),
    );

    this.subscriptions.add(
      this.globals.module.subscribe((module: Module) => {
        this.currentModule = module;
      }),
    );
  }

  private setComponent(data) {
    this.mode = this.module.pretty_mode;
    if (location.hash === '#view') {
      this.mode = this.module.pretty_view_mode;
    }

    switch (this.mode) {
      case 'service': {
        const name = this.mapping[data.mod];
        const factory = this.resolver.resolveComponentFactory(name);
        this.view = this.service.createComponent(factory);
        break;
      }
      case 'article': {
        const factory = this.resolver.resolveComponentFactory(ArticleComponent);
        this.view = this.service.createComponent(factory);
        this.view.instance.module = this.module;
        break;
      }
      case 'link': {
        const factory = this.resolver.resolveComponentFactory(LinkComponent);
        this.view = this.service.createComponent(factory);
        this.view.instance.module = this.module;
        break;
      }
      case 'pdf': {
        const factory = this.resolver.resolveComponentFactory(FilesComponent);
        this.view = this.service.createComponent(factory);
        this.view.instance.module = this.module;
        break;
      }
      case 'list': {
        const factory = this.resolver.resolveComponentFactory(ListComponent);
        this.view = this.service.createComponent(factory);
        this.view.instance.module = this.module;
        break;
      }
      case 'api': {
        const name = this.apiMapping[this.module.module_api.kind];
        const factory = this.resolver.resolveComponentFactory(name);
        this.view = this.service.createComponent(factory);
        this.view.instance.module = this.module;
        break;
      }
    }
  }

  private getModule(data) {
    this.globals
      .getModule(data.mod)
      .then((mod) => {
        this.module = mod;
        if (this.module.gps_radius) {
          if (this.supportGeolocation) {
            this.isInRange(this.module.gps_radius)
              .then((inRange) => {
                if (this.currentModule.type === data.mod) {
                  if (!inRange) {
                    this.globals.navigate('location');
                  }
                  this.checkingGps = false;
                  this.setComponent(data);
                }
              })
              .catch((denied) => {
                this.globals.navigate('location', denied);
              });
          } else {
            this.globals.navigate('location', 'denied');
          }
        } else {
          this.setComponent(data);
        }
      })
      .catch();
  }

  private isInRange(range): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(this.foundPosition(position, range));
        },
        (error) => {
          this.lowAccuracy(error, range)
            .then((inRange) => {
              resolve(inRange);
            })
            .catch((denied) => {
              reject(denied);
            });
        },
        { maximumAge: 0, timeout: 5000, enableHighAccuracy: true },
      );
    });
  }

  lowAccuracy(error, range) {
    return new Promise<boolean>((resolve, reject) => {
      if (error.code === error.TIMEOUT) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(this.foundPosition(position, range));
          },
          () => {
            reject();
          },
          { maximumAge: 0, timeout: 10000, enableHighAccuracy: false },
        );
      } else if (error.code === error.PERMISSION_DENIED) {
        reject('denied');
      }
      reject();
    });
  }

  foundPosition(position, range) {
    const business = this.globals.business;
    const glng = position.coords.longitude;
    const glat = position.coords.latitude;
    const blng = business.coordinates.longitude;
    const blat = business.coordinates.latitude;

    return this.distance(blng, blat, glng, glat) <= range;
  }

  private distance(blng, blat, glng, glat) {
    const R = 6371; // Radius of the earth in km
    const dLat = ((glat - blat) * Math.PI) / 180;
    const dLon = ((glng - blng) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((blat * Math.PI) / 180) *
        Math.cos((glat * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
