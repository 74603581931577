<ng-container *ngFor="let link of social_keys">
  <a
    (click)="globals.openUrl(social[link]['url'])"
    [name]="social[link]['name']"
    class="social-button"
    *ngIf="social[link]['url'] !== null"
  >
    <i class="fa {{ social[link]['icon'] }}"></i>
  </a>
</ng-container>
