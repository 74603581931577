<div class="page-headline">
  <h1>{{ title }}</h1>
</div>

<em [innerHtml]="description" *ngIf="description != null"></em>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"></app-swiper>
    <app-basket-widget [cart]="cart"></app-basket-widget>
    <app-widget-advert></app-widget-advert>
  </div>

  <div class="container-left">
    <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>
    <ng-container *ngIf="business && category">
      <h4 *ngIf="category.extra_charge > 0">
        {{ 'shopping_card.extra_charge' | translate }}:
        {{ category.extra_charge | currency: business.currency }}
      </h4>
      <em
        *ngIf="category.extra_charge_description != null"
        [innerHtml]="category.extra_charge_description"
      ></em>
    </ng-container>

    <div class="top-btn-container clearfix" *ngIf="module">
      <a
        translate
        [routerLink]="['/g', globals.getCode(), 'menu_category', 'daily']"
        class="button top-btn"
        *ngIf="!category && module.settings.daily_menu"
      >
        <i class="fas fa-file-alt"></i>
        categories.daymenu
      </a>
      <a
        [routerLink]="['/g', globals.getCode(), 'basket']"
        class="button top-btn"
        *ngIf="cart?.items?.length && module.orderable"
      >
        <i class="fa {{ module.settings.basket_icon }}"></i>
        {{ module.settings.basket_name }}
      </a>
    </div>

    <div class="searchbox" *ngIf="module?.search && (categories || entries)">
      <input
        type="search"
        [(ngModel)]="searchText"
        placeholder="{{ 'misc.searchenter' | translate }}"
      />
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <div class="menu" *ngIf="suggestions">
      <a [routerLink]="['/g', globals.getCode(), 'menu_category', 'smart']">
        <div translate>smart_weather.suggestion</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
    <div class="menu" *ngIf="categories?.length">
      <a
        *ngFor="let item of categories | filter: searchText"
        [routerLink]="['/g', globals.getCode(), 'menu_category', item.category.id]"
      >
        <div>{{ item.category.name }}</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
    <div class="menu entries" *ngIf="business && entries?.length">
      <div class="head" *ngIf="!searchText">
        <div translate>shopping_card.name</div>
        <div translate *ngIf="category && category.show_price">shopping_card.price</div>
        <div *ngIf="!category || (category && !category.show_price)"></div>
        <div></div>
      </div>
      <a
        *ngFor="let item of entries | filter: searchText"
        [routerLink]="['/g', globals.getCode(), 'menu', item.entry.id]"
      >
        <div class="two-lines">
          {{ item.entry.name }}
          <small
            *ngIf="item.entry.description"
            [innerHtml]="item.entry.description | stripTags"
          ></small>
        </div>
        <div *ngIf="!item.entry.price_hidden">
          {{ item.entry.price | currency: business.currency }}
        </div>
        <div *ngIf="item.entry.price_hidden"></div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
  </div>
</div>

<app-backlink [visibleMobile]="true" *ngIf="category"></app-backlink>
