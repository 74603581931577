<div class="item" (click)="onSelectAddress()">
  <div class="recipient-container">
    <straiv-section-header
      size="small"
      [heading]="mainAddress"
      [description]="fullAddress"
    ></straiv-section-header>
  </div>

  <div *ngIf="changeable" class="action-icons">
    <i class="fas fa-trash remove-icon" (click)="onDeleteClick($event)" title="Delete"></i>
    <i class="fas fa-edit edit-icon" (click)="onEditClick($event)" title="Edit"></i>
  </div>
</div>
