import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoPreviewModule } from '../photo-preview/photo-preview.module';
import { CameraButtonComponent } from './camera-button/camera-button.component';
import { PassportFormComponent } from './passport-form.component';
import { TypographyModule } from 'common/typography/typography.module';
import { PassportPhotoModule } from '../passport-photo/passport-photo.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '../datepicker/datepicker.module';
import { ValidationMessagePipeModule } from '../../../../pipes/validation-message.pipe.module';
import { DateValidatorModule } from 'validators/date.validator.module';
import { ValueAccessorModule } from 'app/value-accessors/value-accessor-module';

@NgModule({
  declarations: [PassportFormComponent, CameraButtonComponent],
  imports: [
    CommonModule,
    TypographyModule,
    PhotoPreviewModule,
    PassportPhotoModule,
    FormsModule,
    TranslateModule,
    DatePickerModule,
    ValidationMessagePipeModule,
    DateValidatorModule,
    ValueAccessorModule,
  ],
  exports: [PassportFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PassportFormModule {}
