import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestIndexRoutingModule } from './guest-index-routing.module';
import { GuestIndexComponent } from './guest-index.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GuestIndexComponent],
  exports: [GuestIndexComponent],
  imports: [CommonModule, GuestIndexRoutingModule, TranslateModule],
})
export class GuestIndexModule {}
