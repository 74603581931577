import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { BusinessGroupService } from 'services/business_group.service';
import { Globals } from 'base';

@Component({
  selector: 'app-module-group-map',
  templateUrl: './map.component.html',
})
export class GroupMapComponent implements OnInit {
  module: Module;
  entries: Array<any>;

  constructor(
    private businessGroupService: BusinessGroupService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.globals.getModule('group_portfolio').then((mod) => {
      this.module = mod;
      this.businessGroupService.getMap().subscribe((success: any) => {
        this.entries = success.businesses;
      });
    });
  }
}
