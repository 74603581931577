import { PmsProcess } from 'pms_enums';

export class Action {
  connection: PmsProcess;
  name: string;
  icon: string;

  constructor(raw_json: any) {
    this.connection = PmsProcess[raw_json.connection];
    this.name = raw_json.name;
    this.icon = raw_json.icon;
  }

  public static getActionsFromJSON(raw_json: any[]): Action[] {
    return raw_json.map((option: any) => {
      const key: string = Object.keys(option)[0];
      const newKey = key.replace('pms_', '');
      return new Action({ connection: newKey, name: option[key], icon: option['icon'] });
    });
  }
}
