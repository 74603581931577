import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CameraComponent } from './camera.component';

@NgModule({
  declarations: [CameraComponent],
  imports: [CommonModule, TranslateModule, ZXingScannerModule],
  exports: [CameraComponent],
})
export class CameraModule {}
