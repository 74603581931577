import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  constructor(private api: ApiService) {}

  toggleSidebar() {
    const sidebar = document.getElementById('sidebar');
    const activate = document.getElementById('activate');
    if (sidebar.classList.contains('removed')) {
      activate.classList.remove('in');
      sidebar.classList.remove('removed');
    } else {
      activate.classList.add('in');
      sidebar.classList.add('removed');
    }
  }

  getWidgets(): Observable<Object> {
    return this.api.get('widgets');
  }

  moduleWidget(widget): Observable<Object> {
    return this.api.silentPost('widgets', { widget: widget });
  }
}
