import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PmsOverlayRoutingModule } from './overlay-routing.module';
import { PmsOverlayComponent } from './overlay.component';
import { PmsSignatureModule } from '../signature/signature.module';

@NgModule({
  imports: [CommonModule, PmsOverlayRoutingModule, TranslateModule, PmsSignatureModule],
  exports: [PmsOverlayComponent],
  declarations: [PmsOverlayComponent],
})
export class PmsOverlayModule {}
