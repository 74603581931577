import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { MapProtection } from 'models/map_protection';
import { GuestService } from 'services/guest.service';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap) googleMap: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() fitBounds: any;
  @Input() boundType: string;
  @Input() zoom = 15;
  @Input() background: string = null;

  subscriptions: Subscription = new Subscription();
  business: Business;

  options: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    scrollwheel: false,
    zoom: this.zoom,
  };
  center: google.maps.LatLngLiteral;
  markers = [];
  activeMarker: any;

  large: boolean;
  unprotected: boolean;
  mapProtection: MapProtection;

  constructor(
    private guestService: GuestService,
    private businessService: BusinessService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.protection();
  }

  standardMap() {
    this.center = { lat: this.latitude, lng: this.longitude };
    this.markers.push({ position: this.center });
  }

  boundMap() {
    this.large = true;
    const bounds = new google.maps.LatLngBounds();
    for (const entry of this.fitBounds) {
      const point: google.maps.LatLng = new google.maps.LatLng(
        entry.coordinates.lat,
        entry.coordinates.lng,
      );
      const options = entry.media?.icon?.length
        ? { position: point, options: { icon: entry.media?.icon } }
        : { position: point };
      bounds.extend(point);
      this.markers.push({ ...entry, ...options });
    }
    this.googleMap.fitBounds(bounds);
  }

  protection() {
    this.businessService.current_business
      .pipe(filter(Boolean), take(1))
      .subscribe((business: Business) => {
        this.subscriptions.add(
          this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
            this.mapProtection = new MapProtection({
              cookie: !guest.cookies.analytics,
              setting: business.maps_protected || false,
              background: this.background,
            });
            if (this.mapProtection.unprotected()) {
              this.loadMap();
            }
          }),
        );
      });
  }

  loadMap() {
    if (this.fitBounds) {
      this.unprotected = true;
    }
    setTimeout(() => {
      this.fitBounds ? this.boundMap() : this.standardMap();
      this.unprotected = true;
    }, 0);
  }

  openInfoWindow(markerElem: MapMarker, marker: any) {
    if (this.fitBounds) {
      this.activeMarker = marker;
      setTimeout(() => {
        this.infoWindow.open(markerElem);
      }, 0);
    }
  }

  openUrl(url: string) {
    this.globals.openUrl(url);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
