import { Component, OnDestroy } from '@angular/core';
import { Guest } from 'models/guest';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';

@Component({
  selector: 'app-internet-access',
  templateUrl: './internet_access.component.html',
  styleUrls: ['internet_access.component.scss'],
})
export class InternetAccessComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  guest: Guest;

  test_url = 'https://cdn.jsdelivr.net/npm/offline-js@0.7.19/offline.min.js';
  iacbox_url =
    'https://hotspot.internet-for-guests.com/logon/cgi/index.cgi?freeperperiod=1&accept_termsofuse=1';
  digmedia_url = 'http://digmedia.hotspot/login?username=notsosecret&password=notsosecret&dst=';

  network: string;
  online = true;
  url = '#';

  constructor(private guestService: GuestService) {
    this.subscriptions.add(
      this.guestService.currentGuest.subscribe((guest: Guest) => {
        if (guest?.place) {
          this.guest = guest;
          this.network = guest.place.network;
          this.internet_connection()
            .then(() => {
              this.online = true;
            })
            .catch(() => {
              this.online = false;
              if (this.network === 'digmedia') {
                this.url = this.digmedia_url + location.origin + '/' + guest.place.cryptcode;
              }
            });
        }
      }),
    );
  }

  connect(event) {
    if (this.network !== 'iacbox') {
      return;
    }

    event.preventDefault();
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', this.iacbox_url);
    iframe.id = 'iacbox';
    document.body.appendChild(iframe);

    // Test Internet again in 5 seconds
    of(true)
      .pipe(delay(5000))
      .subscribe(() => {
        this.internet_connection()
          .then(() => {
            this.online = true;
            iframe.remove();
          })
          .catch(() => {
            this.online = false;
            iframe.remove();
          });
      });
  }

  internet_connection() {
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        resolve(false);
      };
      xhr.onerror = () => {
        reject(false);
      };
      xhr.open('HEAD', this.test_url, true);
      xhr.send();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
