<ng-container *ngIf="item">
  <div class="page-headline" (swipeleft)="swipe($event)" (swiperight)="swipe($event)">
    <h2>
      <i class="ad-icon" *ngIf="item.advert"></i>
      {{ item.title }}
    </h2>
  </div>

  <em (swipeleft)="swipe($event)" (swiperight)="swipe($event)">{{ item.description }}</em>

  <div class="container" (swipeleft)="swipe($event)" (swiperight)="swipe($event)">
    <div class="container-right" *ngIf="showRight">
      <app-swiper [items]="item.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>

    <div class="container-left">
      <div [innerHtml]="item.content"></div>

      <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>
    </div>
  </div>

  <app-element-navigation [element]="item"></app-element-navigation>
</ng-container>
