import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Module } from 'models/module';

@Component({
  selector: 'app-module-title',
  templateUrl: './module_title.component.html',
})
export class ModuleTitleComponent implements OnChanges {
  @Input() module: Module;
  @Input() hideDescription = false;
  description: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    const curr = changes.module.currentValue;
    const desc = curr ? curr.settings?.question || curr.description : null;
    if (desc && !this.hideDescription) {
      this.description = this.sanitizer.bypassSecurityTrustHtml(desc);
    }
  }
}
