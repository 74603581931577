<div class="content" *ngIf="data">
  <ng-container *ngIf="!confirmation">
    <app-loader
      *ngIf="processes.length && !cicoService.infoScreen"
      [text]="'misc.wait' | translate"
      [small]="true"
      [included]="true"
    ></app-loader>
  </ng-container>

  <ng-container *ngIf="cicoService.infoScreen">
    <div class="info-screen-container">
      <straiv-confirm [type]="confirmType" [htmlContent]="confirmation?.text"></straiv-confirm>
    </div>
  </ng-container>

  <straiv-reservation-card
    *ngIf="!processSubmitted || checkInFinished"
    is-upgrade-card="false"
    min-height="185px"
    size="medium"
    selectable="false"
    [imageUrl]="reservationCardData.media"
    [extrasHeading]="reservationCardData.extras.heading"
    [extras]="reservationCardData.extras.data"
    [heading]="reservationCardData.heading"
    [description]="reservationCardData.description"
    [viewDetailsButtonText]="'booking_data.transportation.viewDetails' | translate"
    [arrivalDate]="reservationCardData.dates.arrival"
    [departureDate]="reservationCardData.dates.departure"
    [checkInTime]="reservationCardData.times.checkInTime"
    [checkOutTime]="reservationCardData.times.checkOutTime"
  ></straiv-reservation-card>

  <ng-container *ngIf="!processSubmitted">
    <form #form="ngForm">
      <div class="overview-form-container">
        <straiv-signature-box
          *ngIf="showSignature && fields.signature"
          ngDefaultControl
          [heading]="fields.signature.name"
          name="signature"
          [(ngModel)]="mappedFormFieldsValues.signature.value"
          [subHeading]="fields.signature.description"
          [clearButtonText]="'service.check_in.signature.clear' | translate"
          clear-button-icon="fa fa-remove"
          [description]="'service.check_in.signature.description' | translate"
          [required]="fields.signature.required"
          [submitted]="submitted"
          [errorMessage]="'validation.required' | translate"
          (endStroke)="updateSignature($event)"
        ></straiv-signature-box>

        <straiv-checkbox
          *ngIf="fields.preferences"
          ngDefaultControl
          name="preferences"
          [(ngModel)]="mappedFormFieldsValues.preferences.value"
          [labelText]="fields.preferences.name"
          [helperText]="fields.preferences.description"
          [required]="fields.preferences.required"
          [errorMessage]="'validation.required' | translate"
          [submitted]="submitted"
        ></straiv-checkbox>

        <straiv-checkbox
          *ngIf="fields.marketing"
          ngDefaultControl
          name="marketing"
          [(ngModel)]="mappedFormFieldsValues.marketing.value"
          [labelText]="fields.marketing.name"
          [helperText]="fields.marketing.description"
          [required]="fields.marketing.required"
          [errorMessage]="'validation.required' | translate"
          [submitted]="submitted"
        ></straiv-checkbox>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="doorStep.length">
    <ng-container *ngFor="let step of doorStep">
      <ng-container *ngIf="step === 'pin_codes'">
        <straiv-section-header
          [heading]="'pms_door.pin_codes.heading' | translate"
          [description]="'pms_door.pin_codes.info' | translate"
        ></straiv-section-header>
        <app-pms-ci-pin-codes [id]="data?.incident?.reservation?.id"></app-pms-ci-pin-codes>
      </ng-container>
      <app-pms-encoder
        id="card_encoder"
        #encoder
        *ngIf="step === 'cards'"
        [confirmation]="confirmation"
        [encoderData]="cicoService.encoderData"
        (finishClicked)="endProcess()"
      ></app-pms-encoder>
    </ng-container>
  </ng-container>

  <div
    class="btn-grid"
    *ngIf="!processes.length || (confirmation && !doorStepWithCards)"
    [class.spacing]="cicoService.infoScreen"
  >
    <ng-container *ngIf="confirmation && !doorStepWithCards">
      <ng-container
        *ngIf="data.module.type === ciCoType.ci; then ci_btns; else co_btns"
      ></ng-container>
    </ng-container>
  </div>

  <div class="legal" *ngIf="!sending && !processes.length">
    <ng-container *ngIf="data.module.type === ciCoType.ci && legalInfo?.length">
      <app-form-legal *ngFor="let item of legalInfo" [text]="item.type"></app-form-legal>
    </ng-container>
    <app-form-legal [text]="'privacy_policy'" *ngIf="legal && !legal.terms"></app-form-legal>
    <app-form-legal [text]="'terms'" *ngIf="legal?.terms"></app-form-legal>
  </div>

  <ng-template #ci_btns>
    <straiv-button
      name="finish-ci-button"
      [variant]="variantDoneButton"
      size="lg"
      [leftLabel]="doneButton"
      (buttonClick)="endProcess()"
    ></straiv-button>

    <straiv-button
      *ngIf="!checkInFinished && data.incident.prevention != 'invalid_time'"
      name="not-complete-button"
      variant="flat"
      size="lg"
      [leftLabel]="notCompleteButtonLabels[processText] | translate"
      (buttonClick)="handleNotCompletebtn()"
    ></straiv-button>
  </ng-template>

  <ng-template #co_btns>
    <straiv-button
      name="finish-co-button"
      variant="flat"
      size="lg"
      [leftLabel]="doneButton"
      (buttonClick)="endProcess()"
    ></straiv-button>
  </ng-template>
</div>
