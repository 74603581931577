import * as moment from 'moment';
import { PmsModType } from '../../_enums/pms/enums';
import { Address } from '../address';
import { GenericData } from './generic_data';
import { PmsFolio } from './pms_folio';
import { PmsGuest } from './pms_guest';

export class PmsReservation {
  id: number;
  uuid: string;
  reservation_id: string;
  booking_id: string;
  arrival: string;
  departure: string;
  total_price: string;
  travel_purpose: string;
  persons: number;
  adults: number;
  children: number;
  marketing: boolean;
  car_licence: string;
  can_check_in: boolean;
  can_check_out: boolean;
  signature: string;
  passport: string;
  should_sign: boolean;
  skipable: boolean;
  nights: number;
  primary_guest: PmsGuest;
  related_guests: Array<PmsGuest> = new Array<PmsGuest>();
  children_guests: Array<PmsGuest> = new Array<PmsGuest>();
  credit_card: Object;
  address: Address;
  folios: Array<PmsFolio> = new Array<PmsFolio>();
  allFolios: Array<PmsFolio> = new Array<PmsFolio>();
  field_values: Array<any>;
  question: any;
  action: any;
  helper: any;
  in_time: boolean;
  pre_auth: any;
  payment_providers: any;
  payment: string;
  payment_token: string;
  authenticate: boolean;
  authenticated: boolean;
  auth_url: string;
  door_codes: any;
  can_encode: boolean;
  already_encoded: boolean;
  ui_messages: any;
  medias: any;

  // for reservation module
  all_reservations: Array<any>;
  room: string;
  room_description: string;
  unit_name: string;
  unit_description: string;
  cancel_name: string;
  cancel_description: string;
  booking_data: any;

  unit: Unit;
  reception: Reception;
  services: string;

  constructor(raw_json?: any) {
    this.id = raw_json?.id;
    this.uuid = raw_json?.uuid;
    this.reservation_id = raw_json?.reservation_id;
    this.booking_id = raw_json?.booking_id;
    this.arrival = raw_json?.arrival;
    this.departure = raw_json?.departure;
    this.total_price = raw_json?.total_price;
    this.room = raw_json?.room;
    this.travel_purpose = raw_json?.travel_purpose || null;
    this.adults = raw_json?.adults || 0;
    this.persons = raw_json?.persons || 0;
    this.children = raw_json?.children || 0;
    this.marketing = raw_json?.marketing || false;
    this.car_licence = raw_json?.car_licence;
    this.can_check_in = raw_json?.can_check_in || false;
    this.can_check_out = raw_json?.can_check_out || false;
    this.credit_card = raw_json?.credit_card || {};
    this.signature = raw_json?.signature || undefined;
    this.passport = raw_json?.passport || undefined;
    this.should_sign = raw_json?.should_sign;
    this.skipable = raw_json?.skipable;
    this.nights = raw_json?.nights;
    this.address = new Address({ ...(raw_json?.address || {}), ...{ manual: true } });
    this.field_values = raw_json?.field_values || [];
    this.question = raw_json?.question || [];
    this.action = raw_json?.action || [];
    this.in_time = raw_json?.in_time || false;
    this.payment_providers = raw_json?.payment_providers;
    this.pre_auth = raw_json?.pre_auth;
    this.payment = raw_json?.payment;
    this.payment_token = raw_json?.payment_token;
    this.authenticated = raw_json?.authenticated;
    this.authenticate = raw_json?.authenticate;
    this.auth_url = raw_json?.auth_url;
    this.door_codes = raw_json?.door_codes;
    this.can_encode = raw_json?.can_encode || false;
    this.already_encoded = raw_json?.already_encoded || false;
    this.ui_messages = raw_json?.ui_messages;
    this.medias = raw_json?.medias;

    this.all_reservations = raw_json?.all_reservations;
    this.booking_data = raw_json?.booking_data;
    this.room_description = raw_json?.room_description;
    this.unit_name = raw_json?.unit_name;
    this.unit_description = raw_json?.unit_description;
    this.cancel_name = raw_json?.cancel_name;
    this.cancel_description = raw_json?.cancel_description;

    this.unit = raw_json?.unit;
    this.reception = raw_json?.reception;
    this.services =
      (Array.isArray(raw_json?.services) ? raw_json.services.join(', ') : raw_json.services) || '';
    this.primary_guest = raw_json?.primary_guest
      ? new PmsGuest(raw_json.primary_guest)
      : new PmsGuest({ address: new Address(null) });

    const related = raw_json?.related_guests || raw_json?.guests || [];
    for (let guest of related) {
      guest.group = 'adult_fellows';
      this.related_guests.push(new PmsGuest(guest));
    }

    const children_guests = raw_json?.children_guests || [];
    for (let guest of children_guests) {
      guest.group = 'children_fellows';
      this.children_guests.push(new PmsGuest(guest));
    }
  }

  fellows(): boolean {
    return this.related_guests.length + this.children_guests.length > 0;
  }

  guests(): PmsGuest[] {
    return [this.primary_guest].concat(this.related_guests);
  }

  allGuests(): PmsGuest[] {
    return this.guests().concat(this.children_guests);
  }

  guestsCount(): number {
    return this.adults + this.children + 1;
  }

  nightsCount(): number {
    // fallback 1 in case of day use
    return this.nights || 1;
  }

  cusInfos(): CusInfo {
    return { unitName: this.unit_name, arrival: this.arrival, departure: this.departure };
  }

  duplicateIndex(guest: PmsGuest, property: string, value: string): number {
    const allGuests = this.allGuests();
    const guestIndex = allGuests.indexOf(guest);

    return allGuests.findIndex(
      (otherGuest, index) =>
        index !== guestIndex &&
        otherGuest[property] &&
        guest[property] &&
        otherGuest[property] === value,
    );
  }

  signedBySca(): boolean {
    return this.allFolios.filter((folio) => folio.sca_valid).length > 0;
  }

  idnow(): boolean {
    return !this.authenticated && this.authenticate;
  }

  beforeArrival(): boolean {
    return moment().isBefore(moment(this.arrival), 'day');
  }

  updateUnit(unit: Unit, medias: any, services: string[]) {
    this.unit = unit;
    this.unit_description = unit.description;
    this.unit_name = unit.name;
    this.medias = medias;
    this.services = services?.join(', ') || '';
  }

  foliosPaid(data: GenericData): boolean {
    const foliosLoaded = data.folioInfo?.loaded?.value || data.incident.checks?.loaded_folios;
    if (data.module.type === PmsModType.co) {
      return foliosLoaded && this.allFolios?.every((folio) => folio.paid(true));
    } else {
      const payRequired = this.folios.some((folio) => folio.paymentRequired());
      const isPaid = this.folios?.every((folio) => folio.paid());
      const isAuthorized =
        (!data?.folioInfo?.error && this.folios?.every((folio) => folio.authorized())) ||
        data.preAuth?.fullyAuthorized;

      if (
        data.module?.settings?.required_payments &&
        data.folioInfo.error &&
        !data.module.settings?.allow_folio_error
      ) {
        return false;
      } else {
        return foliosLoaded && (!payRequired || isPaid || isAuthorized);
      }
    }
  }
}

export interface Unit {
  name: string;
  description: string;
}

interface Reception {
  check_in_from: string;
  check_in_till: string;
  check_out_from: string;
  check_out_till: string;
}

export interface CusInfo {
  unitName: string;
  arrival: string;
  departure: string;
}
