import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from '../layout/attachments/attachments.module';
import { BacklinkModule } from '../layout/backlink/backlink.module';
import { SharedSwiperModule } from '../layout/swiper/swiper.module';
import { ArticleRoutingModule } from './article-routing.module';
import { ArticleComponent } from './article.component';

@NgModule({
  imports: [
    CommonModule,
    ArticleRoutingModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    AttachmentsModule,
    BacklinkModule,
    TranslateModule,
  ],
  exports: [ArticleComponent],
  declarations: [ArticleComponent],
})
export class ArticleModule {}
