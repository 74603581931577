import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { PmsCreditCardRoutingModule } from './credit_card-routing.module';
import { PmsCreditCardComponent } from './credit_card.component';

@NgModule({
  imports: [CommonModule, PmsCreditCardRoutingModule, TranslateModule, LoaderModule],
  exports: [PmsCreditCardComponent],
  declarations: [PmsCreditCardComponent],
})
export class PmsCreditCardModule {}
