import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatesRoutingModule } from './dates-routing.module';
import { DatesComponent } from './dates.component';

@NgModule({
  imports: [CommonModule, DatesRoutingModule, FormsModule, TranslateModule],
  exports: [DatesComponent],
  declarations: [DatesComponent],
})
export class DatesModule {}
