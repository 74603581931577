import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'api_service';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { PmsReservation } from 'pms_models/pms_reservation';
import { PmsService } from 'pms_service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pms-door-pin-codes',
  templateUrl: './pin_codes.component.html',
  styleUrls: ['./pin_codes.component.scss'],
})
export class PmsDoorPinCodesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  reservation: PmsReservation;
  doors: Array<any> = [];
  names: any;
  error: any;
  headline: boolean;
  sag: boolean;

  @Input() searchText: string;
  @Input() business: Business;
  @Input() module: Module;

  constructor(
    private pmsService: PmsService,
    private api: ApiService,
  ) {}

  ngOnInit() {
    this.sag = this.business.tech.door_system.type === 'sag';
    this.headline = this.module.settings.modes.length > 1;
    this.names = {
      start_code: `pms_door.pin_codes.${this.sag ? 'start_code' : 'title'}`,
      comfort_code: 'pms_door.pin_codes.comfort_code',
    };

    this.pmsService.getReservationInfo().subscribe((success: any) => {
      this.reservation = new PmsReservation(success.reservation);
      this.getDoors();
    });
  }

  getDoors() {
    this.api.get('door/list/pin_codes').subscribe(
      (response: any) => {
        response?.reservation?.doors
          .filter((door) => door.entries[0]?.start_code?.length)
          .forEach((group) => {
            group.entries.forEach((door: any) => {
              this.doors.push(door);
            });
          });
      },
      (error) => {
        this.doors = [];
        this.error = error.error;
      },
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
