import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { GroupPortfolioRoutingModule } from './group_portfolio-routing.module';
import { GroupPortfolioComponent } from './group_portfolio.component';

@NgModule({
  imports: [
    CommonModule,
    GroupPortfolioRoutingModule,
    FormsModule,
    TranslateModule,
    WidgetAdvertModule,
    SharedSwiperModule,
    ModuleTitleModule,
    FilterPipeModule,
  ],
  declarations: [GroupPortfolioComponent],
})
export class GroupPortfolioModule {}
