import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WishDirectModule } from './direct/direct.module';
import { WishListAllModule } from './list/all/all.module';
import { WishListParentModule } from './list/parent/parent.module';
import { WishRoutingModule } from './wish-routing.module';
import { WishComponent } from './wish.component';

@NgModule({
  imports: [
    CommonModule,
    WishRoutingModule,
    WishListAllModule,
    WishListParentModule,
    WishDirectModule,
    TranslateModule,
  ],
  exports: [WishComponent],
  declarations: [WishComponent],
})
export class WishModule {}
