import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { MenuModuleService } from '../../menu.service';

@Component({
  selector: 'app-menu-list-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss'],
})
export class MenuListAllComponent extends ServiceAllDirective implements OnInit, OnDestroy {
  mod = 'menu';
  cart: any;

  constructor(
    public globals: Globals,
    protected businessService: BusinessService,
    private menuService: MenuModuleService,
  ) {
    super(globals, businessService);
  }

  ngOnInit() {
    super.ngOnInit(this.menuService).then(() => {
      this.subscriptions.add(
        this.menuService.getBasket().subscribe(
          (basket: any) => {
            if (basket.cart?.items?.length) {
              this.cart = basket.cart;
              this.rightSide = true;
            }
          },
          () => {},
        ),
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
