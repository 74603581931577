import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PmsFooterRoutingModule } from './footer-routing.module';
import { PmsFooterComponent } from './footer.component';

@NgModule({
  imports: [CommonModule, PmsFooterRoutingModule, TranslateModule],
  exports: [PmsFooterComponent],
  declarations: [PmsFooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsFooterModule {}
