import { AnimationBuilder } from '@angular/animations';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { OverlayAction, ConfirmName, OverlayType, CusProductTypes } from 'pms_enums';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { GenericData } from 'models/pms/generic_data';
import { CiCoFooterBaseDirective } from '../../base/cico-footer-base-directive';
import { StepperService } from 'services/pms/stepper.service';
import { TravelInfoSubSteps } from 'models/pms/stepper';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';

@Component({
  selector: 'app-pms-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class PmsFooterComponent extends CiCoFooterBaseDirective implements OnInit, OnDestroy {
  data: GenericData;
  pagination: any;
  showOverlay = false;
  @Input() currentIndex: number;
  @Input() index: number;
  @Input() generalIndex: number;
  @Input() reservationIndex: number;
  @Input() confirmName: string;
  @Input() showBack: boolean;
  @Input() showNext: boolean = Boolean(false);
  @Input() disableNext: boolean;
  @Input() disableBack: boolean;
  @Input() nextDisplayText: string = String('Next');
  @Input() Icon: 'start' | 'refresh';
  @Input() idnow: boolean;

  @Output() nextClicked = new EventEmitter<void>();
  @Output() prevClicked = new EventEmitter<void>();

  constructor(
    public cicoService: PmsCiCoService,
    private _cdr: ChangeDetectorRef,
    public globals: Globals,
    protected animationBuilder: AnimationBuilder,
    public stepperService: StepperService,
    private eventService: EventAggregatorService,
  ) {
    super(globals, cicoService, animationBuilder);
    this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.add(
      this.cicoService.overlayOpen.subscribe((value) => {
        setTimeout(() => {
          this.showOverlay = value;
          this._cdr.detectChanges();
        });
      }),
    );

    // Subscribes to the 'overlayClose' event emitted by the cicoService. This event is fired when any overlay is closed.
    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe((event: any) => {
        // Check if the action associated with the overlay close events
        if (event.action === OverlayAction.addToFolios) {
          // If the closed overlay was related to adding cross and upsell to folios, proceed to the next step in the stepper.
          // This is typically triggered after a user confirms an action on a reservation upsell or cross-sell modal.
          this.stepperService.nextStep();
          this.cicoService.confirmNameSubj.next(ConfirmName.next);
        }
      }),
    );
  }

  /** Handles the 'previous' button click in a UI stepper component.*/
  onPreviousClick() {
    if (this.showOverlay) {
      this.cicoService.closeOverlay(OverlayAction.close);
      return;
    }
    this.stepperService.previousStep();
  }

  /** Handles the 'next' button click in a UI stepper component.*/
  onNextClick() {
    if (
      this.cicoService.getCurrentStepName() === TravelInfoSubSteps.reservation &&
      this.cicoService.cusRoomupgradeToBook
    ) {
      this.eventService.getEvent(EventConstants.openRoomUpgradeConfirmation).publish(true);
      return;
    }
    if (
      this.cicoService.getCurrentStepName() === TravelInfoSubSteps.cusBreakfast &&
      this.cicoService.cusBreakfastToBook
    ) {
      this.eventService.getEvent(EventConstants.openBreakfastBookingConfirmation).publish(true);
      return;
    }

    this.stepperService.nextStep();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
