import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupPortfolioEntryComponent } from '../entry/group_portfolio.component';
import { GroupMapComponent } from '../map/map.component';
import { GroupSocialComponent } from '../social/group_social.component';
import { GroupPortfolioComponent } from './group_portfolio.component';

const routes: Routes = [
  { path: '', component: GroupPortfolioComponent },
  { path: 'map', component: GroupMapComponent },
  { path: 'social', component: GroupSocialComponent },
  { path: ':id', component: GroupPortfolioEntryComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupPortfolioRoutingModule {}
