import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { Link } from 'models/link';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { ExternalLinkService } from './external/external.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
})
export class LinkComponent implements OnInit, OnDestroy {
  link: Link;
  iframe = false;
  subscriptions: Subscription = new Subscription();

  @Input() module: Module;
  @Input() showBack = true;

  constructor(
    public globals: Globals,
    private externalLinkService: ExternalLinkService,
  ) {}

  ngOnInit() {
    if (this.link === undefined) {
      if (this.module !== undefined) {
        if (this.module.pretty_mode === 'api') {
          this.link = this.module.module_api.link;
        } else {
          this.link = this.module.link;
        }
        this.openLink();
      } else {
        this.subscriptions.add(
          this.externalLinkService.linkChange.subscribe((link) => {
            this.link = link;
            this.openLink();
          }),
        );
      }
    } else {
      this.openLink();
    }
  }

  openLink() {
    this.globals.backSteps = 1;
    if (this.link && this.link.url_target === '_blank') {
      this.globals.openUrl(this.link.url, '_blank');
    } else {
      this.iframe = true;
    }
  }

  mouseEnter() {
    document.body.classList.add('scroll_lock');
  }

  mouseLeave() {
    document.body.classList.remove('scroll_lock');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
