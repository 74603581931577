<div class="info-component" *ngIf="!link; else show_pdf">
  <div class="close-icon" (click)="closeOverlay()"><i class="fas fa-times"></i></div>
  <h3 translate>{{ 'legal.' + kind }}</h3>
  <div [innerHtml]="legal.text"></div>
  <div class="menu files" *ngIf="legal.attachments?.length">
    <a *ngFor="let file of legal.attachments" (click)="openPdf(file.attachment)" class="link">
      <div><i class="far fa-file-pdf"></i></div>
      <div>{{ file.attachment.name }}</div>
      <div>
        <i class="fas fa-angle-right icon-next"></i>
      </div>
    </a>
  </div>
  <nav role="navigation" class="element-navigation">
    <div class="navigation-container back clearfix">
      <div class="nav-btn">
        <a translate (click)="closeOverlay()">footer.back</a>
      </div>
    </div>
  </nav>
</div>

<ng-template #show_pdf>
  <div
    class="iframe_container info-component"
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
  >
    <iframe [src]="link | safe"></iframe>
  </div>

  <nav role="navigation" class="element-navigation">
    <div class="navigation-container back clearfix">
      <div class="nav-btn">
        <a translate (click)="closePdf()">footer.back</a>
      </div>
    </div>
  </nav>
</ng-template>
