import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetGroupBusinessComponent } from './group_business.component';

const routes: Routes = [{ path: '', component: WidgetGroupBusinessComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WidgetGroupBusinessRoutingModule {}
