import { Component, OnInit, OnChanges, Input, KeyValueDiffers } from '@angular/core';
import { StepStates } from 'app/_enums/pms/enums';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { StepperService } from 'services/pms/stepper.service';

@Component({
  selector: 'app-steps-indicator',
  templateUrl: './steps-indicator.component.html',
  styleUrls: ['./steps-indicator.component.scss'],
})
export class StepsIndicatorComponent {
  @Input() now: number;

  states = StepStates;
  stepStates: StepStates[] = [];

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    public stepperService: StepperService,
  ) {}
}
