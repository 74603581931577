import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { MapProtectionModule } from 'shared/layout/map_protection/map_protection.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { MapModule } from '../shared/map/map.module';
import { VcardRoutingModule } from './vcard-routing.module';
import { VcardComponent } from './vcard.component';

@NgModule({
  imports: [
    CommonModule,
    VcardRoutingModule,
    SharedSwiperModule,
    ModuleTitleModule,
    WidgetAdvertModule,
    TranslateModule,
    WidgetSocialModule,
    MapProtectionModule,
    MapModule,
  ],
  exports: [VcardComponent],
  declarations: [VcardComponent],
})
export class VcardModule {}
