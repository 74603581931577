<straiv-section-header heading="{{ 'cus.upgrades' | translate }}"></straiv-section-header>

<div class="upsell-gallery-container">
  <ng-container *ngFor="let item of upsellItems">
    <straiv-reservation-card
      extrasHeading="{{ 'cus.included_extras' | translate }}"
      extras="{{ item.offer_services.join(',') }}"
      is-upgrade-card="true"
      size="medium"
      imageUrl="{{ item.medias[0].media.url }}"
      heading="{{ item.name }}"
      description="{{ item.description }}"
      min-height="185px"
      [roomAmount]="nightCount * item.difference | currency: item.currency"
      perNightCharge="{{ (item.difference | currency: item.currency) + ' per night' }}"
      selectable="true"
      autoToggleActiveState="{{ false }}"
      (cardClick)="selectItem(item)"
      chooseUpgradeButtonText="{{
        (item.unit_code !== cicoService.cusRoomupgradeToBook?.offer?.unit_code
          ? 'cus.choose_upgrade'
          : 'cus.remove_upgrade'
        ) | translate
      }}"
      [active]="item.unit_code === cicoService.cusRoomupgradeToBook?.offer?.unit_code"
      viewDetailsButtonText="{{ 'misc.more' | translate }}"
    ></straiv-reservation-card>
  </ng-container>
</div>

<div
  class="offers smaller-font-size"
  *ngIf="cicoService.cusRoomupgradeToBook?.product?.offer_services?.length"
>
  <p class="smaller-font-size" translate>cus.service_included</p>
  <ul>
    <li
      class="smaller-font-size"
      *ngFor="let service of cicoService.cusRoomupgradeToBook.product.offer_services"
    >
      <i class="fas fa-circle-check main-text"></i>
      {{ service }}
    </li>
  </ul>
</div>
