import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Module } from 'models/module';
import { Globals } from 'base';
import { WeatherService } from './weather.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  weathers: string;
  smart_weather: boolean;
  suggestions: boolean;
  module: Module;
  showFahrenheit: boolean;

  constructor(
    private weatherService: WeatherService,
    private globals: Globals,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.weatherService.showFahrenheit.subscribe((showFahrenheit) => {
        this.showFahrenheit = showFahrenheit;
      }),
    );
    this.globals
      .getModule('weather')
      .then((mod) => {
        this.module = mod;
        this.smart_weather = this.module.settings.smart_weather;
      })
      .catch(() => {});
    this.weatherService.getWeather().subscribe(
      (success: any) => {
        this.weathers = success.weather;
      },
      () => {},
    );
  }

  changeUnit(event: any) {
    this.weatherService.updateUnit(event.target.checked);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
