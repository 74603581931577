import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { EventListCompactRoutingModule } from './compact-routing.module';
import { EventListCompactComponent } from './compact.component';

@NgModule({
  imports: [
    CommonModule,
    EventListCompactRoutingModule,
    WidgetAdvertModule,
    SharedSwiperModule,
    ModuleTitleModule,
    FormsModule,
    TranslateModule,
    FilterPipeModule,
  ],
  exports: [EventListCompactComponent],
  declarations: [EventListCompactComponent],
})
export class EventListCompactModule {}
