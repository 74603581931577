import { Component, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OfflineService } from 'services/offline.service';
import { InitComponent } from 'common/init/init.component';
import { SearchComponent } from 'common/search/search.component';
import { SidebarService } from 'common/sidebar/sidebar.service';

@Component({
  selector: 'app-widget-search',
  templateUrl: './search.component.html',
  styleUrls: ['search.component.scss'],
})
export class WidgetSearchComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  offline: boolean;

  constructor(
    private resolver: ComponentFactoryResolver,
    private init: InitComponent,
    private sidebarService: SidebarService,
    private offlineService: OfflineService,
  ) {
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );
  }

  showSearch() {
    this.sidebarService.toggleSidebar();

    const factory = this.resolver.resolveComponentFactory(SearchComponent);
    const component = this.init.search.createComponent(factory);
    component.instance._ref = component;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
