import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GuestService } from '../guest.service';

@Injectable({ providedIn: 'root' })
export class JourneyService {
  private journeySubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  journey: Observable<any> = this.journeySubj.asObservable();

  constructor(private guestService: GuestService) {}

  setJourney(data: any) {
    this.guestService.setJourney(data);
    this.journeySubj.next(data.journey);
  }
}
