<footer>
  <app-form-legal *ngIf="showPrivacyPolicy" [text]="'privacy_policy'"></app-form-legal>
  <div class="btn-grid">
    <button
      *ngIf="showBack"
      type="button"
      class="btn-outline btn-prev-step main-text small-width"
      [disabled]="disableBack"
      (click)="onPrevClick()"
      translate
    >
      {{ backBtnText }}
    </button>
    <button
      *ngIf="showNext"
      type="submit"
      [id]="nextBtnId"
      class="btn-filled small-width"
      [disabled]="disableNext"
      (click)="onNextClick()"
      translate
    >
      {{ nextBtnText }}
    </button>
  </div>
</footer>
