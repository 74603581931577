<app-module-title [module]="module" *ngIf="module"></app-module-title>

<div class="container">
  <div class="container-left" *ngIf="confirmPage">
    <div [innerHTML]="confirmPage.body" *ngIf="confirmPage.body"></div>

    <app-widget-social *ngIf="showSocial"></app-widget-social>

    <ng-container *ngIf="confirmPage.newsletter">
      <hr />
      <h3>{{ confirmPage.newsletter.title }}</h3>
      <p [innerHtml]="confirmPage.newsletter.description"></p>
      <app-newsletter-form [class]="'flat'"></app-newsletter-form>
    </ng-container>
  </div>
</div>
