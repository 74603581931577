export class Style {
  title: string;
  text: string;
  background: string;
  theme: string;
  font: string;
  social_in_modal: boolean;
  adverts: boolean;
  chatbot: boolean;
  news: boolean;
  overview_message: string;
  css: string;
  image_swiper: string;
  image_trigger: number;

  constructor(raw_json: any) {
    this.title = raw_json?.title;
    this.text = raw_json?.text;
    this.background = raw_json?.background;
    this.theme = raw_json?.theme;
    this.font = raw_json?.font;
    this.social_in_modal = raw_json?.social_in_modal;
    this.adverts = raw_json?.adverts;
    this.chatbot = raw_json?.chatbot;
    this.news = raw_json?.news;
    this.overview_message = raw_json?.overview_message;
    this.css = raw_json?.css;
    this.image_swiper = raw_json?.image_swiper;
    this.image_trigger = Number(raw_json?.image_trigger ? raw_json?.image_trigger : '10000');
  }
}
