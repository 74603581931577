<div class="grid-container content">
  <app-pms-reservation
    [res]="reservation"
    [hideActions]="true"
    [hideLogout]="true"
  ></app-pms-reservation>
</div>
<app-pms-simple-footer
  *ngIf="cicoService.folioAddressStep === 'folio'"
  [showNext]="true"
  [nextBtnText]="'misc.finish'"
  (nextClicked)="back()"
></app-pms-simple-footer>
