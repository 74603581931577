<div *ngIf="!hide_name" class="form-box">
  <label translate>misc.name</label>
  <input
    type="text"
    #name="ngModel"
    [(ngModel)]="service.name"
    [required]="required"
    maxlength="100"
    name="name"
    placeholder="{{ 'misc.name' | translate }}"
    min="0"
    max="100"
    [lengthValidator]="{ min: 0, max: 100 }"
  />
  <label translate class="error" *ngIf="name.errors?.required">validation.name</label>
  <label class="error" *ngIf="name.errors?.LengthValidator">
    {{ 'validation.maxLength' | translate: { length: 100 } }}
  </label>
</div>

<ng-container *ngIf="isNotPreStay">
  <div *ngIf="isNotAllRooms; else room_list" class="form-box">
    <label translate>{{ translationString }}</label>
    <input
      #place="ngModel"
      [(ngModel)]="service.place"
      [required]="required"
      min="0"
      max="254"
      [lengthValidator]="{ min: 0, max: 254 }"
      name="place"
      placeholder="{{ translationString | translate }}"
      placevalidator
      type="text"
    />
    <label translate class="error" *ngIf="place.errors?.required">validation.required</label>
    <label class="error" *ngIf="place.errors?.LengthValidator">
      {{ 'validation.maxLength' | translate: { length: 254 } }}
    </label>
    <label class="error" *ngIf="!place.errors?.placevalidator?.valid && service?.place">
      {{ 'validation.wrongRoomName' | translate }}
    </label>
  </div>
</ng-container>

<ng-template #room_list>
  <app-form-select
    *ngIf="place_list?.length"
    [errorLabel]="'service.place_not_found'"
    [label]="'service.all_rooms'"
    [model]="service"
    [name]="'place'"
    [options]="place_list"
    [required]="required"
  ></app-form-select>
</ng-template>
