import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { Step } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { Globals } from 'base';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { PmsCiCoService } from 'cico_service';
import { PmsService } from 'pms_service';
import { StepperService } from 'services/pms/stepper.service';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { ReservationCard } from 'models/reservation_card';

@Component({
  selector: 'app-pms-co-general',
  templateUrl: './general.component.html',
})
export class PmsCoGeneralComponent extends PmsBaseDirective implements OnInit, AfterViewInit {
  questions: any[];
  fields: boolean;
  reservationCardData: ReservationCard;

  @Input() submited: boolean;

  constructor(
    public globals: Globals,
    public pmsService: PmsService,
    protected cicoService: PmsCiCoService,
    protected stepperService: StepperService,
    protected readonly eventService: EventAggregatorService,
    protected route: ActivatedRoute,
  ) {
    super(cicoService, Step.guests, route, stepperService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.start();

    this.cicoService.hideBackButton(true);
    this.cicoService.setShowFooter(true);

    // When a form is submitted, set the 'submitted' flag to true. this is for showing the validation satate of the input.
    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.afterSubmitForm).subscribe(() => {
        this.globals.triggerBlurEvents(this.form);
      }),
    );
  }

  ngAfterViewInit() {
    // Move to the next step after payment.
    if (this.cicoService.autoSkipUntilStep === Step.invoice) {
      this.autoNextProcess(Step.reservation);
    }
  }

  protected fetchData() {
    this.subscriptions.add(
      this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        this.data = data;
        this.invoiceAddressPosition();
        this.reservationCardData = this.cicoService.setReservationCardInfo(this.data);
      }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
