<div
  *ngIf="init && redesign"
  class="fields-container"
  [class.one-row-arrival-departure]="oneRowArrivalDeparture"
>
  <ng-container *ngFor="let field of fields">
    <ng-container *ngIf="mappedFormFieldsValues[field.id]" [ngSwitch]="field.kind">
      <straiv-datepicker
        *ngSwitchCase="'date'"
        [name]="'date_' + field.id"
        [id]="'date_' + field.id"
        [class]="'field_' + field.identifier"
        icon="fas fa-calendar"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [required]="field.required"
        [labelText]="field.name"
        [helperText]="field.description"
        [locale]="locale"
        [localeLng]="locale"
        #datepicker="ngModel"
        [errorMessage]="
          datepicker.touched ? (datepicker.errors | validationMessage | translate) : ''
        "
        [datevalidator]="{
          required: field.required,
          locale,
          validation: field.validation
        }"
        [ngModelOptions]="{ updateOn: 'blur' }"
      ></straiv-datepicker>

      <straiv-textarea
        *ngSwitchCase="'textarea'"
        ngDefaultControl
        [name]="'textarea_' + field.id"
        id="{{ 'textarea_' + field.id }}"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [placeholder]="field.name"
        [helperText]="field.description"
        [required]="field.required"
        [errorMessage]="'validation.required' | translate"
        [submitted]="submited"
      ></straiv-textarea>

      <straiv-radio-group
        *ngSwitchCase="'polar'"
        ngDefaultControl
        [name]="'polar_' + field.id"
        id="{{ 'polar_' + field.id }}"
        [labelText]="field.name"
        orientation="vertical"
        [value]="mappedFormFieldsValues[field.id].value ? 'yes' : 'no'"
        (valueChange)="polarType($event.detail, field.id)"
        [helperText]="field.description"
        [options]="[
          { label: 'misc.no_string' | translate, value: 'no' },
          { label: 'misc.yes_string' | translate, value: 'yes' }
        ]"
        [required]="field.required"
        [errorMessage]="'validation.required' | translate"
        [submitted]="submited"
      ></straiv-radio-group>

      <straiv-checkbox
        *ngSwitchCase="'checkbox'"
        ngDefaultControl
        [name]="'check_field_' + field.id"
        id="{{ 'check_field_' + field.id }}"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [helperText]="field.description"
        [required]="field.required"
        [errorMessage]="'validation.required' | translate"
        [submitted]="submited"
      ></straiv-checkbox>

      <straiv-input
        *ngSwitchCase="'email'"
        ngDefaultControl
        #dynamicInput="ngModel"
        [name]="'text_' + field.id"
        id="{{ 'text_' + field.id }}"
        [type]="'email'"
        [required]="field.required"
        customEmailValidator
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [helperText]="field.description"
        [placeholder]="field.name"
        [errorMessage]="dynamicInput.errors | validationMessage | translate"
      ></straiv-input>

      <straiv-input
        *ngSwitchCase="'phone'"
        ngDefaultControl
        [name]="'text_' + field.id"
        id="{{ 'text_' + field.id }}"
        [type]="'phone'"
        [required]="field.required"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [helperText]="field.description"
        [placeholder]="field.name"
        [errorMessage]="'validation.required' | translate"
      ></straiv-input>

      <ng-container *ngSwitchCase="'payment'">
        <straiv-select
          *ngIf="field.options"
          ngDefaultControl
          [name]="'payment_' + field.id"
          id="{{ 'payment_' + field.id }}"
          type="payment"
          combined="false"
          [placeholder]="field.name"
          [value]="mappedFormFieldsValues[field.id].value"
          [items]="field.options | json"
          [helperText]="field.description"
          [(ngModel)]="mappedFormFieldsValues[field.id].value"
          (selectedEvent)="changeSelPaymentMethod($event?.detail?.selectedValue, field.id)"
          [required]="field.required"
          [labelText]="field.name"
          [errorMessage]="'validation.required' | translate"
          [submitted]="submited"
        ></straiv-select>
      </ng-container>

      <straiv-input
        *ngSwitchCase="'time'"
        ngDefaultControl
        [name]="'time_' + field.id"
        id="{{ 'time_' + field.id }}"
        [type]="'time'"
        [required]="field.required"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [helperText]="field.description"
        [placeholder]="field.name"
        [errorMessage]="'validation.required' | translate"
      ></straiv-input>

      <straiv-input
        *ngSwitchCase="'number'"
        [name]="'text_' + field.id"
        id="{{ 'text_' + field.id }}"
        type="number"
        [required]="field.required"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [helperText]="field.description"
        [placeholder]="field.name"
        [errorMessage]="'validation.required' | translate"
      ></straiv-input>

      <straiv-input
        *ngSwitchDefault
        ngDefaultControl
        [name]="'text_' + field.id"
        id="{{ 'text_' + field.id }}"
        [type]="field.kind"
        [required]="field.required"
        [(ngModel)]="mappedFormFieldsValues[field.id].value"
        [labelText]="field.name"
        [helperText]="field.description"
        [placeholder]="field.name"
        [errorMessage]="'validation.required' | translate"
      ></straiv-input>
    </ng-container>
  </ng-container>
</div>
<!-- TODO: need to be checked if it should be removed or not -->
<ng-container *ngIf="init && !redesign">
  <div class="form-box" *ngFor="let field of fields">
    <ng-container *ngIf="modelFor(field.id)">
      <label *ngIf="field.kind !== 'checkbox'">{{ field.name }}</label>
      <small translate *ngIf="field.kind !== 'checkbox' && field.description?.length">
        {{ field.description }}
      </small>
      <ng-container [ngSwitch]="field.kind">
        <app-datepicker
          *ngSwitchCase="'date'"
          ngDefaultControl
          [name]="field.id.toString()"
          [key]="'value'"
          [model]="modelFor(field.id)"
          [field]="field"
          [startView]="'month'"
          [required]="field.required"
          [placeholder]="field.name"
          [description]="field.name"
          [tooltip]="field.description"
          [submited]="submited"
        ></app-datepicker>

        <ng-container *ngSwitchCase="'textarea'">
          <textarea
            [name]="field.id.toString()"
            [required]="field.required"
            #textarea="ngModel"
            [(ngModel)]="modelFor(field.id).value"
            [placeholder]="field.name"
          ></textarea>
          <label translate class="error">validation.required</label>
        </ng-container>

        <div class="open-dropdown" *ngSwitchCase="'polar'">
          <div class="radio-container" *ngFor="let option of ['yes', 'no']">
            <input
              class="custom-radio"
              type="radio"
              [name]="option + field.id"
              [id]="option + field.id"
              [(ngModel)]="modelFor(field.id).value"
              [required]="field.required"
              [value]="option === 'yes'"
            />
            <label [for]="option + field.id">{{ 'misc.' + option + '_string' | translate }}</label>
            <label translate class="error" *ngIf="option === 'no'">validation.required</label>
          </div>
        </div>

        <div *ngSwitchCase="'checkbox'" class="checkboxgroup">
          <div class="checkbox">
            <input
              type="checkbox"
              [name]="'check_field_' + field.id"
              [id]="'check_field_' + field.id"
              [(ngModel)]="modelFor(field.id).value"
              class="option-input checkbox"
              [required]="field.required"
            />
            <label [innerHtml]="field.name" [for]="'check_field_' + field.id"></label>
            <small translate *ngIf="field.description?.length">{{ field.description }}</small>
            <label translate class="error">validation.required</label>
          </div>
        </div>

        <ng-container *ngSwitchCase="'email'">
          <input
            *ngSwitchCase="'email'"
            [name]="'email_' + field.id"
            [type]="field.kind"
            [required]="field.required"
            #bla="ngModel"
            [(ngModel)]="modelFor(field.id).value"
            [placeholder]="field.name"
            emailvalidator
          />
          <label translate class="error">validation.email</label>
        </ng-container>

        <app-form-phone-field
          *ngSwitchCase="'phone'"
          [model]="modelFor(field.id)"
          [key]="'value'"
          [field]="field"
        ></app-form-phone-field>

        <div *ngSwitchCase="'payment'">
          <app-form-select
            [model]="modelFor(field.id)"
            [name]="field.id.toString()"
            [key]="'value'"
            [options]="field.options"
            [required]="field.required"
            *ngIf="field.options"
          ></app-form-select>
        </div>

        <ng-container *ngSwitchDefault>
          <input
            [name]="'text_' + field.id"
            [type]="field.kind"
            [required]="field.required"
            min="0"
            max="254"
            [lengthValidator]="{ min: 0, max: 254 }"
            #text="ngModel"
            [(ngModel)]="modelFor(field.id).value"
            [placeholder]="field.name"
          />
          <label translate class="error" *ngIf="text.errors?.required">validation.required</label>
          <label class="error" *ngIf="text.errors?.LengthValidator">
            {{ 'validation.maxLength' | translate: { length: 254 } }}
          </label>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
