import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'pipes/safe.pipe.module';
import { InfoRoutingModule } from './info-routing.module';
import { InfoComponent } from './info.component';

@NgModule({
  imports: [CommonModule, InfoRoutingModule, SafePipeModule, TranslateModule],
  exports: [InfoComponent],
  declarations: [InfoComponent],
})
export class InfoModule {}
