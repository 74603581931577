import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { LabelModule } from 'shared/forms/label/label.module';
import { PmsGuestModule } from '../../shared/guest/guest.module';
import { PmsInvoiceAddressFormModule } from '../../shared/invoice_address/form/form.module';
import { PmsCiGeneralRoutingModule } from './general-routing.module';
import { PmsCiGeneralComponent } from './general.component';

@NgModule({
  imports: [
    CommonModule,
    PmsCiGeneralRoutingModule,
    LabelModule,
    FormsModule,
    DatePickerModule,
    TranslateModule,
    PmsInvoiceAddressFormModule,
    PmsGuestModule,
  ],
  exports: [PmsCiGeneralComponent],
  declarations: [PmsCiGeneralComponent],
  providers: [PmsBaseDirective],
})
export class PmsCiGeneralModule {}
