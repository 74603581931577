import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioListAllModule } from './list/all/all.module';
import { PortfolioListParentModule } from './list/parent/parent.module';
import { PortfolioModuleRoutingModule } from './portfolio-routing.module';
import { PortfolioModuleComponent } from './portfolio.component';

@NgModule({
  imports: [
    CommonModule,
    PortfolioModuleRoutingModule,
    PortfolioListParentModule,
    PortfolioListAllModule,
  ],
  exports: [PortfolioModuleComponent],
  declarations: [PortfolioModuleComponent],
})
export class PortfolioModuleModule {}
