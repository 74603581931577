import { OverlayAction } from 'pms_enums';

interface OverlayButton {
  title: string;
  action: OverlayAction;
  guard?: boolean;
}

interface OverlayContent {
  text?: string;
  timerKey?: string;
  class?: string;
}

export class GenericOverlay {
  name: string;
  title: string;
  content: OverlayContent;
  button: OverlayButton;
  alternative: OverlayButton;
  component: any;
  inputParams: any;
  id: string;
  small: boolean;
  type: string;
  with_back: boolean;
  showClose: boolean;

  constructor(
    title: string,
    content?: OverlayContent,
    button?: OverlayButton,
    alternative?: OverlayButton,
    with_back?: boolean,
    component?: any,
    inputParams?: any,
    id?: string,
    small?: boolean,
    type?: string,
  ) {
    this.title = title;
    this.content = content;
    this.button = button;
    this.alternative = alternative;
    this.component = component;
    this.inputParams = inputParams;
    this.id = id;
    this.small = small;
    this.type = type;
    this.with_back = with_back;
  }
}
