<div class="popup-text">
  <h4 [innerHTML]="headline" *ngIf="headline"></h4>
  <div [innerHTML]="content" class="m-b-16"></div>
</div>

<div class="btn-grid">
  <button
    translate
    class="btn-filled small-width"
    (click)="close()"
    *ngIf="this.error == 'outdated'"
  >
    misc.update
  </button>
  <button
    translate
    class="btn-filled small-width"
    (click)="close()"
    *ngIf="!this.error || this.error == 'maintenance'"
  >
    misc.close
  </button>
</div>
