import { Component } from '@angular/core';
import { Globals } from 'base';

@Component({
  selector: 'app-content',
  template: `
    <section id="content" [class.is-safari]="isSafari">
      <router-outlet></router-outlet>
      <div class="clearfix"></div>
    </section>
  `,
})
export class ContentComponent {
  isSafari = false;

  constructor(private globals: Globals) {
    this.isSafari = this.globals.device.browser === "Safari";
  }
}
