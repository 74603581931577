<div
  id="steps-container"
  [ngStyle]="{ 'grid-template-columns': 'repeat(' + cicoService.userSteps.length + ', 1fr)' }"
>
  <div
    *ngFor="let step of cicoService.userSteps; let i = index"
    class="step"
    [ngClass]="step.state"
  >
    <div class="circle-filled">
      <i [class]="step.state === 'done' ? 'fas fa-check' : step.icon"></i>
    </div>
    <span class="description main-text">{{ 'steps.' + step.key | translate }}</span>
    <div class="progress-line" *ngIf="i > 0"></div>
  </div>
</div>
