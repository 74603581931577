import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetClockRoutingModule } from './clock-routing.module';
import { WidgetClockComponent } from './clock.component';

@NgModule({
  imports: [CommonModule, WidgetClockRoutingModule, TranslateModule],
  exports: [WidgetClockComponent],
  declarations: [WidgetClockComponent],
})
export class WidgetClockModule {}
