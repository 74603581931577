export enum LogType {
  log = 'Black',
  info = 'DodgerBlue',
  error = 'Red',
  debug = 'Orange',
}

export enum EventConstants {
  // PMS
  navigationChange = 'pms.navigationChange',
  afterSubmitForm = 'pms.afterSubmitForm',
  moveToNextOrPreviousPage = 'pms.moveToNextOrPreviousPage',
  resetFieldForm = 'pms.resetFieldForm',
  folioProcessInitiated = 'pms.folioProcessInitiated',
  toShowIdNowWhenUserClickBack = 'pms.toShowIdNowWhenUserClickBack',
  formCustomValidation = 'formCustomValidation',
  checkInOutAction = 'checkInOutAction',
  invoiceAddressUpdate = 'invoiceAddressUpdate',
  openRoomUpgradeConfirmation = 'openRoomUpgradeConfirmation',
  openBreakfastBookingConfirmation = 'openBreakfastBookingConfirmation',
  toCheckThePaidFolios = 'pms.toCheckThePaidFolios',
}

export enum DeviceSize {
  mobile = 'MOBILE',
  tablet = 'TABLET',
  desktop = 'DESKTOP',
  kiosk = 'KIOSK',
}
