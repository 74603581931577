import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { Business } from 'models/business';
import { GenericService } from 'models/generic_service';
import { Module } from 'models/module';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { OfflineService } from 'services/offline.service';
import { ServiceService } from 'services/service.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
})
export class BasketComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  service: GenericService;
  business: Business;
  module: Module;
  basket_module: Module;
  cart: any;
  items: any[];
  suggestions: any[];
  categories: any[];
  notEmpty = false;
  is_direct = true;
  payments = [];

  pay_method_field: any;

  offline: boolean;

  confirm_name = 'send';
  error_text: string;

  disabled = false;

  @ViewChild('serviceForm') serviceForm: NgForm;

  constructor(
    public globals: Globals,
    private businessService: BusinessService,
    private api: ApiService,
    private offlineService: OfflineService,
    private serviceService: ServiceService,
  ) {
    this.createForm();
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );
  }

  onSubmit() {
    this.serviceService.send_service(this, 'menu');
  }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
    this.globals.getModule('basket').then((mod) => {
      this.basket_module = mod;
    });
    this.globals
      .getModule('menu', false)
      .then((mod) => {
        this.module = mod;
        this.pay_method_field = this.module.field('pay_method');
        this.payments = this.pay_method_field.options;
        this.disabled = this.module.service_disabled();
        if (this.disabled) {
          this.globals._module = this.module;
          this.serviceService.openTimetable();
        }
      })
      .catch(() => {});
    this.subscriptions.add(
      this.getBasket().subscribe(
        (success: any) => {
          this.cart = success.cart;
          this.items = this.cart.items;
          this.service.cart = this.cart.id;
          this.service.price = this.cart.total_price;
          this.suggestions = success.cart.suggestions;
          this.notEmpty = this.cart && this.cart['items'] && this.cart['items'].length > 0;
        },
        () => {},
      ),
    );
    this.service.time = null;
    this.subscriptions.add(
      this.getCategories().subscribe(
        (success) => {
          this.categories = success['categories'];
        },
        () => {},
      ),
    );
  }

  createForm() {
    this.service = this.serviceService.newService();
    this.service.date = moment().format('YYYY-MM-DD');
  }

  toggleCategories() {
    document.getElementById('category-menu').classList.toggle('active');
  }

  direct_delivery(state) {
    this.is_direct = state;
    if (state === true) {
      this.service.time = null;
    } else {
      this.set_proc_time();
    }
  }

  set_proc_time() {
    this.subscriptions.add(
      this.getMaxProcTime().subscribe(
        (success) => {
          this.service.time = success['process_time'];
        },
        () => {},
      ),
    );
  }

  getBasket(): Observable<Object> {
    return this.api.get('basket');
  }

  getMaxProcTime(): Observable<Object> {
    return this.api.silentGet('basket/new');
  }

  getCategories(): Observable<Object> {
    return this.api.silentGet('menu/categories');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
