import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FIELD_CONFIG } from '../address/field-config';
import { GenericData } from 'models/pms/generic_data';
import { AddressType } from 'app/_enums/pms/enums';
import { Field } from 'models/field';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pms-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PmsAddressComponent implements OnInit {
  @Input() guestAddress: any;
  @Input() field: Field;
  @Input() isBillingAddress: boolean;
  @Input() data: GenericData;
  @Input() fieldType: string;
  @Output() onChangeCountry = new EventEmitter<any>();

  fields: any;

  AddressType = AddressType;
  states = [];

  constructor() {}

  ngOnInit(): void {
    this.initializeFields();
    this.countryChanged(this.guestAddress?.country);
  }

  initializeFields() {
    const fieldTypeForConfig =
      this.fieldType === 'popup_address' ? 'invoice_address' : this.fieldType;
    const fieldsConfig = FIELD_CONFIG[fieldTypeForConfig];
    this.fields = fieldsConfig.reduce((acc: Field, fieldId: string) => {
      acc[fieldId] = this.field_for(fieldId);
      return acc;
    }, {});
  }

  field_for(id: string): Field {
    return this.field.fields?.find((field: Field) => field.identifier === id);
  }

  onSelectedState(event: any) {
    if (event?.detail?.selectedValue) {
      this.guestAddress.state = event.detail.selectedValue.value;
    }
  }

  onSelectedCountry(event: any) {
    if (event?.detail?.selectedValue) {
      this.guestAddress.country = event.detail.selectedValue.value;
    }
    this.countryChanged(this.guestAddress.country);
    this.onChangeCountry.emit(this.guestAddress);
  }

  countryChanged(value: any) {
    switch (value) {
      case 'US':
        this.states = this.data.statesUs;
        break;
      case 'CA':
        this.states = this.data.statesCa;
        break;
      default:
        this.states = [];
        this.guestAddress.state = null;
    }
  }

  addressToggle(event: any) {
    this.guestAddress.address_type =
      event?.detail == AddressType.personal ? AddressType.personal : AddressType.company;
    this.onChangeCountry.emit(this.guestAddress);
  }

  updateAddress() {
    this.onChangeCountry.emit(this.guestAddress);
  }
}
