import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { LanguageService } from 'common/language/language.service';
import { DeviceSize } from 'global_enums';
import { Action } from 'models/action';
import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { Place } from 'models/place';
import { PmsReservation } from 'models/pms/pms_reservation';
import { UserOption } from 'models/user_option';
import { DoorType, UserActionType, OverlayAction, PmsModType, PmsProcess } from 'pms_enums';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { TeaserService } from 'services/websocket/teaser.service';
import { WebsocketService } from 'services/websocket/websocket.service';
import { KioskService } from 'services/websocket/wizard/kiosk.service';
import { DeviceService } from 'services/device.service';
import { PmsLoginComponent } from '../shared/login/login.component';

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss'],
})
export class PmsKioskComponent implements OnInit, OnDestroy {
  readonly PmsProcess = PmsProcess;
  readonly PmsModType = PmsModType;

  private subscriptions: Subscription = new Subscription();
  public suppressGuard: boolean;
  module: Module;
  business: Business;
  place: Place;
  guest: Guest;
  terminal: boolean;
  reservation: PmsReservation;
  state: string;
  webcam_problem = false;
  simplifiedLogin: boolean;
  showDeviceName: boolean;
  options = [];
  webcam_test = 'mediaDevices' in navigator;
  actionNotAvailable: boolean;
  showAvailableActions = false;
  kiosk_actions: Action[];
  doorType: DoorType;
  modType: PmsModType;
  multipleActions: boolean;
  deviceSize: DeviceSize;

  @ViewChild(PmsLoginComponent) login: PmsLoginComponent;
  imageUrl = '';

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    private router: Router,
    private businessService: BusinessService,
    private languageService: LanguageService,
    private kioskService: KioskService,
    private guestService: GuestService,
    public wsService: WebsocketService,
    private teaserService: TeaserService,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
  ) {}

  ngOnInit() {
    const cryptcode = this.guestService.getCryptcode(this.router.url);

    this.subscriptions.add(
      this.deviceService.getSize().subscribe((size) => {
        this.deviceSize = size;
      }),
    );

    this.subscriptions.add(
      this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
        this.guest = guest;
        this.checkSession(cryptcode);

        this.globals.setStart(true);
        this.kioskService.containerSetup();
        this.businessService.current_business
          .pipe(filter(Boolean), take(1))
          .subscribe((business: Business) => {
            this.business = business;

            this.wsService.checkLicense();
            this.loadModule();
          });

        this.subscriptions.add(
          this.teaserService.teaser.pipe(filter(Boolean)).subscribe((teaser: any) => {
            if (teaser.medias?.length && teaser.medias[0].media?.urls) {
              this.imageUrl = teaser.medias[0].media.urls.normal;
            }
          }),
        );

        this.subscriptions.add(
          this.cicoService.navigation.subscribe((userActionType: UserActionType) => {
            if (
              userActionType === UserActionType.cancel &&
              !['check_in', 'check_out'].includes(this.cicoService.getProcess())
            ) {
              this.reset();
            }
          }),
        );
      }),
    );
  }

  checkSession(cryptcode) {
    if (cryptcode !== this.guest.place.cryptcode) {
      const querySub = this.router.routerState.root.queryParams.subscribe((params) => {
        this.guestService.redirectFallback(this.router.url, this.globals.queryString(params));
        setTimeout(() => {
          querySub?.unsubscribe();
        });
      });
    }
  }

  loadModule() {
    this.globals
      .getModule(PmsModType.wizard, false)
      .then((mod) => {
        this.module = mod;
        this.place = this.globals.place;
        this.terminal = this.globals.terminalKiosk() || this.globals.hardwareTerminalKiosk();
        this.simplifiedLogin = this.module.settings.simplified_login;
        this.showDeviceName = this.module.settings.show_device_name;

        if (this.terminal) {
          this.loadActions(this.module);
          this.subscriptions.add(
            this.businessService.currentLocale.subscribe(() => {
              this.loadActions();
            }),
          );
        }

        this.observeReservationPush();

        const querySub = this.route?.queryParams
          ?.pipe(filter((qry: any) => qry.params))
          ?.subscribe((query: any) => {
            const params = JSON.parse(atob(query['params']));

            this.reservation = new PmsReservation({ uuid: params['uuid'] });
            if (this.globals.kiosk()) {
              this.options = [params['type']];
              this.cicoService.setProcess(PmsProcess[params.type]);
              this.start();
            } else {
              this.state = params['type'];
            }

            this.cicoService.loggedInSubj.next(true);
            setTimeout(() => {
              querySub?.unsubscribe();
            });
          });

        this.webcam_problem =
          this.terminal && this.module.settings.require_passport_image && !this.webcam_test;
        this.subscriptions.add(
          this.cicoService.idle.pipe(filter(Boolean)).subscribe(() => {
            this.wsService.statusSubj.next('idle');
            this.reset();
          }),
        );
      })
      .catch(() => {});
  }

  loadActions(mod?: Module) {
    if (mod) {
      this.kiosk_actions = Action.getActionsFromJSON(this.module.settings.kiosk_options);
    } else {
      this.globals.getModule(PmsModType.wizard, false).then((wizardMod) => {
        this.module = wizardMod;
        this.kiosk_actions = Action.getActionsFromJSON(this.module.settings.kiosk_options);
      });
    }
    this.multipleActions = this.kiosk_actions.length > 1;
  }

  observeReservationPush() {
    this.subscriptions.add(
      this.kioskService.reservation.subscribe((reservation) => {
        this.languageService.setLanguage(reservation.locale).then(() => {
          this.wsService.statusSubj.next('in_use');
          this.reservation = reservation;
          this.state = reservation.state;
          this.cicoService.initiateAway(this.module.settings.timeout);
          this.cicoService.loggedInSubj.next(true);
          this.handleAction(reservation.state);
        });
      }),
    );
  }

  setReservation(data: any) {
    this.actionNotAvailable = false;
    this.reservation = data.reservation;
    this.options = data.options.map((option: any) => new UserOption(option));

    if (this.cicoService.getProcess() === PmsProcess.default && this.options.length === 1) {
      if (this.hasOption(PmsProcess.check_in) || this.hasOption(PmsProcess.check_out)) {
        this.cicoService.setProcess(this.options[0].type);
      }
    }

    if (this.cicoService.getProcess() === PmsProcess.door) {
      if (this.hasOption(DoorType.pin_codes)) {
        this.doorType = DoorType.pin_codes;
      } else if (this.hasOption('cards') || this.hasOption('new_card')) {
        this.doorType = DoorType.cards;
      }
    }

    if (!this.isProcessAllowed(this.cicoService.getProcess())) {
      if (this.cicoService.getProcess() !== PmsProcess.default) {
        this.actionNotAvailable = true;
      }
      this.showAvailableActions = true;
      this.cicoService.setProcess(null);
    }
    this.globals.setStart(false);
  }

  isProcessAllowed(process: PmsProcess): boolean {
    switch (process) {
      case PmsProcess.door:
        return this.hasOption('new_card') || this.hasOption('cards') || this.hasOption('pin_codes');
      default:
        return this.hasOption(process);
    }
  }

  start() {
    this.globals.clearAlert(true);
    this.cicoService.disableNextButton(false);
    this.cicoService.initiateAway(this.module.settings.timeout);
    this.globals.removeOverlayClass();
  }

  handleAction(process: string) {
    if (process === 'cards' || process === 'new_card') {
      this.doorType = DoorType.cards;
      process = PmsProcess.door;
    } else if (process === 'pin_codes') {
      this.doorType = DoorType.pin_codes;
      process = PmsProcess.door;
    }

    this.modType = this.getPmsModType(PmsProcess[process]);

    this.showAvailableActions = false;
    this.cicoService.setProcess(PmsProcess[process]);
    this.start();
  }

  hasOption(process: string): boolean {
    return this.options.find((option) => option.type === process);
  }

  getPmsModType(process: PmsProcess): PmsModType | undefined {
    return Object.values(PmsModType).find(
      (value) => value === 'pms_' + process.toString(),
    ) as PmsModType;
  }

  reset() {
    this.kioskService.containerSetup();
    this.globals.getModule(PmsModType.wizard, false).then((mod) => {
      this.module = mod;
      this.simplifiedLogin = mod.settings.simplified_login;
      this.showDeviceName = mod.settings.show_device_name;
      this.globals.checkUpdate();
      this.globals.setStart(true);
      this.reservation = null;
      this.showAvailableActions = false;
      this.login?.reset();
      this.cicoService.cardLost = null;
      this.cicoService.setProcess(null);
      this.cicoService.closeOverlay(OverlayAction.close);
      this.cicoService.toggleInactivity(false);
      this.router.navigate([], { queryParams: null });
      this.cicoService.showContinueButton(true);
      this.cicoService.loggedInSubj.next(false);
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
