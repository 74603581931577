import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoiMapComponent } from './map/map.component';
import { PoiComponent } from './poi.component';

const routes: Routes = [
  { path: '', component: PoiComponent },
  { path: 'map', component: PoiMapComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PoiRoutingModule {}
