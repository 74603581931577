import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'base';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { StorageService } from 'services/storage.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  response: HttpErrorResponse;
  error: any;
  code: string;
  token: string;
  diagnosis: any;

  constructor(
    private route: ActivatedRoute,
    private globals: Globals,
    private router: Router,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.response = this.globals.error;
    this.subscriptions.add(
      this.route.params.subscribe((params: any) => {
        const code = params.status || this.response?.status || '500';
        this.error = this.description(code);
      }),
    );
    this.code = this.response?.error?.reason;

    this.storageService.getTokens().then((tokens: Array<string>) => {
      this.diagnosis = [
        environment.version,
        this.response?.error?.version || this.response?.headers?.get('X-App'),
        tokens[0],
      ]
        .filter((info) => info?.length)
        .join('|');
    });

    if (this.response?.status === 412 && this.response.error?.code) {
      const cryptcode = this.response.error.code;
      this.router.navigate(['/', cryptcode]);
    }
  }

  description(code): any {
    const list = {
      '500': {
        headline: 'Internal Server Error',
        small: 'Error 500',
        description:
          'An unexpected condition was encountered.<br />The support team has been notified.',
      },
      '429': {
        headline: 'Too Many Requests',
        small: 'You have been temporary blocked',
        description: 'You have too many failed login attempts. Please try again later.',
      },
      '423': {
        headline: 'Maintenance',
        small: 'Please try again later',
        description: 'We are performing scheduled maintenance. We will be back online shortly.',
      },
      '409': {
        headline: 'Conflict',
        small: 'Error 409',
        description: 'Currently another kiosk was started for this license.',
      },
      '403': {
        headline: 'Forbidden',
        small: 'Error 403',
        description: "You don't have permission to access the requested site.",
      },
      '401': {
        headline: 'Unauthorized',
        small: 'Error 401',
        description:
          'The request lacks valid authentication for the target the request was invalid.',
      },
    };
    return list[code];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
