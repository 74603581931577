<div
  id="tooltip-wrapper"
  class="tooltip-wrapper"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
>
  <div id="tooltip" class="tooltip">
    {{ tooltip }}
  </div>
</div>
