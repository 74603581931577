import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { MapProtectionModule } from '../layout/map_protection/map_protection.module';
import { MapRoutingModule } from './map-routing.module';
import { MapComponent } from './map.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MapRoutingModule, GoogleMapsModule, MapProtectionModule],
  exports: [MapComponent],
  declarations: [MapComponent],
})
export class MapModule {}
