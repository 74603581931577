<app-module-title [module]="module"></app-module-title>

<div class="container" *ngIf="module">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"></app-swiper>
    <app-basket-widget [cart]="cart"></app-basket-widget>
    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left all">
    <div class="top-btn-container clearfix">
      <a
        translate
        [routerLink]="['/g', globals.getCode(), 'menu_category', 'daily']"
        class="button top-btn"
        *ngIf="module.settings.daily_menu"
      >
        <i class="fas fa-file-alt"></i>
        categories.daymenu
      </a>
      <a
        [routerLink]="['/g', globals.getCode(), 'basket']"
        class="button top-btn"
        *ngIf="cart?.items?.length && module.orderable"
      >
        <i class="fa {{ module.settings.basket_icon }}"></i>
        {{ module.settings.basket_name }}
      </a>
    </div>

    <div class="searchbox" *ngIf="module?.search && categories">
      <input
        type="search"
        [(ngModel)]="searchText"
        placeholder="{{ 'misc.searchenter' | translate }}"
      />
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <ng-container *ngFor="let item of categories">
      <em [innerHtml]="item.category.name"></em>
      <div [innerHtml]="item.category.description" *ngIf="item.category.description"></div>
      <app-attachments [files]="item.files" *ngIf="item.files?.length"></app-attachments>
      <div class="menu">
        <div class="head">
          <div translate>shopping_card.name</div>
          <div translate>shopping_card.price</div>
          <div></div>
        </div>
        <a
          *ngFor="let entry of item.category.entries | filter: searchText"
          [routerLink]="['/g', globals.getCode(), 'menu', entry.id]"
        >
          <div class="two-lines">
            {{ entry.name }}
            <small *ngIf="entry.description" [innerHtml]="entry.description | stripTags"></small>
          </div>
          <div *ngIf="business">
            {{ entry.price_hidden ? null : (entry.price | currency: business.currency) }}
          </div>
          <div><i class="fas fa-angle-right icon-next"></i></div>
        </a>
      </div>
    </ng-container>
  </div>
</div>
