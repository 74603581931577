import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { Advert } from 'models/advert';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { VisitService } from 'services/visit.service';
import { WidgetAdvertService } from './advert.service';

@Component({
  selector: 'app-widget-advert',
  templateUrl: './advert.component.html',
  styleUrls: ['./advert.component.scss'],
})
export class WidgetAdvertComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  adverts: Array<Advert>;
  advert: Advert;
  showSwiper: boolean;

  slideIndex = 0;
  autoplay = {
    delay: 7000,
    disableOnInteraction: false,
  };

  public slideConfig: SwiperConfigInterface = {
    loop: true,
    autoplay: false,
    watchOverflow: true,
    navigation: true,
    autoHeight: true,
  };

  constructor(
    private advertService: WidgetAdvertService,
    private businessService: BusinessService,
    private visitService: VisitService,
    private hostElement: ElementRef,
    private globals: Globals,
  ) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      if (business.style.adverts) {
        this.advertService
          .getModule()
          .then((mod: Module) => {
            if (mod.settings?.swiper_enabled) {
              this.showSwiper = true;
              if (mod.settings.swiper_auto_enabled) {
                this.autoplay.delay = Number(mod.settings.swiper_trigger_value) * 1000;
                this.slideConfig.autoplay = this.autoplay;
              } else {
                this.slideConfig.autoplay = false;
              }
            } else {
              this.showSwiper = false;
            }

            this.advertService
              .getAdverts()
              .then((adverts: Array<Advert>) => {
                this.adverts = adverts;
                this.countView(this.adverts[0].id);

                if (this.adverts.length === 1) {
                  this.advert = this.adverts[0];
                  this.showSwiper = false;
                }
              })
              .catch(() => {});
          })
          .catch(() => {});
      }
    });
  }

  setIndex(index: number) {
    this.slideIndex = index;
  }

  open(id, event) {
    if (event === true || (event?.target && !event.target.className.includes('swiper-button'))) {
      const advert = this.adverts.find((a) => a.id === id);
      this.openUrl(advert);
    }
  }

  openUrl(advert) {
    if (advert) {
      this.globals.openUrl(advert.link.url);
      this.countClick(advert.id);
    }
  }

  countClick(id) {
    this.advertService.countClick(id).subscribe();
  }

  countView(id) {
    if (
      !this.visitService.adverts.find(
        (view) => view.id === id && Date.now() - view.timestamp < 4000,
      ) &&
      this.track()
    ) {
      this.visitService.adverts.push({ id: id, timestamp: Date.now() });
    }
  }

  track(): boolean {
    const self = this.hostElement.nativeElement;
    const sidebar = self.closest('#sidebar');
    const navigation = self.closest('#navigation');

    if (sidebar) {
      return sidebar.offsetWidth > 1;
    } else if (navigation) {
      return navigation.offsetHeight > 1;
    } else {
      return self.offsetHeight > 1;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
