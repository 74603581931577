import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DomModal } from 'models/dom_modal';
import { Module } from 'models/module';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { EventModuleService } from '../../event.service';
import { CalendarService } from './calendar.service';
import { EventListCalenderItemComponent } from './item/calendar_item.component';

@Component({
  selector: 'app-event-list-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class EventListCalendarComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  module: Module;
  calendar: any;
  medias: [];

  rightSide: boolean;

  private month = new BehaviorSubject<number>(0);
  currentMonth = this.month.asObservable();

  constructor(
    private globals: Globals,
    private eventService: EventModuleService,
    private calendarService: CalendarService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.globals
      .getModule('event')
      .then((mod) => {
        this.module = mod;
        this.medias = this.module.medias;

        this.rightSide = this.medias && this.medias.length > 0;
      })
      .catch(() => {});

    this.subscriptions.add(
      this.currentMonth.subscribe((month) => {
        this.eventService.getCalendar(month).subscribe(
          (success) => {
            this.calendar = success['calendar'];
            this.globals.backlink = ['parent'];
          },
          () => {},
        );
      }),
    );
  }

  getMonth(): number {
    return this.month.getValue();
  }

  change(action) {
    let month = this.getMonth();
    if (action === 'previous' && month > 0) {
      month -= 1;
      this.month.next(month);
    }
    if (action === 'current' && month !== 0) {
      this.month.next(0);
    }
    if (action === 'next' && month < 12) {
      month += 1;
      this.month.next(month);
    }
  }

  open(id) {
    this.subscriptions.add(
      this.calendarService.getCalendar(id).subscribe((entry) => {
        this.calendarService.calendarSubj.next(entry['entry']);
        const body = new DomModal();
        body.title = entry['entry'].name;
        body.redirect = false;
        body.component = EventListCalenderItemComponent;
        this.modalService.open(body);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
