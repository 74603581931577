import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-guest-index',
  templateUrl: './guest-index.component.html',
  styleUrls: ['./guest-index.component.scss'],
})
export class GuestIndexComponent {
  @Input() guestIndex: number;
  @Input() totalGuests: number;

  constructor() {}
}
