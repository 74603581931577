<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="module.medias"></app-swiper>
    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">
    <form (ngSubmit)="onSubmit()" #serviceForm="ngForm">
      <fieldset>
        <div class="alert" [innerHtml]="error_text" *ngIf="error_text"></div>

        <ng-container *ngIf="questions">
          <div *ngFor="let question of questions" [ngSwitch]="question.kind" class="form-box">
            <label>{{ question.title }}</label>

            <div *ngSwitchCase="'stars'" class="stars">
              <ng-container *ngFor="let star of starsArray">
                <i
                  class="far fa-star"
                  (mouseenter)="starEnter($event)"
                  (mouseleave)="starLeave($event)"
                  (click)="answer($event, star + 1)"
                  [attr.data-id]="question.id"
                ></i>
              </ng-container>
            </div>

            <div *ngSwitchCase="'text'">
              <input
                type="text"
                [name]="'answer_' + question.id"
                placeholder="{{ 'feedback.answer' | translate }}"
                [(ngModel)]="service.answers[question.id]"
                (keyup)="answer($event)"
                min="0"
                max="254"
                [lengthValidator]="{ min: 0, max: 254 }"
              />
              <label class="error">{{ 'validation.maxLength' | translate: { length: 254 } }}</label>
            </div>

            <div class="open-dropdown" *ngSwitchCase="'polar'">
              <div class="radio-container" *ngFor="let option of ['yes', 'no']">
                <input
                  class="custom-radio"
                  type="radio"
                  [id]="option + question.id"
                  [name]="'question_' + question.id"
                  [(ngModel)]="service.answers[question.id]"
                  [value]="option === 'yes' ? 1 : 0"
                  (click)="answer($event)"
                />
                <label [for]="option + question.id">
                  {{ 'misc.' + option + '_string' | translate }}
                </label>
              </div>
            </div>
          </div>

          <app-form-place
            [service]="service"
            [hide_name]="true"
            *ngIf="globals.needPlace() && showPlace"
          ></app-form-place>

          <ng-container *ngIf="showAdditionalFields">
            <div class="form-box">
              <label translate>misc.name</label>
              <input
                name="name"
                #name="ngModel"
                [(ngModel)]="service.name"
                placeholder="{{ 'misc.name' | translate }}"
                [required]="requireAdditionalFields"
              />
              <label translate class="error">validation.name</label>
            </div>

            <div class="form-box">
              <label translate>misc.confirm_mail</label>
              <input
                type="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="service.email"
                placeholder="{{ 'misc.mail' | translate }}"
                emailvalidator
                [required]="requireAdditionalFields"
              />
              <label translate class="error">validation.email</label>
            </div>
          </ng-container>
        </ng-container>
      </fieldset>
      <app-form-send-cancel
        [confirm]="confirm_name"
        [status]="globals.send_cancel_disabled"
        *ngIf="questions"
      ></app-form-send-cancel>
    </form>
  </div>
</div>
