import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomModal } from 'models/dom_modal';
import { ApiService } from 'api_service';
import { PushNotificationService } from 'services/push_notification.service';
import { StorageService } from 'services/storage.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { Module } from 'models/module';

@Component({
  selector: 'app-push-categories',
  templateUrl: './categories.component.html',
})
export class PushCategoriesComponent implements OnInit {
  categories: any[];
  activated: Number[];
  confirm_name = 'update';

  constructor(
    public globals: Globals,
    private api: ApiService,
    private router: Router,
    private pushNotificationService: PushNotificationService,
    private modalService: ModalService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.globals.setModule('push');
    this.pushNotificationService.getCategories().subscribe(
      (success: any) => {
        this.categories = success.push_categories;
        if (!this.globals.guest.subscription_id || this.modalService.isShowing) {
          this.activated = this.categories.map((item) => {
            return item['id'];
          });
        } else {
          this.activated = this.globals.guest.push_categories;
        }
      },
      () => {},
    );
  }

  check(id) {
    if (!this.activated.includes(id)) {
      this.activated.push(id);
    } else {
      const index = this.activated.indexOf(id, 0);
      if (index > -1) {
        this.activated.splice(index, 1);
      }
    }
  }

  onSubmit() {
    if (!!this.globals.guest) {
      this.globals.guest.push_categories = this.activated;
    }
    if (!this.globals.guest.hasValidSubscription() || this.modalService.isShowing) {
      if (this.modalService.isShowing) {
        this.modalService.close();
      }
    }
    this.pushNotificationService
      .requestPermission()
      .then((token) => {
        this.updateCategories(token);
      })
      .catch();
  }

  updateCategories(token) {
    const push_categories = this.globals.guest.push_categories;
    this.pushNotificationService
      .updateCategories({ push_categories: push_categories, token: token })
      .then(() => {
        if (push_categories && push_categories.length === 0) {
          this.globals.guest.subscription_id = -1;
        }
        this.storageService.setItem('current_guest', this.globals.guest.toLocalStorage());
        this.success();
      })
      .catch(() => {
        this.globals.alert('error', this.globals.translate('validation.error'));
      });
  }

  success() {
    const body = new DomModal();
    body.title = 'misc.thank_you';
    body.description = 'push.edit_subsciption';
    this.modalService.open(body, true);
  }
}
