import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaQueryService {
  query = window.matchMedia('(display-mode: standalone)');

  standaloneSubj: Subject<boolean> = new Subject<boolean>();
  standalone: Observable<boolean> = this.standaloneSubj.asObservable();

  constructor() {
    if (typeof this.query.addEventListener === 'function') {
      this.query.addEventListener('change', (e) => {
        this.standaloneSubj.next(e.matches);
      });
    } else {
      this.query.addListener((e) => {
        this.standaloneSubj.next(e.matches);
      });
    }
  }
}
