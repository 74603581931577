import { Injectable } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsService } from 'modules/pms/pms.service';
import { throwError } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

export interface RoomBookingRequest {
  id: string;
  rule_id: number;
  origin: string;
  offer_id: number;
}

export interface ServiceBookingRequest {
  id: string;
  rule_id: number;
  origin: string;
}

@Injectable({ providedIn: 'root' })
export class BookingService {
  private reservationId;
  public hasBookedRoomUpgradeSuccessfully: boolean = false;

  constructor(
    private pmsService: PmsService,
    private globals: Globals,
    private cicoService: PmsCiCoService,
  ) {
    this.cicoService.data
      .pipe(
        filter((data) => !!data),
        take(1),
      )
      .subscribe((data) => (this.reservationId = data.incident.reservation.uuid));
  }

  public bookRoom(request: RoomBookingRequest, productName: string) {
    this.globals.posthogSetCapture({ active: false });
    return this.pmsService
      .bookRoomOffers(request.id, { ...request, uuid: this.reservationId })
      .pipe(
        map((res) => {
          this.globals.alert(
            'success',
            this.globals.translate('cus.success', { service: productName }),
            this.globals.translate('service.process.success'),
          );
          this.hasBookedRoomUpgradeSuccessfully = true;
          this.cicoService.folioUpdateSubj.next({ cus: true });
          return res;
        }),
        catchError((err) => {
          this.globals.alert(
            'error',
            this.globals.translate('cus.error', { service: productName }),
            this.globals.translate('service.process.error'),
          );
          return throwError(err);
        }),
      );
  }

  public bookService(request: ServiceBookingRequest, productName: string) {
    this.globals.posthogSetCapture({ active: false });
    return this.pmsService.bookAdditionalService(request).pipe(
      map((res) => {
        this.globals.alert(
          'success',
          this.globals.translate('cus.success', { service: productName }),
          this.globals.translate('service.process.success'),
        );
        this.cicoService.folioUpdateSubj.next({ cus: true });
        return res;
      }),
      catchError((err) => {
        this.globals.alert(
          'error',
          this.globals.translate('cus.error', { service: productName }),
          this.globals.translate('service.process.error'),
        );
        return throwError(err);
      }),
    );
  }
}
