import {
  Component,
  EventEmitter,
  Input,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { fadeInAnimation } from 'app/route-animations';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { ModalService } from 'common/modal/modal.service';
import { environment } from 'environments/environment';
import { DeviceSize } from 'global_enums';
import { Field } from 'models/field';
import { Module } from 'models/module';
import { PmsPaymentService } from 'payment_service';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { PmsService } from 'pms_service';
import { skip } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';

export type LoginType = 'default' | 'qr_code' | 'simplified_login';

@Component({
  selector: 'app-pms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInAnimation],
})
export class PmsLoginComponent
  extends PmsCiCoBaseDirective
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('serviceForm') serviceForm: NgForm;
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @ViewChild('reservation_id', { static: true, read: NgModel }) reservationIdNgModel!: NgModel;
  @Input() mod: string;
  @Input() wizardModule: Module;
  @Input() deviceSize: DeviceSize;
  @Output() reservation_data = new EventEmitter<any>();
  @Output() cancelLogin = new EventEmitter<any>();

  loginType: LoginType = 'default';

  terminal: boolean;
  simplifiedLogin: boolean;
  scannerEnabled: boolean;
  kioskScanner: boolean;
  confirm_name = 'send';
  legal: any;
  list: any;

  search: string;
  email: string;
  address: string;
  date: Date;

  resFiledName = 'reservation_id';
  nameFiledName = 'last_name';

  loginData = {
    id: '',
    reservation_id: '',
    last_name: '',
    mod: '',
    field_values: [],
  };

  valid: boolean;
  heading = 'service.login.instruction';
  inputSize: 'small' | 'medium' = 'medium';

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    private pmsService: PmsService,
    private businessService: BusinessService,
    protected differs: KeyValueDiffers,
    protected storageService: StorageService,
    protected guestService: GuestService,
    protected modalService: ModalService,
    protected paymentService: PmsPaymentService,
    protected route: ActivatedRoute,
  ) {
    super(
      cicoService,
      guestService,
      differs,
      storageService,
      globals,
      modalService,
      paymentService,
      route,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['deviceSize']) {
      this.inputSize = this.deviceSize === DeviceSize.tablet ? 'small' : 'medium';
    }
  }

  ngOnInit() {
    document.getElementById('container')?.classList?.add('cico');
    super.ngOnInit();

    this.cicoService.logUnload = false;
    this.legal = this.globals.business?.legal;
    this.terminal = this.globals.terminalKiosk() || this.globals.hardwareTerminalKiosk();

    this.init();
    if (this.terminal) {
      this.scannerEnabled =
        this.wizardModule?.settings?.qr_code_scan &&
        (!this.globals.isMacOrIos() || this.globals.isSafari());
      this.kioskScanner = this.globals.place.kiosk_scanner;

      this.subscriptions.add(
        this.businessService.currentLocale.pipe(skip(1)).subscribe((_locale) => {
          this.pmsService.get_texts(this.wizardModule.type).subscribe((response: any) => {
            this.wizardModule.fields.forEach((field, _index) => {
              const updated = response.module.fields.find(
                (ufield: Field) => field.identifier === ufield.identifier,
              );
              if (updated) {
                field.name = updated.name;
                field.description = updated.description;
              }
            });
          });
        }),
      );
    }

    this.subscriptions.add(
      this.guestService.loginRequired.subscribe((loginRequired: boolean) => {
        if (loginRequired) {
          this.cicoService.disableNextButton(false);
          this.route.queryParams.subscribe((query: any) => {
            if (query?.reservation) {
              this.loginData.reservation_id = query.reservation;
              this.globals.removeQuery();
            }
          });
        }
      }),
    );
  }

  onSubmit() {
    this.globals.clearAlert();
    this.globals.markAllControlsTouched(this.serviceForm);
    if (this.serviceForm?.valid) {
      this.globals.sending();
      this.loginData.mod = this.mod;
      this.pmsService.get_reservation_by(this.loginData).subscribe(
        (success: any) => {
          this.cicoService.logUnload = true;
          Array.isArray(success) ? (this.list = success) : this.login(success);
        },
        (response) => {
          this.globals.alert('error', response.error.message, response.error.title);
          this.init();
          if (response.status === 403) {
            this.reset();
          }
        },
      );
      this.valid = false;
    } else {
      this.valid = true;
    }
  }

  onScanSuccess(data) {
    if (data.errorText) {
      const error = data.errorText.error;
      this.globals.alert(error.type, error.message, error.title);
    } else {
      this.reservation_data.emit(data.reservationData);
      this.cicoService.toggleInactivity(true);
    }
  }

  login(success) {
    this.subscriptions.unsubscribe();
    this.reservation = success.reservation;
    this.reservation_data.emit({ reservation: success.reservation, options: success.options });

    if (!this.globals.place.wizard && this.globals.guest) {
      if (success.email) {
        this.globals.guest.email = success.email;
      }
      this.globals.guest.subscription_id = success.subscription_id;
      this.globals.guest.smart_feedback = success.smart_feedback;
      this.globals.guest.save();
    }
    if (success.place) {
      this.guestService.setPlace(success.place);
    }

    this.cicoService.loggedInSubj.next(true);
    this.guestService.loginRequiredSubj.next(false);
    this.init();
  }

  choose(id) {
    this.list = null;
    this.loginData.id = id;
    this.pmsService.get_reservation_by(this.loginData).subscribe((success: any) => {
      this.login(success);
    });
  }

  init() {
    this.setLoginData();
    this.list = null;
    this.cicoService.suppressGuardSubj.next(true);
    this.globals.init_send_cancel_btn();

    if (environment.production && this.globals.place?.wizard) {
      this.resFiledName = Math.random().toString(36).substring(2);
      this.nameFiledName = Math.random().toString(36).substring(2);
    }
  }

  setLoginData() {
    this.simplifiedLogin = this.terminal ? this.wizardModule?.settings?.simplified_login : false;
    this.loginData = {
      id: '',
      reservation_id: '',
      last_name: '',
      mod: '',
      field_values: this.loginData.field_values,
    };
    this.serviceForm?.resetForm();
    this.reservationIdNgModel?.reset();
  }

  changeLoginType(loginType: LoginType) {
    this.loginType = loginType;
    this.heading =
      this.loginType === 'qr_code' ? 'service.login.scan_qr' : 'service.login.instruction';
  }

  navigateBack() {
    if (this.loginType === 'default') {
      this.cancelLogin.emit();
    } else {
      this.loginType = 'default';
    }
    this.valid = false;
    this.heading = 'service.login.instruction';
  }

  reset() {
    this.loginType = 'default';
    this.setLoginData();
    this.serviceForm?.resetForm();
  }

  ngOnDestroy(): void {
    this.globals.send_cancel_disabled = false;
    super.ngOnDestroy();
  }
}
