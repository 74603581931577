<div
  class="iframe_container"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  *ngIf="iframe"
>
  <iframe [src]="link.url | safe" id="external_link" *ngIf="link" allow="geolocation"></iframe>
</div>

<app-backlink *ngIf="showBack"></app-backlink>
