import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { LoaderModule } from 'common/loader/loader.module';
import { ScannerRoutingModule } from './scanner-routing.module';
import { ScannerComponent } from './scanner.component';

@NgModule({
  imports: [CommonModule, ScannerRoutingModule, TranslateModule, LoaderModule, ZXingScannerModule],
  exports: [ScannerComponent, TranslateModule],
  declarations: [ScannerComponent],
  providers: [TranslateService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ScannerModule {}
