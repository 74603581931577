import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { PortfolioModuleService } from '../../portfolio.service';

@Component({
  selector: 'app-portfolio-list-parent',
  templateUrl: './parent.component.html',
})
export class PortfolioListParentComponent
  extends ServiceParentDirective
  implements OnInit, OnDestroy
{
  mod = 'portfolio';

  constructor(
    public globals: Globals,
    protected route: ActivatedRoute,
    protected businessService: BusinessService,
    protected sanitizer: DomSanitizer,
    private portfolioService: PortfolioModuleService,
  ) {
    super(globals, route, businessService, sanitizer);
  }

  ngOnInit() {
    super.ngOnInit(this.portfolioService);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
