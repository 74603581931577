<div id="a2hs-widget" class="a2hs-bar widget" [ngClass]="a2hsService.classList">
  <div class="inner">
    <h4 [innerHTML]="'a2hs.browser.' + a2hsService.text | translate"></h4>
    <div *ngIf="a2hsService.a2hsBrowser">
      <button class="button main-bg" (click)="a2hsService.install()" translate>
        misc.add_android
      </button>
    </div>
    <ul *ngIf="!a2hsService.a2hsBrowser">
      <li>
        <div [innerHTML]="'a2hs.icons.' + a2hsService.browser + '.choose' | translate"></div>
        <div translate>misc.choose</div>
      </li>
      <li *ngIf="a2hsService.browser === 'opera'">
        <div class="opera-overflow" translate>a2hs.add_to</div>
      </li>
      <li>
        <div [innerHTML]="'a2hs.icons.' + a2hsService.browser + '.homescreen' | translate"></div>
        <div [innerHTML]="'a2hs.text.' + a2hsService.browser | translate"></div>
      </li>
    </ul>
  </div>
</div>
