import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Link } from 'models/link';

@Injectable({ providedIn: 'root' })
export class ExternalLinkService {
  linkChange: BehaviorSubject<Link> = new BehaviorSubject<Link>(null);

  constructor() {}

  changeLink(link: Link) {
    this.linkChange.next(link);
  }
}
