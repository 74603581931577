<div *ngIf="offline" class="info_box info normal">
  <i class="fas fa-info-circle"></i>
  <p translate>navigation.offline</p>
</div>
<nav *ngIf="business">
  <ul>
    <li
      class="menu-item hidden-mobile"
      [class.active]="module?.type === 'language'"
      *ngIf="business.language_sidebar && business.multilingual"
    >
      <a
        [routerLink]="['/g', globals.getCode(), 'language']"
        [ngClass]="{ disabled_state: offline }"
      >
        <ng-container
          *ngIf="
            languageService.language?.language && languageService.language?.flag;
            else fallback_globe
          "
        >
          <img
            [src]="languageService.language.flag"
            [alt]="languageService.language.language"
            class="flag-icon"
          />
          <span>{{ languageService.language.name }}</span>
          <span class="triangle"></span>
        </ng-container>
      </a>
    </li>

    <li
      class="menu-seperator"
      *ngIf="highlights?.length && business.language_sidebar && business.multilingual"
    ></li>
    <ng-template
      *ngFor="let item of highlights"
      [ngTemplateOutlet]="mod"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-template>
    <li class="menu-seperator" *ngIf="highlights?.length"></li>
    <li class="menu-label"><p>Navigation</p></li>
    <ng-container *ngIf="navigation; else loader">
      <li class="menu-item hidden-mobile" [class.active]="module?.type === 'home'">
        <a [routerLink]="['/g/', globals.getCode()]">
          <i class="fas fa-home"></i>
          <span translate>footer.home</span>
          <span class="triangle"></span>
        </a>
      </li>
      <ng-template
        *ngFor="let item of navigation"
        [ngTemplateOutlet]="mod"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-template>
      <li class="menu-item" *ngFor="let test of test_items">
        <a [routerLink]="['/g/', globals.getCode(), test.navigation.path]" [fragment]="'view'">
          <i class="fa {{ test.navigation.icon }}"></i>
          <span>{{ test.navigation.name + ' (Demo)' }}</span>
          <span class="triangle"></span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>

<ng-template #loader>
  <li class="menu-item loading" *ngFor="let item of loadingIcons()">
    <a>
      <i class="fas fa-{{ item }}"></i>
      <span translate>misc.loading</span>
      <span class="triangle"></span>
    </a>
  </li>
</ng-template>

<ng-template #fallback_globe>
  <i class="fas fa-globe-americas"></i>
  <span>Select Language</span>
  <span class="triangle"></span>
</ng-template>

<ng-template #mod let-mod>
  <li class="menu-item" [class.active]="module?.type === mod.path">
    <a
      [routerLink]="['/g/', globals.getCode(), mod.path]"
      *ngIf="mod.type === 'int'"
      [ngClass]="{
        disabled_state:
          offline &&
          (!globals.isInStandaloneMode() || (offline_modules && offline_modules.includes(mod.path)))
      }"
    >
      <i class="fa {{ mod.icon }}"></i>
      <span [innerHtml]="mod.name"></span>
      <span class="triangle"></span>
    </a>
    <a
      [class.ext-link]="mod.url_target === '_blank'"
      (click)="openExternalLink(mod)"
      *ngIf="mod.type === 'ext'"
      [ngClass]="{
        disabled_state:
          offline &&
          (!globals.isInStandaloneMode() || (offline_modules && offline_modules.includes(mod.path)))
      }"
    >
      <i class="fa {{ mod.icon }}"></i>
      <span [innerHtml]="mod.name"></span>
      <span class="triangle"></span>
    </a>
  </li>
  <li class="menu-item" *ngFor="let entry of overviewItems(mod.path)">
    <a
      [routerLink]="['/g/', globals.getCode(), entry.path, entry.id]"
      [ngClass]="{
        disabled_state:
          offline &&
          (!globals.isInStandaloneMode() || (offline_modules && offline_modules.includes(mod.path)))
      }"
    >
      <i class="fa {{ entry.icon }}"></i>
      <span [innerHtml]="entry.name"></span>
      <span class="triangle"></span>
    </a>
  </li>
</ng-template>
