import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { Socialmedia } from 'models/socialmedia';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';

@Component({
  selector: 'app-widget-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
})
export class WidgetSocialComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  social: Socialmedia;
  social_keys: string[];

  constructor(
    public globals: Globals,
    private businessService: BusinessService,
  ) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.social = this.business.socialmedia;
      this.social_keys = Object.keys(this.social);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
