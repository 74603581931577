import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[customEmailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomEmailValidator,
      multi: true,
    },
  ],
})
export class CustomEmailValidator implements Validator {
  @Input('controlNameToMatch') controlNameToMatch: string;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailPattern.test(control.value)) {
      return { wrongFormat: true };
    }

    if (this.controlNameToMatch) {
      const controlToMatch = control.root.get(this.controlNameToMatch);
      if (control.value !== controlToMatch?.value) {
        const confirmError = { confirmEmail: true };
        controlToMatch?.setErrors(confirmError);
        return confirmError;
      }
      controlToMatch?.setErrors(null);
    }

    return null;
  }
}
