import { Component, Input } from '@angular/core';
import { Field } from 'models/field';

@Component({
  selector: 'app-form-label',
  templateUrl: './label.component.html',
})
export class LabelComponent {
  @Input() field: Field;
  @Input() for: string;

  constructor() {}
}
