import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class EncoderService {
  private resultSubj: Subject<any> = new Subject<any>();
  result: Observable<any> = this.resultSubj.asObservable();

  constructor(private api: ApiService) {}

  getInfo(uuid): Observable<Object> {
    return this.api.get('door/encoder/' + uuid + '/edit');
  }

  createCard(data): Observable<Object> {
    return this.api.post('door/encoder', data);
  }

  getStatus(uuid) {
    return this.api.get('door/encoder/check/' + uuid);
  }

  receiveData(data: any) {
    this.resultSubj.next(data);
  }
}
