import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PassportPhotoService {
  photo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  cameraVisible$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  changeCameraVisible(cameraVisible: boolean) {
    this.cameraVisible$.next(cameraVisible);
  }

  setPhoto(photo: any) {
    this.photo$.next(photo);
  }

  public removePhoto() {
    this.photo$.next(null);
  }
}
