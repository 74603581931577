import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-info-screen',
  templateUrl: './info-screen.component.html',
  styleUrls: ['./info-screen.component.scss'],
})
export class InfoScreenComponent implements OnInit, AfterViewInit {
  @Input() type: 'info' | 'success' | 'error' | 'warning';
  @Input() text: string;
  safeHtml: any;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    timer(0, 0)
      .pipe(take(1))
      .subscribe(() => {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.text);
      });
  }

  ngOnInit(): void {}
}
