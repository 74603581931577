import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { ShopModuleService } from '../../shop.service';

@Component({
  selector: 'app-shop-list-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss'],
})
export class ShopListAllComponent extends ServiceAllDirective implements OnInit, OnDestroy {
  mod = 'shop';

  constructor(
    public globals: Globals,
    protected businessService: BusinessService,
    private shopService: ShopModuleService,
  ) {
    super(globals, businessService);
  }

  ngOnInit() {
    super.ngOnInit(this.shopService);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
