export class Contact {
  email: string;
  phone: string;
  fax: string;
  url: string;
  url_target: string;
  route: string;

  constructor(raw_json: any) {
    this.email = raw_json?.email;
    this.phone = raw_json?.phone;
    this.fax = raw_json?.fax;
    this.url = raw_json?.url;
    this.url_target = raw_json?.url_target;
    this.route = raw_json?.route;
  }
}
