import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaFilterPipeModule } from 'pipes/mediaFilter.pipe.module';
import { BackgroundRoutingModule } from './background-routing.module';
import { BackgroundComponent } from './background.component';

@NgModule({
  imports: [CommonModule, BackgroundRoutingModule, MediaFilterPipeModule],
  exports: [BackgroundComponent],
  declarations: [BackgroundComponent],
})
export class BackgroundModule {}
