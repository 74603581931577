import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WishListParentComponent } from './parent.component';

const routes: Routes = [{ path: '', component: WishListParentComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WishListParentRoutingModule {}
