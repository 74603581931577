import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  min_length = 3;

  _ref: any;
  length = true;
  loading = false;
  no_results = false;
  deactivated = false;
  results: any;
  search_string: string;

  constructor(
    private api: ApiService,
    private globals: Globals,
  ) {}

  searchFor(value: string) {
    if (value !== this.search_string) {
      this.search_string = value;

      this.length = value.length >= this.min_length;

      if (this.length) {
        this.no_results = false;
        this.loading = true;
        this.getResults(value).subscribe(
          (success: any) => {
            this.results = success.search;
            this.no_results = this.results.length === 0;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            if (error.status === 423) {
              this.deactivated = true;
            } else {
              this.no_results = true;
            }
          },
        );
      } else {
        this.results = [];
        this.loading = false;
      }
    }
  }

  url(url) {
    return ['/g', this.globals.getCode()].concat(url.split('/'));
  }

  close() {
    this._ref.destroy();
  }

  getResults(query): Observable<Object> {
    return this.api.post('search', { query: query });
  }
}
