import { OnInit, Component, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { PassportPhotoService } from 'services/passport-photo.service';
@Component({
  selector: 'app-passport-photo',
  templateUrl: './passport-photo.component.html',
  styleUrls: ['./passport-photo.component.scss'],
})
export class PassportPhotoComponent implements OnInit {
  @Input() recaptureTrigger$: Subject<any> = new Subject<any>();
  @Input() type: string = '';
  @Input() imageUpload: boolean = true;
  @Output() success: EventEmitter<void> = new EventEmitter<void>();
  @Output() abort: EventEmitter<void> = new EventEmitter<void>();

  captured: boolean = false;
  photo: any;

  constructor(private passportPhotoService: PassportPhotoService) {}

  ngOnInit() {}

  reCapture() {
    this.recaptureTrigger$.next();
  }

  onPhotoSuccess(photo: any) {
    this.photo = photo;
  }

  onSuccess() {
    this.success.emit();
    this.passportPhotoService.setPhoto(this.photo);
    this.passportPhotoService.changeCameraVisible(false);
  }

  onAbort() {
    this.abort.emit();
    this.passportPhotoService.changeCameraVisible(false);
  }
}
