import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomModal } from 'models/dom_modal';
import { GenericNewsletter } from 'models/generic_newsletter';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { GuestService } from 'services/guest.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './form.component.html',
})
export class NewsletterFormComponent implements OnInit {
  newsletter: GenericNewsletter;
  module: Module;
  guest: Guest;
  showNameFields = false;
  requireNameFields = false;

  confirm_name = 'subscribe';
  error_text: string;

  @ViewChild('serviceForm', { static: true }) serviceForm: NgForm;
  @Input() class: string;
  @Output() onSubmitSuccess = new EventEmitter<void>();

  constructor(
    public globals: Globals,
    private modalService: ModalService,
    private guestService: GuestService,
    private api: ApiService,
  ) {
    this.guest = this.guestService.guest;
  }

  ngOnInit() {
    this.createForm();
    this.globals
      .getModule('newsletter')
      .then((mod) => {
        this.module = mod;
        this.showNameFields = this.module.settings.show_name_fields;
        this.requireNameFields = this.module.settings.require_name_fields;
      })
      .catch(() => {});
  }

  createForm() {
    this.newsletter = new GenericNewsletter();
    this.newsletter.email = this.globals.getEmail();
  }

  onSubmit() {
    this.globals.markAllControlsTouched(this.serviceForm);
    if (this.serviceForm.valid) {
      this.api
        .post('newsletter', {
          params: this.newsletter,
        })
        .subscribe(
          () => {
            this.onSubmitSuccess.emit();
            this.globals.setEmail(this.newsletter.email);
            this.guest.newsletter = true;
            const body = new DomModal();
            body.title = 'misc.thank_you';
            body.description = 'newsletter.confirm';
            this.modalService.open(body, true);
          },
          (error) => {
            this.error_text = this.globals.formError(error);
          },
        );
    }
  }

  close() {
    this.modalService.close();
  }
}
