import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { LandingPage } from 'models/landingPage';

@Injectable({ providedIn: 'root' })
export class StyleService {
  interval: any;

  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) {}

  set_styles(business: Business | LandingPage, guest: Guest = null) {
    document.body.id = business.style.theme;

    this.loadIcons(business.icon);
    this.loadCSS(business.style.css);

    this.loadManifest(guest);
    this.titleService.setTitle(business.style.title);

    this.metaService.updateTag({
      name: 'apple-mobile-web-app-title',
      content: business.style.title,
    });
    this.metaService.updateTag({ name: 'theme-color', content: business.style.background });
    this.metaService.updateTag({ name: 'msapplication-starturl', content: '/g/' + business.code });
    this.metaService.updateTag({
      name: 'msapplication-TileColor',
      content: business.style?.background,
    });
    this.metaService.updateTag({
      name: 'msapplication-TileImage',
      content: business.icon.size_144,
    });
  }

  loadManifest(guest) {
    if (guest && environment.env !== 'development') {
      const url = guest.place.manifest;
      if (guest.browser && guest.browser === 'firefox') {
        const manifest = document.getElementById('manifest');
        if (manifest) {
          manifest.parentNode.removeChild(manifest);
        }

        const link = document.createElement('link');
        link.href = url;
        link.rel = 'manifest';
        this.styleToHead(link);
      } else {
        document.getElementById('manifest')?.setAttribute('href', url);
      }
    }
  }

  loadIcons(icons) {
    let link = document.createElement('link');
    link.href = icons.size_16;
    link.rel = 'shortcut icon';
    link.type = 'image/png';
    this.styleToHead(link);

    link = document.createElement('link');
    link.href = icons.size_512;
    link.rel = 'apple-touch-startup-image';
    this.styleToHead(link);

    const iconSize = ['16', '32', '96', '192'];
    iconSize.forEach((value) => {
      link = document.createElement('link');
      link.href = icons['size_' + value];
      link.type = 'image/png';
      link.rel = 'icon';
      link.setAttribute('sizes', value + 'x' + value);

      this.styleToHead(link);
    });

    const size = ['60', '72', '76', '114', '120', '144', '152', '180'];
    size.forEach((value) => {
      link = document.createElement('link');
      link.href = icons['size_' + value];
      link.rel = 'apple-touch-icon';
      link.setAttribute('sizes', value + 'x' + value);

      this.styleToHead(link);
    });
  }

  loadCSS(url) {
    if (url === undefined) {
      return;
    }
    // Create link
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    this.styleToHead(link);
  }

  styleToHead(link) {
    const head = document.getElementsByTagName('head')[0];
    const links = head.getElementsByTagName('link');
    const style = head.getElementsByTagName('style')[0];

    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (let i = 0; i < links.length; i++) {
      if (links[i].href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) {
      return;
    }
    head.insertBefore(link, style);
  }

  scriptToHead(url, defer = false) {
    const head = document.getElementsByTagName('head')[0];
    const scripts = head.getElementsByTagName('script');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    if (defer) {
      script.defer = true;
      script.async = true;
    }

    let isLoaded = false;
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.indexOf(script.src) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) {
      return;
    }
    head.appendChild(script);
  }

  set_lang(lang) {
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute('lang', lang);
  }
}
