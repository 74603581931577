<ng-container *ngIf="field.validation === 'no_validation'; else internationalPhone">
  <input
    #phone="ngModel"
    [name]="field.id?.toString()"
    minlength="5"
    maxlength="30"
    [required]="field.required"
    [(ngModel)]="model[key]"
    placeholder="{{ 'misc.phone' | translate }}"
    [lengthValidator]="{ min: 5, max: 30 }"
  />
  <label translate class="error" *ngIf="phone.errors?.required">validation.required</label>
  <label translate class="error" *ngIf="phone.errors?.LengthValidator?.min">
    {{ 'validation.minLength' | translate: { length: 5 } }}
  </label>
  <label translate class="error" *ngIf="phone.errors?.LengthValidator?.max">
    {{ 'validation.maxLength' | translate: { length: 30 } }}
  </label>
</ng-container>

<ng-template #internationalPhone>
  <international-phone-number
    #phone="ngModel"
    [defaultCountry]="'default'"
    [(ngModel)]="model[key]"
    [name]="field.id?.toString()"
    placeholder="{{ 'misc.phone' | translate }}"
    [maxlength]="30"
    [required]="field.required"
    [phonevalidator]="{ validation: field.validation, required: field.required }"
    (ngModelChange)="replace00()"
  ></international-phone-number>
  <label
    translate
    class="error"
    *ngIf="phone?.errors?.phoneEmptyError && !phone.errors?.phonevalidator"
  >
    validation.phone
  </label>
  <label class="error" *ngIf="phone?.errors?.phonevalidator">
    {{ 'validation.' + field.validation | translate }}
  </label>
</ng-template>
