<app-module-title [module]="module"></app-module-title>

<ng-container *ngIf="items">
  <div class="container">
    <div class="container-right" *ngIf="rightSide">
      <app-swiper [items]="module.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">
      <div class="top-btn-container clearfix">
        <a
          translate
          [routerLink]="['/g/', globals.getCode(), 'group_portfolio', 'map']"
          class="button top-btn"
          *ngIf="showMap"
        >
          <i class="fas fa-map-marked-alt"></i>
          poi.map
        </a>
        <a
          translate
          [routerLink]="['/g/', globals.getCode(), 'group_portfolio', 'social']"
          class="button top-btn"
          *ngIf="showSocial"
        >
          <i class="fas fa-thumbs-up"></i>
          misc.social
        </a>
      </div>

      <div class="searchbox" *ngIf="module?.search && items?.length > 3">
        <input
          type="search"
          [(ngModel)]="searchText"
          placeholder="{{ 'misc.searchenter' | translate }}"
        />
        <div class="search-icon"><i class="fas fa-search"></i></div>
      </div>

      <div class="menu">
        <a *ngFor="let item of items | filter: searchText" [routerLink]="[item.entry.id]">
          <div>
            <i class="ad-icon" *ngIf="item.entry.advert"></i>
            {{ item.entry.title }}
          </div>
          <div>
            <i class="fas fa-angle-right icon-next"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-container>
