export class Visit {
  page: string;
  module: string;
  session: Number;
  created_at: any;
  standalone: boolean;

  constructor(raw_json: any) {
    this.page = raw_json.page;
    this.module = raw_json.module;
    this.session = raw_json.session;
    this.created_at = raw_json.created_at;
    this.standalone = this.isInStandaloneMode();
  }

  isInStandaloneMode() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
}
