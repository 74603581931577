<ng-container *ngIf="data">
  <ng-container *ngIf="skip && skipType">
    <straiv-dialog
      *ngIf="isDialogOpen"
      id="dialog"
      size="md"
      [closeOnClickOutside]="false"
      [open]="isDialogOpen"
      (dialogClose)="closeDialog()"
    >
      <straiv-dialog-header
        class="straiv-dialog"
        [dialogTitle]="'service.payment.skip_auth' | translate"
        title-alignment="center"
      ></straiv-dialog-header>
      <p class="text" [innerHtml]="ui_messages[skipType]?.content"></p>
      <ng-container
        *ngIf="skipType === 'no_credit_card_with_rule'; else no_credit_card_without_rule"
      >
        <straiv-dialog-actions
          [primaryButtonLeftLabel]="
            'service.payment.auth.' +
              (data.paymentProcess === processType.pre_auth ? 'pay_now' : 'auth_now') | translate
          "
          [secondaryButtonLeftLabel]="
            'service.payment.auth.back_to_' +
              (data.paymentProcess === processType.pre_auth ? 'auth' : 'payment') | translate
          "
          (primaryButtonClick)="confirmSkip()"
          (secondaryButtonClick)="setSkip(false)"
        ></straiv-dialog-actions>
      </ng-container>
      <ng-template #no_credit_card_without_rule>
        <straiv-dialog-actions
          [primaryButtonLeftLabel]="'misc.continue' | translate"
          [secondaryButtonLeftLabel]="'service.payment.auth.back_to_auth' | translate"
          (primaryButtonClick)="confirmSkip()"
          (secondaryButtonClick)="setSkip(false)"
        ></straiv-dialog-actions>
      </ng-template>
    </straiv-dialog>
  </ng-container>

  <form #form="ngForm" class="redesign">
    <ng-container *ngIf="loaded; else loading">
      <ng-container *ngIf="!data.folioInfo.error; else retry">
        <p
          [innerHTML]="'service.folios.partial' | translate"
          *ngIf="(suite8 || opera) && data.incident.reservation.folios.length && !showHints"
        ></p>

        <div
          class="folios-loaded"
          *ngFor="let folio of data.incident.reservation.folios; let i = index; let last = last"
          [class.last]="last"
        >
          <app-pms-folio
            [hidden]="cicoService.displayedFolioIndex != i && cicoService.displayedFolioIndex >= 0"
            [folio]="folio"
            [data]="data"
            [number]="i"
            [staticAddress]="!collectAddress"
            [payment]="data.paymentProcess === processType.payment || payFromResModule()"
            [showHints]="showHints"
            *ngIf="folio.positions?.length || suite8"
          ></app-pms-folio>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          cicoService.folioAddressStep == 'folio' &&
          (data.folioInfo.error || data.folioInfo.noFolios || blankFolios)
        "
      >
        <p class="no-folios heading-container">
          <i class="fa-solid fa-circle-info"></i>
          <span [innerHTML]="'service.folios.no_folios' + foliosErrorMsg | translate"></span>
        </p>
      </ng-container>

      <div class="heading-container" *ngIf="onlyAdress">
        <h2 translate>service.folios.invoice_address</h2>
        <app-pms-invoice-address-form
          [data]="data"
          [newDesign]="true"
          [staticAddress]="false"
          [withoutFolio]="true"
        ></app-pms-invoice-address-form>
      </div>

      <ng-container
        *ngIf="
          data.paymentProcess === processType.pre_auth &&
          data.preAuth &&
          data.preAuth.amount > 0 &&
          !data.preAuth.fullyAuthorized
        "
      >
        <div class="auth-info" *ngIf="data.preAuth?.info">
          <straiv-section-header
            *ngIf="
              !(
                data?.incident?.reservation?.pre_auth?.auth_without_folio && data?.folioInfo?.error
              ) && data?.incident.reservation?.folios?.length
            "
            [heading]="'service.folios.authorise_payments' | translate"
          ></straiv-section-header>
          <p
            [innerHTML]="data.preAuth.info.with_folio"
            *ngIf="
              !(
                data?.incident?.reservation?.pre_auth?.auth_without_folio && data?.folioInfo?.error
              ) && data?.incident.reservation?.folios?.length
            "
          ></p>
          <ng-container *ngIf="data.incident?.reservation.pre_auth?.auth_without_folio">
            <p
              [innerHTML]="data.preAuth.info.without_folio"
              *ngIf="!data.folioInfo?.error && !data?.incident.reservation?.folios?.length"
            ></p>
            <p [innerHTML]="data.preAuth.info.with_folio_error" *ngIf="data.folioInfo?.error"></p>
          </ng-container>
          <div class="auth-container">
            <div class="row">
              <span>{{ 'service.folios.open_position' | translate }}</span>
              <span>{{ this.payableAmount }}</span>
            </div>
            <div class="row">
              <span>{{ 'service.folios.deposit' | translate }}</span>
              <span>{{ this.data.preAuth.deposit_with_currency }}</span>
            </div>
            <div class="row total">
              <span>{{ 'shopping_card.total_amount' | translate }}</span>
              <span>{{ this.data.preAuth.balance_with_currency }}</span>
            </div>
            <div>
              <ng-template [ngTemplateOutlet]="authButtons"></ng-template>
            </div>
          </div>
        </div>

        <div class="btn-grid" *ngIf="reloadButton">
          <straiv-button
            data-cy="buttons_load_retry_auth"
            name="retry_auth"
            [leftLabel]="'service.payment.loading.buttons.load' | translate"
            right-icon="fas fa-rotate-right"
            (buttonClick)="setFolioSettings()"
          ></straiv-button>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #retry>
      <ng-container
        *ngIf="
          !(
            data.paymentProcess === processType.pre_auth &&
            data.incident.reservation.pre_auth.auth_without_folio
          )
        "
      >
        <ng-template [ngTemplateOutlet]="buttons" *ngIf="showHints"></ng-template>
      </ng-container>
    </ng-template>

    <ng-template #loading>
      <app-loader
        [small]="true"
        [text]="msgToDisplay"
        [headline]="msgToDisplayHeadLine"
      ></app-loader>
      <ng-template [ngTemplateOutlet]="buttons" *ngIf="showHints"></ng-template>
    </ng-template>

    <ng-template #buttons>
      <div class="btn-grid">
        <straiv-button
          *ngIf="reloadButton"
          data-cy="buttons_load"
          name="load-button"
          [leftLabel]="'service.payment.loading.buttons.load' | translate"
          right-icon="fas fa-rotate-right"
          (buttonClick)="loadFolios()"
        ></straiv-button>
        <straiv-button
          *ngIf="skipButton"
          data-cy="skip_it"
          name="skip-it-button"
          [leftLabel]="'misc.skip' | translate"
          variant="outline"
          (buttonClick)="skipIt()"
        ></straiv-button>
      </div>
    </ng-template>

    <ng-template #authButtons>
      <div class="btn-grid half-fill">
        <straiv-button
          data-cy="authorize_now"
          name="authorize-now-button"
          [leftLabel]="'service.payment.authorize_now' | translate: data.preAuth"
          right-icon="fas fa-chevron-right"
          (buttonClick)="openPreAuth($event)"
        ></straiv-button>
        <straiv-button
          data-cy="skip_auth"
          name="skip-auth-button"
          [leftLabel]="'service.payment.skip_auth' | translate"
          variant="outline"
          (buttonClick)="skipPreAuth()"
        ></straiv-button>
      </div>
    </ng-template>

    <p *ngIf="data?.preAuth?.authorizationDone && cicoService.folioAddressStep === 'folio'">
      <i class="fas fa-check text-success"></i>
      <span>
        {{
          'service.payment.authorized'
            | translate: { pre_auth_balance_with_currency: data.preAuth.balance_with_currency }
        }}
      </span>
      <br />
      <span [innerHtml]="ui_messages.authorized?.content"></span>
    </p>
  </form>

  <straiv-dialog
    *ngIf="isExternalPaymentDialogOpen"
    id="dialog-payment"
    size="md"
    [closeOnClickOutside]="false"
    [open]="isExternalPaymentDialogOpen"
    (dialogClose)="closeExternalPaymentDialog()"
  >
    <straiv-dialog-header
      class="straiv-dialog"
      [dialogTitle]="'shopping_card.amount_to_pay' | translate"
      title-alignment="center"
    ></straiv-dialog-header>
    <app-pms-payment
      [data]="data"
      [preAuth]="this.data?.preAuth.amount"
      [payButton]="payButton"
    ></app-pms-payment>
  </straiv-dialog>
</ng-container>
