import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceSize } from 'global_enums';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private static readonly BREAKPOINTS: Record<DeviceSize, string> = {
    [DeviceSize.mobile]: '(max-width: 640px)',
    [DeviceSize.tablet]: '(min-width: 640px) and (max-width: 1320px) and (max-height: 1799px)',
    [DeviceSize.desktop]: '(min-width: 1321px)',
    [DeviceSize.kiosk]: '(min-height: 1800px)',
  };

  private mediaQueryList: Record<DeviceSize, MediaQueryList> = {
    MOBILE: window.matchMedia(DeviceService.BREAKPOINTS.MOBILE),
    TABLET: window.matchMedia(DeviceService.BREAKPOINTS.TABLET),
    DESKTOP: window.matchMedia(DeviceService.BREAKPOINTS.DESKTOP),
    KIOSK: window.matchMedia(DeviceService.BREAKPOINTS.KIOSK),
  };

  private sizeSubject: BehaviorSubject<DeviceSize | null> = new BehaviorSubject<DeviceSize | null>(
    this.getMatchingSize(),
  );

  constructor() {
    this.initMediaQueries();
    this.updateCurrentSize();
  }

  private initMediaQueries(): void {
    Object.entries(this.mediaQueryList).forEach(([key, query]) => {
      query.addEventListener('change', () => this.updateCurrentSize());
    });
  }

  private updateCurrentSize(): void {
    const newSize = this.getMatchingSize();
    if (this.sizeSubject.value !== newSize) {
      this.sizeSubject.next(newSize);
    }
  }

  private getMatchingSize(): DeviceSize | null {
    const matchingSize = Object.entries(this.mediaQueryList).find(
      ([key, query]) => query.matches,
    )?.[0] as DeviceSize;
    return matchingSize || null;
  }

  getSize(): Observable<DeviceSize | null> {
    return this.sizeSubject.asObservable();
  }
}
