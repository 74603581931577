import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LogType } from 'global_enums';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private isDevelopment = environment.env !== 'production';

  constructor() {}

  public log(msg: string, type: LogType, force = false): void {
    if (this.isDevelopment || force) {
      console.log(
        `[%cstraiv]%c[${new Date().toLocaleTimeString()}-${new Date().getMilliseconds()}]: %c${msg}`,
        'color: Blue',
        'color: Black',
        `color: ${type}`,
      );
    }
  }
}
