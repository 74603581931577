import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class EventModuleService {
  constructor(private api: ApiService) {}

  getEntry(id: string): Observable<Object> {
    return this.api.get('event/entries/' + id);
  }

  getCategories(id: string): Observable<Object> {
    return this.api.get('event/categories/' + id);
  }

  getCompact(): Observable<Object> {
    return this.api.get('event/compact');
  }

  getCalendar(month): Observable<Object> {
    return this.api.get('event/calendar/' + month);
  }
}
