import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { GenericService } from 'models/generic_service';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-form-place',
  templateUrl: './place.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormPlaceComponent implements OnInit {
  @Input() service: any;
  @Input() room_validation: string;
  @Input() hide_name: boolean;
  @Input() required = true;

  isNotPreStay: boolean;
  isNotAllRooms: boolean;
  translationString: string;

  place_list = [];

  constructor(
    public globals: Globals,
    private api: ApiService,
  ) {}

  ngOnInit() {
    this.init_dropdowns();
    this.isNotPreStay = !this.globals.isPreStay();
    this.isNotAllRooms = this.room_validation !== 'all_rooms';
    this.translationString =
      this.room_validation === 'number_name' ? 'service.number_or_name' : 'service.only_number';
  }

  init_dropdowns() {
    this.api.silentGet('place').subscribe(
      (success) => {
        this.place_list = success['place'].map((place) => {
          return { value: place.number, name: place.name };
        });
      },
      () => {},
    );
  }
}
