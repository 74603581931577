export const FIELD_CONFIG = {
  invoice_address: [
    'address_type',
    'company',
    'first_name',
    'last_name',
    'address',
    'zipcode',
    'city',
    'country',
    'state',
  ],
  private_address: ['address', 'zipcode', 'city', 'country', 'state'],
};
