import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NewsletterFormModule } from 'modules/newsletter/form/form.module';
import { AttachmentsModule } from 'modules/shared/layout/attachments/attachments.module';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { CookieInfoModule } from '../cookie_info/cookie_info.module';
import { ModalRoutingModule } from './modal-routing.module';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [
    CommonModule,
    ModalRoutingModule,
    NewsletterFormModule,
    WidgetSocialModule,
    AttachmentsModule,
    TranslateModule,
    CookieInfoModule,
  ],
  exports: [ModalComponent],
  declarations: [ModalComponent],
})
export class ModalModule {}
