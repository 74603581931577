import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormPlaceComponent } from './place.component';

const routes: Routes = [{ path: '', component: FormPlaceComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormPlaceRoutingModule {}
