import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'models/address';

@Pipe({
  name: 'addressPipe',
})
export class AddressPipe implements PipeTransform {
  transform(items: Address[]): any {
    return items.filter((address) => address.valid());
  }
}
