import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FilterPipe } from './filter.pipe';

const routes: Routes = [{ path: '', component: FilterPipe }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FilterPipeRoutingModule {}
