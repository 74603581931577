import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';

@NgModule({
  imports: [CommonModule, SearchRoutingModule, TranslateModule],
  exports: [SearchComponent],
  declarations: [SearchComponent],
})
export class SearchModule {}
