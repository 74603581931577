import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormControl } from '@angular/forms';

@Directive({
  selector: 'straiv-checkbox[ngModel][required]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredCheckboxValidator,
      multi: true,
    },
  ],
})
export class RequiredCheckboxValidator implements Validator {
  @Input() required = true;

  // 1: implement the validate method
  validate(control: FormControl): ValidationErrors | null {
    // the value of the control is an object that holds the value of each checkbox
    // the value's signature looks like this, `{ 'check-one': false, 'check-two': true }`
    if (!this.required || control.value === true) {
      return null;
    }

    return { required: 'required checkbox should be checked' };
  }
}
