import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { Guest } from 'models/guest';
import { GuestService } from 'services/guest.service';
import { A2hsService } from 'services/a2hs.service';
import { Globals } from 'base';

@Component({
  selector: 'app-widget-a2hs',
  templateUrl: './a2hs_widget.component.html',
})
export class WidgetA2hsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;

  constructor(
    private businessService: BusinessService,
    private guestService: GuestService,
    public a2hsService: A2hsService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.a2hsService.android = this.globals.isAndroid();
      this.subscriptions.add(
        this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
          this.a2hsService.browserClass(guest);

          this.toggle(this.a2hsService.installableSubject.value);
          this.subscriptions.add(
            this.a2hsService.installable.subscribe((installable) => {
              this.toggle(installable);
            }),
          );
        }),
      );
    });
  }

  toggle(show) {
    const widgets: any[] = Array.from(document.getElementsByTagName('app-widget-a2hs'));
    widgets.forEach((el) => {
      const desktop_nav = !this.globals.isMobile() && el.parentNode.id == 'navigation';
      el.style.display = show && !desktop_nav ? 'block' : 'none';
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
