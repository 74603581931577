<div class="pale" *ngIf="business">
  <a
    translate
    [routerLink]="['/g', globals.getCode(), 'legal', 'legal_notice']"
    *ngIf="business.legal?.legal_notice"
  >
    legal.legal_notice
  </a>
  <a
    translate
    [routerLink]="['/g', globals.getCode(), 'legal', 'terms']"
    *ngIf="business.legal?.terms"
  >
    legal.terms
  </a>
  <a translate [routerLink]="['/g', globals.getCode(), 'legal', 'privacy_policy']">
    legal.privacy_policy
  </a>
  <span>{{ version }}</span>
</div>
