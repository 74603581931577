import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { SharedSwiperModule } from '../../layout/swiper/swiper.module';
import { ExternalLinkRoutingModule } from './external-routing.module';
import { ExternalLinkComponent } from './external.component';

@NgModule({
  imports: [
    CommonModule,
    ExternalLinkRoutingModule,
    TranslateModule,
    SharedSwiperModule,
    WidgetAdvertModule,
  ],
  exports: [ExternalLinkComponent],
  declarations: [ExternalLinkComponent],
})
export class ExternalLinkModule {}
