<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="module && rightSide">
    <app-swiper [items]="module.medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>

  <div class="container-left">
    <form (ngSubmit)="onSubmit()" #serviceForm="ngForm">
      <ng-container *ngIf="module">
        <fieldset [disabled]="disabled">
          <div class="alert" [innerHtml]="error_text" *ngIf="error_text"></div>
          <div class="form-box">
            <div class="checkbox">
              <input
                type="checkbox"
                name="confirm"
                id="service_confirm"
                #confirm="ngModel"
                [(ngModel)]="service.confirm"
                class="option-input checkbox"
                required
              />
              <label [innerHtml]="info_text" for="service_confirm"></label>
              <label translate class="error">validation.selection</label>
            </div>
          </div>

          <div class="form-box" *ngIf="module?.settings?.reservation; else singleDate">
            <label translate>misc.date</label>
            <small translate>green_choice.dates</small>
            <app-form-dates
              [module]="module"
              [service]="service"
              [select_all_days]="true"
              *ngIf="module.settings.dates?.length"
            ></app-form-dates>
            <div class="alert alert-danger" *ngIf="!module.settings.dates?.length" translate>
              green_choice.error
            </div>
          </div>

          <ng-template #singleDate>
            <div class="form-box">
              <label translate for="date">misc.date</label>
              <input
                type="date"
                name="date"
                id="date"
                [min]="globals.today()"
                [max]="service.date"
                #date="ngModel"
                [lang]="globals.locale()"
                [(ngModel)]="service.date"
                disabled
                required
              />
            </div>
          </ng-template>

          <div class="form-box" *ngIf="select?.length">
            <app-form-select
              [model]="service"
              [name]="'selection'"
              [label]="'request.goodie'"
              [options]="select"
            ></app-form-select>
          </div>

          <app-form-fields
            [form]="service"
            [module]="module"
            *ngIf="module?.fields?.length"
          ></app-form-fields>

          <app-form-place
            [service]="service"
            [room_validation]="module.room_validation"
            *ngIf="module && globals.needPlace()"
          ></app-form-place>

          <div class="form-box">
            <label translate>misc.confirm_mail</label>
            <input
              type="email"
              name="email"
              #email="ngModel"
              [(ngModel)]="service.email"
              placeholder="{{ 'misc.mail' | translate }}"
              emailvalidator
              required
            />
            <label translate class="error">validation.email</label>
          </div>

          <div class="form-box" *ngIf="module?.field('phone')">
            <label [innerText]="module.field('phone').name"></label>
            <small
              [innerText]="module.field('phone').description"
              *ngIf="module.field('phone').description"
            ></small>
            <app-form-phone-field
              [model]="service"
              [key]="'phone'"
              [field]="module.field('phone')"
            ></app-form-phone-field>
          </div>

          <app-form-send-cancel
            [confirm]="confirm_name"
            [status]="globals.send_cancel_disabled"
          ></app-form-send-cancel>
        </fieldset>
      </ng-container>
    </form>
  </div>
</div>
