import { Directive, ElementRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { ValueAccessor } from './value-accessor';
import { DateValidator } from 'validators/date.validator';

@Directive({
  selector: 'straiv-datepicker',
  host: {
    '(valueChange)': 'handleChangeEvent($event.target.value)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerValueAccessor,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidator,
      multi: true,
    },
  ],
})
export class DatepickerValueAccessor extends ValueAccessor {
  @Input() localeLng: string = 'default';

  constructor(protected el: ElementRef) {
    super(el);
  }

  // we transform any given date string to the format the straiv datepicker can work with.
  writeValue(value: string) {
    if (!value) {
      this.el.nativeElement.value = '';
      return;
    }
    const { momentMask } = this.getLocaleMask(this.localeLng);
    const needsFormatting = this.hasWrongFormat(value, momentMask);
    if (!needsFormatting) {
      this.el.nativeElement.value = value;
      return;
    }
    const formattedDate = this.formatValue(value, momentMask);
    if (formattedDate === 'Invalid date') {
      this.el.nativeElement.value = '';
      super._handleBlurEvent();
      return;
    }
    this.el.nativeElement.value = formattedDate;
    super._handleBlurEvent();
    return;
  }

  private hasWrongFormat(value, mask) {
    return !moment(value, mask, true).isValid();
  }

  private formatValue(value: string, mask: string): string {
    // Parse the input value to a consistent date format (e.g., YYYY-MM-DD)
    // we must be tell moment the exact format, we want it to parse, otherwise invalid dates like "5" can be parsed as a date, 
    // or the interpretation of the format is wrong
    const formattedValue = [
      moment(value, 'YYYY-MM-DD', true),
      moment(value, 'DD-MM-YYYY', true),
      moment(value, 'DD/MM/YYYY', true),
      moment(value, 'YYYY/MM/DD', true),
      moment(value, 'DD.MM.YYYY', true),
    ]
      .find((m) => m.isValid())
      ?.format(mask);

    return formattedValue || 'Invalid date';
  }

  private getLocaleMask(locale: string) {
    return (
      this.LOCALE_MASKS.find((mask) => mask.locale === locale) ||
      this.LOCALE_MASKS.find((mask) => mask.locale === 'default')
    );
  }

  private LOCALE_MASKS = [
    { locale: 'de', momentMask: 'DD.MM.YYYY' },
    { locale: 'ar', momentMask: 'YYYY/MM/DD' },
    { locale: 'default', momentMask: 'DD/MM/YYYY' },
  ];
}
