import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'api_service';
import { Module } from 'models/module';
import { Globals } from '../../globals';

@Injectable({ providedIn: 'root' })
export class NewsService {
  module: Module;
  newsEntrySubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  newsEntry: Observable<any> = this.newsEntrySubj.asObservable();

  constructor(
    private api: ApiService,
    private globals: Globals,
  ) {}

  getAllNews(): Observable<Object> {
    return this.api.get('news');
  }

  getNews(id): Observable<Object> {
    return this.api.get('news/' + id);
  }

  getModule(): Promise<Module> {
    return new Promise((resolve, _reject) => {
      if (this.module) {
        resolve(this.module);
      } else {
        this.globals.getModule('news', false).then(
          (mod: Module) => {
            this.module = mod;
            resolve(this.module);
          },
          () => {},
        );
      }
    });
  }
}
