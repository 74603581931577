import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DebugRoutingModule } from './debug-routing.module';
import { DebugComponent } from './debug.component';

@NgModule({
  imports: [CommonModule, DebugRoutingModule],
  exports: [DebugComponent],
  declarations: [DebugComponent],
})
export class DebugModule {}
