import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { TacRoutingModule } from './tac-routing.module';
import { TacComponent } from './tac.component';

@NgModule({
  imports: [
    CommonModule,
    TacRoutingModule,
    FormsModule,
    TranslateModule,
    ModuleTitleModule,
    FilterPipeModule,
    BacklinkModule,
  ],
  exports: [TacComponent],
  declarations: [TacComponent],
})
export class TacModule {}
