<div class="content" [class.centered-content]="encoderData.internalEncoder && !confirmation">
  <form #form="ngForm" class="redesign">
    <div *ngIf="!confirmation" class="room-info-container box-shadow">
      <div class="icon-container">
        <i class="fa fa-bed bed-icon"></i>
      </div>
      <div class="room-info">
        <h2 class="room-name">{{ 'misc.room' | translate }} {{ encoderData.room }}</h2>
      </div>
    </div>

    <ng-container *ngIf="!encoderData.internalEncoder">
      <div class="entries-container box-shadow">
        <div class="icon-container">
          <div class="icon-circle">
            <i class="fa fa-key key-icon"></i>
          </div>
        </div>
        <div class="steps menu entries">
          <div class="menu entries">
            <p class="step-description" [ngClass]="enableStepColour ? 'gray-text' : 'black-text'">
              {{ 'pms_door.encoder.steps.global.card' | translate }}
            </p>
            <p class="step-description" [ngClass]="enableStepColour ? 'black-text' : 'gray-text'">
              {{ 'pms_door.encoder.steps.global.wait' | translate }}
            </p>
          </div>
          <p *ngIf="showLimitInfo && remaining_keycards !== null">
            {{
              'pms_door.encoder.steps.remaining_cards' | translate: { limit: remaining_keycards }
            }}
          </p>
        </div>
        <div class="btn-grid">
          <straiv-button
            *ngIf="!confirmation || (confirmation && canEncode)"
            [variant]="encodeText === 'encode_further' ? 'outline' : 'flat'"
            name="encoder-button"
            [leftLabel]="'pms_door.encoder.' + encodeText | translate"
            (buttonClick)="generateKey()"
            [loading]="encoding"
          ></straiv-button>
          <straiv-button
            *ngIf="cicoService.cardEncoded"
            name="encoder-button"
            [leftLabel]="'service.check_in.general.finish' | translate"
            (buttonClick)="finish()"
          ></straiv-button>
        </div>
      </div>
    </ng-container>
  </form>
</div>
