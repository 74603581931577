<input
  #fileInput
  type="file"
  class="hidden"
  [name]="name"
  [id]="inputId"
  [accept]="accept"
  (change)="onFileChange($event)"
  [required]="required"
  [attr.data-cy]="name"
/>
