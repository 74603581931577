<app-module-title [module]="module"></app-module-title>

<ng-container *ngIf="entries">
  <div class="container">
    <div class="container-right" *ngIf="rightSide">
      <app-swiper [items]="module.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">
      <div class="clearfix"></div>
      <div class="menu">
        <a *ngFor="let entry of entries" [routerLink]="[entry.id]">
          <div class="two-lines">
            {{ entry.name }}
            <small>{{ entry.cuisine }}</small>
          </div>
          <div>
            <i class="fas fa-angle-right icon-next"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-container>
