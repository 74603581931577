import { Component, OnInit } from '@angular/core';
import { BusinessGroup } from 'models/business_group';
import { BusinessGroupService } from 'services/business_group.service';

@Component({
  selector: 'app-widget-group',
  moduleId: 'widget',
  templateUrl: './group.component.html',
})
export class WidgetGroupComponent implements OnInit {
  business_group: BusinessGroup;
  logo_url: string;

  constructor(private businessGroupService: BusinessGroupService) {}

  ngOnInit() {
    this.businessGroupService.getBusinessGroup().subscribe(
      (success) => {
        this.business_group = new BusinessGroup(success['business_group']);
        this.logo_url = this.business_group.logo;
      },
      () => {},
    );
  }
}
