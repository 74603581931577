import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetSocialRoutingModule } from './social-routing.module';
import { WidgetSocialComponent } from './social.component';

@NgModule({
  imports: [CommonModule, WidgetSocialRoutingModule],
  exports: [WidgetSocialComponent],
  declarations: [WidgetSocialComponent],
})
export class WidgetSocialModule {}
